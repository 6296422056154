<template>
  <div class="row margin-top-10">
    <div class="col-sm-4 col-md-4 col-lg-4">
      <div class="col-sm-12 col-md-12 col-lg-12 detour-date-content">
        <label> {{ $t('projectForm.labelDetourPeriod')}}</label>
        <base-input>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            value-format="timestamp"
            range-separator="-"
            format="yyyy-MM-dd HH:mm"
            :clearable="false"
            :disabled="statusOfUser"
            :picker-options="{disabledDate: disableDates}"
            v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
            v-bind:end-placeholder="$t('projectForm.labelProjectEnd')">
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 detour-days-content">
        <span class="badge" v-bind:class="daysClass(1)" @click="activeLink(1)">{{ $t('projectForm.monday') }}</span>
        <span class="badge" v-bind:class="daysClass(2)" @click="activeLink(2)">{{ $t('projectForm.tuesday') }}</span>
        <span class="badge" v-bind:class="daysClass(3)" @click="activeLink(3)">{{ $t('projectForm.wednesday') }}</span>
        <span class="badge" v-bind:class="daysClass(4)" @click="activeLink(4)">{{ $t('projectForm.thursday') }}</span>
        <span class="badge" v-bind:class="daysClass(5)" @click="activeLink(5)">{{ $t('projectForm.friday') }}</span>
        <span class="badge" v-bind:class="daysClass(6)" @click="activeLink(6)">{{ $t('projectForm.saturday') }}</span>
        <span class="badge" v-bind:class="daysClass(7)" @click="activeLink(7)">{{ $t('projectForm.sunday') }}</span>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 detours-sens">
      <label>{{ $t('projectForm.labelDetourSens') }}</label>
      <el-select
        class="select-primary select-inline"
        size="large"
        :disabled="statusOfUser"
        v-bind:placeholder="$t('projectForm.placeholderDetourSens')"
        v-model="item.detourSensValue">
        <el-option
          v-for="option in detourSens"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.value">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 label-input">
      <base-input
          name="nbVoice"
          @keypress="isNumber($event)"
          :disabled="statusOfUser"
          v-bind:label="$t('projectForm.labelProjectNbVoice')"
          v-bind:placeholder="$t('projectForm.labelProjectNbVoice')"
          v-model="item.nbVoice">
      </base-input>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
      <div class="detour-date-remove-btn btn-danger" v-if="!statusOfUser" v-on:click="$emit('removeDetourDate', [index, item.dateId])"><span><i class="tim-icons icon-simple-remove"></i></span></div>
    </div>
  </div>
</template>
<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';

export default {
  name: 'detour-date-component',
   components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: ['item', 'index', 'pdate','statusOfUser'],
  watch: {
    date(val) {
      this.item.sdate = val[0];
      this.item.edate = val[1];
    },
    detourSensValue(val) {
      this.item.detourSensValue=val.value;
    }
  },
  data () {
    return {
      date: [this.item.sdate, this.item.edate],
      pdate: [this.pdate, this.pdate],
      detourSensValue: this.item.detourSensValue, 
      detourSens:[
        { value: '1', label: this.$i18n.t('projectForm.selectWayNormal') },
        { value: '2', label: this.$i18n.t('projectForm.selectWayCounter') },
        { value: '3', label: this.$i18n.t('projectForm.selectWayTwo') }
      ]
    };
  },
  methods: {
    disableDates(date) {
      return ((this.pdate[0] > date) || (this.pdate[1] < date));
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    daysClass(day) {
      if (this.item.days.includes(parseInt(day,10))) {
        return 'badge-info';
      } else {
        return 'badge-disable';
      }
    },
    activeLink(day) {
      if (this.item.days.includes(parseInt(day,10))) {
        this.item.days = this.item.days.filter(function(item) { 
          return item !== day;
        });
      } else {
        this.item.days.push(day);
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if ((charCode > 31 && (charCode < 42 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
     
    }
  }
}
</script>
<style>
  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    padding-top: 3px !important;
  }
  
  body.white-content div.detours-sens div.el-select.select-primary.select-inline input.el-input__inner {
    border-color: #8d919c;
  }
  div.label-input label {
    display: ruby;
  }
  div.label-input input {
    margin-top: 5px;
  }
</style>