<template>
  <div class="network-container">
    <div v-if="display.bus || display.road || display.roadWorks || display.cycling || dynamicLayer " class="network-header" @click='toggleNetwork();'>
      <a data-toggle="dropdown" class="settings-icon">
        <i v-if="displayContent" class="tim-icons icon-simple-remove" ></i>
        <!--<i v-else class="tim-icons icon-puzzle-10" ></i>-->
         <img v-else src="static/img/layers.png" />
      </a>
    </div>
    <div v-show="displayContent" class="network-content">
      <div v-if="display.bus" class="network-element-content-bus">
        <label>{{ $t('mapPage.networkTitle')}}</label>
        <el-select style="max-height:100px; overflow: auto;" v-model="lines" multiple v-bind:placeholder="$t('mapPage.networkLines')" popper-class="filters-project1" @visible-change="visibleChange($event)">
          <el-option-group
            :class="filters-project-group"
            v-for="group in options"
            :key="group.key"
            :label="group.label">
            <el-option
              :class=""
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="`${item.value}`">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div style="margin-top: 10px; margin-bottom: 10px;">
        <label>{{ $t('mapPage.dynamicLayers')}}</label>
        <button v-if="collapsed" class="btn btn btn-icon btn-primary custom-btn" @click="toggleHeight(this, 300); return false">
          <i class="tim-icons icon-simple-delete"></i>
        </button>
        <button v-else class="btn btn btn-icon btn-primary custom-btn" @click="toggleHeight(this, 300); return false">
          <i class="tim-icons icon-simple-add"></i>
        </button>
      </div>
      <div id="layers-container" style="height: 0px;" class="layers-container expandable">
        <div>
          <div class="network-element-content">
            <label>{{ $t('mapPage.legend')}}</label>
            <div class="network-item">
              <base-switch v-model='legendEnable' on-text="ON" off-text="OFF" @input="onLegendEnable(legendEnable)"></base-switch>
            </div>
          </div>
          <div class="divider-network"></div>
          <div v-for="(item,index) in layerToDisplay" v-bind:index="index" v-bind:item="item" v-bind:key="index" >
            <div class="network-element-content">
              <label>{{ item.label }}</label>
              <div class="network-item">
                <base-switch v-model='item.value' on-text="ON" off-text="OFF" @input="onFlowSelect(item)"></base-switch>
              </div>
            </div>
            <div class="divider-network"></div>
          </div>
        </div>
        
        
      </div>
      <div style="height: 20px;"></div>
    </div>
  </div>
</template>
<script>
import {Select, Option, OptionGroup} from 'element-ui';
import { BaseSwitch } from 'src/components';
import RoadNetwork from './../shared/roadNetwork';
import axios from 'axios';


export default {
  name: 'network-component',
  props: {
    'flow' : {type : Object},
    'instance' : {type : Object},
    'isLegend' : {type : Boolean}
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    BaseSwitch
  },
  watch: {
    'lines'(lines) {
      this.$emit('drawBusLines', [lines]);
    }
  },
  data () {
    return {
      layerToDisplay: [],
      displayContent: false,
      options: [],
      bus: RoadNetwork,
      lines: null,
      collapsed: false,
      dynamicLayer: false,
      legendEnable: false,
      display: {
        bus: true,
      }
    };
  },
  methods: {
    visibleChange(val) {
      console.log("visible change", val);
    },
    showNetwork() {
      this.displayContent=true;
    },
    hideNetwork() {
      this.displayContent=false;
    },
    toggleNetwork() {
      this.displayContent=!this.displayContent;
    },
    onFlowSelect(item){
      this.$emit('flowSelect', [item]);
    },
    onLegendEnable(item){
      this.$emit('legendEnable', item);
    },
    toggleHeight(e, maxHeight) {
      e = document.getElementById("layers-container");
      if(e.style.height != '0px') {
        e.style.height = '0px';
        e.style.overflowY = "hidden"; 
        this.collapsed=false;
      } else {
        //e.style.height = maxHeight + 'px';
        e.style.height = 'auto';
        e.style.overflowY = "auto";
        this.collapsed=true;
      }
    }
  },
  created() {
    let _this=this;
    let user = JSON.parse(window.localStorage.getItem('user'));
    let lang = user.lang;
    let dynamicLayersKeys = Object.keys(RoadNetwork[this.instance.city].dynamicLayers);
    dynamicLayersKeys.forEach((key) => {
      _this.display[key]=RoadNetwork[_this.instance.city].dynamicLayers[key];
      if (_this.display[key]) {
        let label = lang=='fr'?_this.display[key].label_fr:_this.display[key].label_en;
        _this.layerToDisplay.push({'key': key, 'value': false, 'label': label});
      }
    });
    axios.get('layer/list').then(response => {
      let data = response.data;
      this.dynamicLayer = true;
      if (data.success) {
        let res =  data.data; 
        res.forEach((layer,key) =>{
          _this.display[key]=key;
          let label = lang=='fr'?layer.label_fr:layer.label_en;
          _this.layerToDisplay.push({'key': key, 'value': false, 'label': label});          
        })

        return true;
      } 
      }).catch((e) => {
        console.log("error",e)
        _this.$notify({
          message: e.message,
          timeout:   10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    this.display.bus = RoadNetwork[_this.instance.city].bus;
    if (this.display.bus) {
      let lines = RoadNetwork[_this.instance.city].bus.lines.map((line) => {
        if (line.value=='Tout') {
          line.label= _this.$i18n.t('mapPage.networkLineAll');
        }
        return line;
      });
      this.options= [
        {key:'network1', label:'', options: lines}
      ];
    }
  }
}
</script>
<style>
  div.network-container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 25%;
    /*right: 15px;*/
    cursor: pointer;
    opacity: 1;
  }
  div.network-container div.network-content {
    position: absolute;
    top: 40px;
    width: 300px;
    max-height: 450px;
    background-color:white;
    right: 2px;
    padding: 10px;
    padding-top: 10px;
  }
  div.layers-container {
    max-height: 300px;
    overflow-y: hidden;
    width: 100%;
    /*margin-top: 15px;*/
  }
  div.layers-containser.expandable {
    padding-top: 10px;
    /*overflow-y: scroll;*/
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s;
  }

  div.network-header {
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-box-shadow: rgba(0,0,0,.3) 0 1px 4px -1px;
    box-shadow: rgba(0,0,0,.3) 0 1px 4px -1px;
    background: #FFF;
    text-align: center;
    background-color: #fff;
    filter: alpha(opacity=80);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  div.network-header a.settings-icon {
    height: 24px;
    width: 24px;
    display: block;
  }
  
  div.network-content div.divider-network {
    border-bottom: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  div.network-content label {
    font-weight: 600;
  }
  
  div.filters-project1 ul.el-scrollbar__view.el-select-dropdown__list {
    display:  flex !important;
  }
  
  div.filters-project1 .el-select-group__wrap:not(:last-of-type)::after {
    content: "";
    position: absolute;
    display: none;
    background: #e4e7ed;
    height: 0px;
  }
  div.el-select-dropdown.el-popper.is-multiple.filters-project1 {
    min-width: auto !important;
  }
  div.filters-project1 .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
    right: 0px !important;
  }
  div.filters-project1 ul.el-select-group__wrap {
    border-right: 1px solid #e4e7ed;
    padding-right: 10px;
    padding-left: 10px;
  }
  div.filters-project1 ul.el-select-group li.el-select-dropdown__item.selected {
    color: #e14eca !important;
  }
  div.filters-project1  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #e14eca !important;
  }
  div.network-element-content {
    display: flex;
    position: relative;
  }
  div.network-element-content label {
    padding-right: 10px;
    width: 70%;
  }
  div.network-element-content div.network-item {
    position: absolute;
    right: 10px;
  }
  /*
  div.network-element-content div.network-item div.bootstrap-switch.bootstrap-switch-wrapper.bootstrap-switch-animate.bootstrap-switch-off{
    right: 30px;
    position: absolute;
  }
  */
  div.network-element-content-bus div.el-select__tags {
    /*margin-top: 20px;
    min-height: 100px;*/
  }
  button.custom-btn {
    height: 1.375rem !important;
    min-width: 1.375rem !important;
    width: 1.375rem !important;
    font-size: .5375rem !important;
    float: right;
  }
</style>
