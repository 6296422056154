<template>
  <span class="amazing-exp-main py-2" :class="{ 'active': feedbackRating === 1 }">😄<span>{{ $t('feedbackForm.amazingExp') }}</span></span>
</template>

<script>

export default {
  name: 'AmazingExperience',
  props: {
    feedbackRating: {
      required: true,
      type: Number
    }
  },
}

</script>

<style>
.amazing-exp-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border: 1px solid #9f9797;
  border-radius: 8px;
  width: 150px;
  font-size: 14px;
  color: #9f9797;
}

.amazing-exp-main.active {
  color: white !important;
  background-color: #2E9139;
  font-weight: 600;
  border: none;
}
</style>