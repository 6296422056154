<template>
  <li class="timeline-inverted">
    <div class="timeline-badge info">
      {{indexPhase}}
    </div>
    <div class="timeline-panel">
      <div class="timeline-heading">
        <span class="badge badge-pill badge-info"> {{ $t('projectForm.phaseTitle') }} {{item.name}} </span>
        <div class="phase-remove-btn btn-danger" v-if="!statusOfUser" v-on:click="$emit('remove', [index, item.phaseId])"><span><i class="tim-icons icon-simple-remove"></i></span></div>
        <div class="closing-add-btn" v-if="!statusOfUser"  v-on:click="$emit('addClosing', [index, item.phaseId])"><i class="tim-icons icon-simple-add"></i><span></span></div>
      </div>
      <div class="timeline-body">
        <closing-component v-for="(item1, index1) in item.closing"  v-bind:key="item1.closingId" v-bind:item="item1" v-bind:layer="layer" v-bind:index="index1" v-bind:date="date" v-bind:indexPhase="indexPhase"
                           v-on:remove="removeClosing($event)"
                           v-on:addClosingDate="addClosingDate($event)"
                           v-on:removeClosingDate="removeClosingDate($event)"
                           v-on:updateClosingDate="updateClosingDate($event)"
                           v-on:showModal="$emit('showModal', [$event, item.phaseId])"
                           v-on:input="$emit('input', [$event, item.phaseId])"
                           :statusOfUser="statusOfUser">
        </closing-component>
      </div>
    </div>
  </li>
    <!--
    <time-line-item
      inverted
      title="1"
      badge-type="info timeline-phase-icon">
      <span slot="header" class="badge badge-pill badge-danger">Fermetures</span>
      <div slot="header" class="closing-add-btn"><i class="tim-icons icon-simple-add"></i><span></span></div>
      <closing-component slot="content"  v-for="item in closing"  v-bind:key="item.name" v-bind:item="item" ></closing-component>
    </time-line-item>
    -->
</template>
<script>
import { TimeLineItem } from 'src/components';

import ClosingComponent from './ClosingComponent.vue';
import ModalMapClosingComponent from './../map/ModalMapClosingComponent.vue';

export default {
  name: 'phase-component',
  computed: {
    indexPhase () {
      return this.index + 1;
    }
  },
  components: {
    TimeLineItem,
    ClosingComponent,
    ModalMapClosingComponent
  },
  props: ['item', 'index', 'date','statusOfUser','layer'],
  data () {
    return {
    };
  },
  methods: {
    removeClosing(params) {
      this.$emit('removeClosing', [params[0], this.item.phaseId, params[1]]);
    },
    addClosingDate(params) {
      this.$emit('addClosingDate', [params[0], this.item.phaseId, params[1]]);
    },
    removeClosingDate(params) {
      this.$emit('removeClosingDate', [params[0], params[1], params[2], this.item.phaseId]);
    },
    updateClosingDate(params) {
      this.$emit('updateClosingDate', [params[0], params[1], params[2], this.item.phaseId]);
    }
  }
}
</script>
<style>
  .timeline-heading {
    display: flex
  }
  .timeline-heading span.badge {
    margin-right: 40px;
  }
  .card.card-timeline .timeline::before {
    background-color: #FFF !important;
  }
  .card.card-timeline .timeline > li > .timeline-badge {
    left: 5%;
  }
  .card.card-timeline .timeline::before {
    left: 5%;
  }
  .card.card-timeline .timeline > li > .timeline-panel {
    width: 90%;
  }
  .phases-title {
    margin-left: 3%;
    margin-bottom:  0px !important;
  }
  .card.card-timeline .timeline > li > .timeline-badge {
    /*width: 60px !important;
    height: 60px !important;*/
  }
  .card.card-timeline .timeline > li:last-child > .timeline-badge.timeline-phase-icon {
    cursor: pointer;
    /*
    line-height: 60px !important;
    font-size: 0.8em*/
  }
  .closing-phases div.card.card-timeline.card-plain div.card-body ul.timeline li:last-child div.timeline-panel {
    display: none;
  }
</style>