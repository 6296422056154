<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar :background-color="sidebarBackground" short-title="MAP" v-bind:title="$t('sidebar.map')">
      <template slot-scope="props" slot="links">
        <!--
        <sidebar-item :link="{name: $t('sidebar.project'), icon: 'tim-icons icon-molecule-40', path: '/app'}">
          <sidebar-item :link="{ name: $t('sidebar.projectList'), path: '/projects/list' }" v-if="isAdmin || isUser || isViewer"></sidebar-item>
          <sidebar-item :link="{name: $t('sidebar.projectCreate'), path: '/projects/create'}" v-if="isAdmin || isUser"></sidebar-item>
        </sidebar-item>
        -->
        <sidebar-item :link="{ name: $t('sidebar.projectList'), icon: 'tim-icons icon-notes', path: '/projects/list' }" v-if="isAdmin || isAdminGis || isUser || isViewer  || isUserRes  || isViewerRes"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.projectConflicts'), icon: 'tim-icons icon-alert-circle-exc', path: '/projects/conflicts' }" v-if="(!!features.isCustomSidebar && features.isCustomSidebar.conflictPage) && (isAdmin || isAdminGis || isUser || isViewer  || isUserRes  || isViewerRes)"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.userList'), icon: 'tim-icons icon-badge', path: '/users/list' }" v-if="(!features.isCustomSidebar || !features.isCustomSidebar.userManagement)&&(isAdmin|| isAdminGis)"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.archiveList'), icon: 'tim-icons icon-components', path: '/archives/list' }" v-if="isAdmin|| isAdminGis"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.mapLayers') , icon: 'tim-icons icon-map-big', path: '/layer/list' }" v-if="isAdminGis"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.emailLogs'), icon: 'tim-icons icon-email-85', path: '/emails' }" v-if="(!!features.emailLogsOnSidebar && features.emailLogsOnSidebar.show)&&(isAdmin || isAdminGis|| isUser || isUserRes )"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.publicMap'), icon: 'tim-icons icon-world', path: '/app?publicpage=true' }" v-if="(!!features.isCustomSidebar && features.isCustomSidebar.publicMapPage)"></sidebar-item>
        <sidebar-item :link="{ name: $t('sidebar.projectTimeline'), icon: 'tim-icons timeline-icon', path: '/app?timeline=true' }" v-if="(!!features.isCustomSidebar && features.isCustomSidebar.timelinePage)"></sidebar-item>
        
        <div class="sidebar-feedback">

          <sidebar-item  :link="{ name: 'FAQ',  path: '/faq' }"  :customIcon="icons.help" v-if="(!!features.helpOnSidebar && features.helpOnSidebar.show)"></sidebar-item>
          <sidebar-item  :link="{ name: $t('sidebar.feedback'), icon: 'tim-icons icon-chat-33', path: '/feedback/list' }" v-if="(!!features.feedbackOnSidebar && features.feedbackOnSidebar.show)"></sidebar-item>
        </div>
        <!--
        <sidebar-item :link="{ name: $t('sidebar.user'), icon: 'tim-icons icon-notes' }">
          <sidebar-item :link="{ name: $t('sidebar.userList'), path: '/users/list' }" v-if="isAdmin"></sidebar-item>
          <sidebar-item :link="{ name: $t('sidebar.userCreate'), path: '/users/create'}" v-if="isAdmin"></sidebar-item>
        </sidebar-item>
        -->
      </template>
    </side-bar>
    <!--<sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>-->
    <div class="main-panel" :data="sidebarBackground">
      <app-navbar></app-navbar>
      <router-view name="header"></router-view>
      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>

import AppNavbar from './AppNavbar.vue';
import ContentFooter from './AppFooter.vue';
import AppContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import SidebarShare from './SidebarSharePlugin';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import Features from '../shared/features';
import icons from './../shared/icons';

export default {
  components: {
    AppNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    AppContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      instance: JSON.parse(window.localStorage.getItem('instance')),
      features : [],
      icons: icons,
    };
  },
  computed: {
    isAdmin() {
      return (window.localStorage.getItem('role')==='admin' || window.localStorage.getItem('role')==='adminGis');
    },
    isUser() {
      return window.localStorage.getItem('role')==='user';
    },
    isUserRes() {
      return window.localStorage.getItem('role')==='userRes';
    },
    isViewer() {
      return window.localStorage.getItem('role')==='viewer';
    },
    isViewerRes() {
      return window.localStorage.getItem('role')==='viewerRes';
    },
    isAdminGis(){
      return (window.localStorage.getItem('role')==='adminGis');
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    getAppSettings() {
      let user = JSON.parse(window.localStorage.getItem('user'));
      let settings = user.appsettings;
      this.sidebarBackground = this.instance.city === "safelane" ? 'orange' : settings.sidebarColor || 'vue';
      let docClasses = document.body.classList;
      if (settings.darkMode) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
      if (settings.sidebarMini) {
        docClasses.add('sidebar-mini');
        this.$sidebar.displaySidebar(false);
      } else {
        docClasses.remove('sidebar-mini');
        this.$sidebar.displaySidebar(true);
      }
      this.$sidebar.toggleMinimize();
    },
  },
  created() {
    this.getAppSettings();
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.features = Features[this.instance.city];
  }
};
</script>
<style lang="scss">
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
  .sidebar {
    z-index: 99999 !important;
  }
  
  div.filters-tags input.el-input__inner {
    border: 1px solid #dcdfe6;
  }
  div.filters-tags input div.el-select:hover input.el-input__inner {
    border-color: #c0c4cc;
  }
  div.filters-tags  .el-select div.el-input.is-focus input.el-input__inner {
    border-color: #409eff;
  }
  
  div.filters-range div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    border: 1px solid #dcdfe6;
    background: white;
    width: 40px;
  }
  
  div.filters-range div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #525f7f !important;
  }
  
  div.filters-range div.el-date-editor--datetimerange:hover,
  div.filters-range div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange:hover {
    border-color: #c0c4cc !important;
  }
  
  div.filters-range div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange.is-active {
    border-color: #409eff;
  }
  
  body div.list-project-tags input.el-input__inner {
    background-color: transparent;
    border: 1px solid #2b3553;
  }
  body div.list-project-tags  div.el-select:hover input.el-input__inner {
    border-color: #2b3553;
  }
  
  body div.list-project-tags span.el-tag.el-tag--info {
    border: none !important;
  }
  
  body.white-content div.list-project-tags input.el-input__inner {
    border: 1px solid #dcdfe6;
  }
  body.white-content div.list-project-tags  div.el-select:hover input.el-input__inner {
    border-color: #c0c4cc;
  }
  
  body div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    border: 1px solid #dcdfe6;
    background: white;
    width: 40px;
  }
  
  body div.list-project-date div.el-date-editor--datetimerange:hover {
    border-color: #c0c4cc;
  
  }  
  body div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    background: transparent;
  }
  
  body div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    border-color: #2b3553; 
  }
  body div.list-project-date div.el-date-editor--datetimerange:hover {
    border-color: #2b3553;
  }
  body div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: hsla(0,0%,100%,.8) !important;
  }
  body div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange.is-active {
    border-color: #409eff;
  }
  
  body.white-content div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    border: 1px solid  #dcdfe6;
  }
  
  body.white-content div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #525f7f !important;
  }
  
  body.white-content div.list-project-date div.el-date-editor--datetimerange:hover {
    color: #c0c4cc;
  }
  
  body.white-content div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #525f7f;
  }
  
  body.white-content div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange.is-active {
    border-color: #409eff;
  }
  
  body.white-content div.list-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #525f7f;
  }
  body.white-content div.create-project-date .el-date-editor .el-range-separator,
  body.white-content div.create-project-date div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #222a42!important;
  }
  body.white-content div.list-project-action .select-primary.el-select .el-input input,
  body.white-content div.list-project-type .select-primary.el-select .el-input input {
    border-color: #dcdfe6 !important;
  }
  
  body.white-content div.detour-date-content  .el-date-editor .el-range-separator,
  body.white-content div.detour-date-content div.el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange input.el-range-input {
    color: #222a42 !important;
  }
  
  body.white-content div.conflict-list-container table tbody tr:nth-child(odd) td{
    background: #f5f6fa;
  }
  body div.conflict-list-container table tbody td .span-date {
    border: 1px solid #3d3f51;
    padding: 10px;
    border-radius: 5px;
    background-color: #27293d;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  body.white-content div.conflict-list-container table tbody td .span-date {
    border: 1px solid #e8e8eb;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    margin-bottom: 10px;
    color: #746c7d !important;
  }
  
  div.list-project-action {
    margin-left: -5px !important;
    padding-left: 0px !important;
  }
  .modal.show .modal-dialog {
    -webkit-transform: translateY(15%);
    transform: translateY(15%);
  }
  
  .modal.show.modal-custom .modal-dialog {
    -webkit-transform: translateY(8%);
    transform: translateY(8%);
  }
  div.modal.show.modal-custom-map div.modal-dialog {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  body.modal-open.sidebar-mini div.modal {
    left: 0px;
    padding-left: 0px;
  }
  body.modal-open div.modal {
    /*left: 120px;*/
    padding-left: 120px;
  }
  .modal-header {
  }
  
  .modal-dialog {
    max-width: 80%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 800px !important;
  }
  .modal-content {
  }
  .map-content {
    height: 600px;
  }
  
  div.modal.show.modal-custom-map div.modal-dialog div.modal-content {
    width: 100%;
    border-radius: .2857rem;
  }
  
  div.modal.show.modal-custom-map.filters-activated div.modal-dialog div.modal-content {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  div.modal.show.modal-custom-map div.modal-dialog div.modal-content div.modal-body {
    width: 100%;
  }
  
  div.modal.show.modal-custom-map.filters-activated div.modal-dialog div.modal-content div.modal-body {
    width: 70%;
  }
  
  div.modal.show.modal-custom-map div.modal-dialog div.modal-dialog div.modal-content div.modal-header {
    width: 100%;
  }
  
  div.modal.show.modal-custom-map.filters-activated  div.modal-dialog div.modal-content div.modal-header {
    width: 70%;
    position: relative;
  }
  
  div.modal.show.modal-custom-map div.modal-dialog div.modal-dialog div.modal-content div.modal-footer {
    width: 100%;
  }
  
  div.modal.show.modal-custom-map.filters-activated  div.modal-dialog div.modal-content div.modal-footer {
    width: 70%;
  }
  
  div.modal.show.modal-custom-map div.modal-dialog div.modal-filters-container {
    width: 30%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    height: 823px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .2857rem;
    border-bottom-right-radius: .2857rem;
    border-left: 1px solid #b0b0b1;
  }
  
  body.white-content div.filters-project ul li {
    color: #909399 !important;
  }
  div.filters-project li.el-select-group__title {
    font-weight: bold;
  }
  /*
  div.modal.show.modal-custom-map {
    background-color: #1e1e2e;
  }
  */
  div.modal-custom-alert div.modal-footer,
  div.modal-custom-users div.modal-footer,
  div.modal-custom div.modal-footer {
    padding: 24px 24px 16px 24px !important;
  }
  div.modal-custom-map div.modal-footer {
    padding: 13px 24px 13px 24px !important;
  }
  .login-page .card-login .card-header {
    min-height: 100px !important;
  }
  .sidebar-feedback{
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
  }
  .timeline-icon{
    height: 22px;
    width: 22px !important;
    margin: 6px;
    background-size: cover;
    background-image: url('../../../public/img/icons/ganttchart1.svg');
  }
  
</style>
