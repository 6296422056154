<template>
  <div>
    <modal :show.sync="appSettings" headerClasses="justify-content-center" type="custom" :scrollToBottom="false" v-on:hideModal="hideModal">
      <h4 slot="header" class="title title-up">{{ $t('settings.title') }}</h4>
      <template slot="close-button">
        <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="col-md-12 app-settings-container">
        <tabs type="primary">
          <tab-pane v-bind:label="$t('settings.tab1')">
            <div class="row map-image-container">
              <!--<label class="col-sm-2 col-form-label">Map</label>-->
                <div class="col-sm-3 map-image-content" v-for="item in maps" :class="[ { selected: item.active }]">
                  <img class='selected' :src="item.fname" @click="changeMap(item);">
                  <label>{{item.label}}</label>
                </div>
                <!--<div class="flex-div">
                  <base-radio class="col-sm-4" v-model="typeMap" name="topo">Topo</base-radio>
                  <base-radio class="col-sm-4" v-model="typeMap" name="topo-vector">Topo vector</base-radio>
                  <base-radio class="col-sm-4" v-model="typeMap" name="osm">Osm</base-radio>
                </div>
                <div class="flex-div">
                  <base-radio class="col-sm-4" v-model="typeMap" name="streets">Streets</base-radio>
                  <base-radio class="col-sm-4" v-model="typeMap" name="streets-vector">Streets vector</base-radio>
                  <base-radio class="col-sm-4" v-model="typeMap" name="streets-navigation-vector">Streets navigation vector</base-radio>
                </div>-->
            </div>
          </tab-pane>
          <tab-pane v-bind:label="$t('settings.tab2')">
            <div>
              <div class="row theme-row">
                <label class="col-sm-4 col-form-label">{{ $t('settings.sidebar') }} </label>
                <div class="col-sm-8 colors-div">
                  <div class="col-sm-8">
                    <a class="switch-trigger background-color">
                      <div class="badge-colors text-center">
                        <span
                          v-for="item in sidebarColors"
                          :key="item.color"
                          class="badge filter"
                          :class="[`badge-${item.color}`, { active: item.active }]"
                          :data-color="item.color"
                          @click="changeSidebarBackground(item);">
                        </span>
                      </div>
                      <div class="clearfix"></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row theme-row">
                <label class="col-sm-4 col-form-label">{{ $t('settings.largeSidebar') }}</label>
                <div class="col-sm-8">
                  <div class="togglebutton switch-sidebar-mini">
                    <span class="label-switch">OFF </span>
                    <base-switch v-model="sidebarMini" @input="minimizeSidebar"></base-switch>
                    <span class="label-switch label-right"> ON</span>
                  </div>
                </div>
              </div>
              <div class="row theme-row">
                <label class="col-sm-4 col-form-label">{{ $t('settings.darkMode') }}</label>
                <div class="col-sm-8">
                  <span class="label-switch">OFF </span>
                  <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
                  <span class="label-switch label-right"> ON</span>
                </div>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
      <template slot="footer">
        <base-button type="danger" v-on:click="hideModal()">{{ $t('settings.btnClose') }}</base-button>
        <base-button @click='saveSettings()'>{{ $t('settings.btnSave') }}</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
  
import { Modal } from 'src/components';
import { TabPane, Tabs } from 'src/components';
import { BaseRadio } from 'src/components/index';
import { BaseSwitch } from 'src/components';

export default {
  name:'app-settings',
  props: ['appSettings'],
  components: {
    Modal,
    BaseRadio,
    TabPane,
    Tabs,
    BaseSwitch
  },
  data() {
    return {
      map: {
        typeMap: 'streets',
        modeMap: '0'
      },
      sidebarMini: false,
      darkMode: false,
      sidebarColors: [
        { color: 'primary', active: false, value: 'primary' },
        { color: 'vue', active: true, value: 'vue' },
        { color: 'info', active: false, value: 'blue' },
        { color: 'success', active: false, value: 'green' },
        { color: 'warning', active: false, value: 'orange' },
        { color: 'danger', active: false, value: 'red' }
      ],
      maps: [
        { active: true,  modeMap: '0', key:'osm', label: 'OpenStreetMap', fname: 'static/img/map/osm.jpg' },
        { active: false, modeMap: '0', key:'streets', label: 'Streets', fname: 'static/img/map/streets.jpg' },
        { active: false, modeMap: '0', key:'topo', label: 'Topographic', fname: 'static/img/map/topo.jpg' },
        { active: false, modeMap: '0', key:'dark-gray', label: 'Dark Gray Canvas', fname: 'static/img/map/dark_gray_canvas.png' },
        { active: false, modeMap: '0', key:'gray', label: 'Light Gray Canvas', fname: 'static/img/map/light_gray_canvas.jpg' },
        { active: false, modeMap: '0', key:'satellite', label: 'Imagery', fname: 'static/img/map/imagery.jpg' },
        { active: false, modeMap: '0', key:'national-geographic', label: 'National Geographic', fname: 'static/img/map/nat_geo.jpg' },
        { active: false, modeMap: '0', key:'terrain', label: 'Terrain with Labels', fname: 'static/img/map/terrain_labels.jpg' },
        { active: false, modeMap: '1', key:'098132d21c014e62908d847e3b74d740', label: 'Dark Grey-Community Map of Canada', fname: 'static/img/map/dark_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'e1de1cfd7a624639b65e2cc0f10aad70', label: 'Light Grey-Community Map of Canada', fname: 'static/img/map/light_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'41fff870ffc2461ab79cd949da2ac1bc', label: 'Streets (Night)-Community Map of Canada', fname: 'static/img/map/streets_night_canada.png' },
        { active: false, modeMap: '1', key:'cba1e859423a424eb26fb73c5df5ecdf', label: 'Streets-Community Map of Canada', fname: 'static/img/map/streets_canada.png' },
        { active: false, modeMap: '1', key:'98652eb8458a464fa95feb9bd812b29a', label: 'Topographic-Community Map of Canada', fname: 'static/img/map/topographic_canada.jpeg' }
      ]
    };
  },
  mounted() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    let instance = JSON.parse(window.localStorage.getItem('instance'));
    let city = instance.city;
    let settings = user.appsettings;
    let _this=this;

    if( city === 'sjsr'){
      this.maps.push({ active: false, modeMap: '2', key:'92c9dbcdd3ba4b95a07c2116c7b1cb09', label: 'SJSR Custom basemap', fname: 'static/img/map/sjsr.png' });
    }
    if (settings && settings.map && settings.map.typeMap) {
      this.map.typeMap=settings.map.typeMap;
      this.map.modeMap=settings.map.modeMap;
      this.maps.forEach( e => e.active = (e.key === _this.map.typeMap));
    }
    if (settings && settings.sidebarColor) {
      this.sidebarColor=settings.sidebarColor;
      this.sidebarColors.forEach( e => e.active = (e.value === _this.sidebarColor));
    }
    if (settings && settings.sidebarMini) {
      this.sidebarMini=settings.sidebarMini;
    }
    if (settings && settings.darkMode) {
      this.darkMode=settings.darkMode;
    }
    if (settings && settings.darkMode) {
      this.darkMode=settings.darkMode;
    }
  },
  methods: {
    hideModal() {
      this.$emit('hideAppSettings');
    },
    changeSidebarBackground(item) {
      this.toggleList(this.sidebarColors, item);
    },
    toggleList(list, itemToActivate) {
      list.forEach(listItem => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    },
    changeMap(item) {
      this.maps.forEach(listItem => {
        listItem.active = false;
      });
      item.active=true;
      this.map.typeMap=item.key;
      this.map.modeMap=item.modeMap;
    },
    saveSettings() {
      let _this=this;
      let selected = this.sidebarColors.find( e => e.active === true );
      let obj = {
        map: this.map,
        sidebarColor: selected.value || 'vue',
        sidebarMini: this.sidebarMini,
        darkMode: this.darkMode
      };
      axios.post('user/settings', obj).then(response => {
        let data = response.data;
        if (data.success) {
          window.localStorage.setItem('user', JSON.stringify(data.data));
          this.$notify({
            message: _this.$i18n.t('settingsMess.successSettings'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          _this.$router.go();
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message: _this.$i18n.t('settingsMess.errorSettings'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: _this.$i18n.t('serverReply.errorUserCreate'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    }
  }
}
</script>
<style>
  div.app-settings-container .tab-content .tab-pane {
    color: rgb(82, 95, 127) !important;
  }
  div.app-settings-container span.badge {
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
  }
  div.app-settings-container  span.badge-vue {
    background-color: #42b883 !important;
  }
  div.app-settings-container span.badge.active, div.app-settings-container.span.badge:hover {
    border-color: #1d253b;
  }
  div.modal-custom div.modal-dialog {
    width: 45% !important;
  }
  div.theme-row {
    margin: 0px;
    margin-bottom: 15px;
  }
  div.row.theme-row label {
    padding-left: 5px;
  }
  
  div.colors-div {
    padding-top: 5px;
    margin-left: -45px;
  }
  div.flex-div {
    display: flex;
  }
  div.map-image-container {
    margin: 0;
    height: 900px;
    overflow: auto;
  }
  
  div.map-image-content {
    padding: 10px;
    display: grid;
  }
  div.map-image-content label {
    font-weight: 600;
    text-align: center;
  }
  
  div.map-image-content img {
    cursor: pointer;
  }
  div.map-image-content img {
    border: 1px solid #000;
  }
  div.map-image-content.selected img {
    border-color: #fff;
    box-shadow: 3px 3px 10px #000;
  }
</style>