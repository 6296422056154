<template>
    <div>
        <el-table :data="informations.agents">
            <el-table-column min-width="150" :label="$i18n.t('quickCreate.responsible.name')">
                <template slot-scope="scope">
                    <base-input v-if="authorTable.editingRowIndex === scope.$index" v-model="scope.row.name"
                        name="name"  
                        v-validate="modelValidations.name"
                        :class="{'has-danger':errors.has('name')}">
                    </base-input>
                    <div v-else>{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" :label="$i18n.t('quickCreate.responsible.role')">
                <template slot-scope="scope">
                    <base-input v-if="authorTable.editingRowIndex === scope.$index" v-model="scope.row.role"></base-input>
                    <div v-else>{{ scope.row.role }}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" :label="$i18n.t('quickCreate.responsible.phone')">
                <template slot-scope="scope">
                    <base-input v-if="authorTable.editingRowIndex === scope.$index" v-model="scope.row.phone"></base-input>
                    <div v-else>{{ scope.row.phone }}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="200" :label="$i18n.t('quickCreate.responsible.email')">
                <template slot-scope="scope">
                    <base-input v-if="authorTable.editingRowIndex === scope.$index" v-model="scope.row.email"
                        name="email"  
                        v-validate="modelValidations.email"
                        :class="{'has-danger':errors.has('email')}">
                    </base-input>
                    <div v-else>{{ scope.row.email }}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" :label="$i18n.t('quickCreate.responsible.organization')">
                <template slot-scope="scope">
                    <base-input v-if="authorTable.editingRowIndex === scope.$index"
                        v-model="scope.row.organization"></base-input>
                    <div v-else>{{ scope.row.organization }}</div>
                </template>
            </el-table-column>


            <el-table-column>
                <template slot-scope="scope" v-if="!scope.row.isAuthor">
                    <el-tooltip v-if="authorTable.editingRowIndex === scope.$index"
                        :content="$i18n.t('stakeholder.tableSave')" :open-delay="300" placement="top">
                        <base-button type="success" size="sm" round icon @click="saveRow">
                            <i class="tim-icons icon-check-2"></i>
                        </base-button>
                    </el-tooltip>
                    <el-tooltip v-else :content="$i18n.t('stakeholder.tableEdit')" :open-delay="300" placement="top">
                        <base-button type="success" v-if="!scope.row.isStakeholder" size="sm" round icon
                            @click="editRow(scope.$index)">
                            <i class="tim-icons icon-pencil"></i>
                        </base-button>
                    </el-tooltip>

                    <el-tooltip :content="$i18n.t('stakeholder.tableDelete')" :open-delay="300" placement="top">
                        <base-button type="danger" size="sm" round icon @click="deleteRow(scope.$index)">
                            <i class="tim-icons icon-simple-remove"></i>
                        </base-button>
                    </el-tooltip>

                </template>
            </el-table-column>

        </el-table>


        <el-form ref="authorForm" :model="authorTable.newRowData" v-if="authorTable.isAddingRow" inline
            class="author-form-row">
            <el-form-item :label="$i18n.t('quickCreate.responsible.name')" prop="name"
                class="author-form-column author-form-row-name">
                <base-input :placeholder="$i18n.t('quickCreate.responsible.name')" 
                    name="name"  
                    v-validate="modelValidations.name"
                    :class="{'has-danger':errors.has('name')}"
                    v-model="authorTable.newRowData.name"></base-input>
                    <label v-if="errors.has('name')" class="error">
                              {{ $t('projectForm.requiredField') }}
                    </label>
            </el-form-item>
            <el-form-item :label="$i18n.t('quickCreate.responsible.role')"
                class="author-form-column author-form-row-role">
                <base-input :placeholder="$i18n.t('quickCreate.responsible.role')"
                    v-model="authorTable.newRowData.role"></base-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('quickCreate.responsible.phone')" prop="phone"
                class="author-form-column author-form-row-phone">
                <base-input :placeholder="$i18n.t('quickCreate.responsible.phone')"
                    v-model="authorTable.newRowData.phone"></base-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('quickCreate.responsible.email')" prop="email"
                class="author-form-column author-form-row-email">
                <base-input :placeholder="$i18n.t('quickCreate.responsible.email')"
                    name="email"  
                    v-validate="modelValidations.email"
                    :class="{'has-danger':errors.has('email')}"
                    v-model="authorTable.newRowData.email"></base-input>
                <label v-if="errors.has('email')" class="error">
                            {{ $t('projectForm.validEmail') }}
                </label>
            </el-form-item>
            <el-form-item :label="$i18n.t('quickCreate.responsible.organization')"
                class="author-form-column author-form-row-organization">
                <base-input :placeholder="$i18n.t('quickCreate.responsible.organization')"
                    v-model="authorTable.newRowData.organization"></base-input>
            </el-form-item>
            <!-- ... other input fields ... -->
            <el-form-item class="author-form-column ">
                <el-tooltip :content="$i18n.t('stakeholder.tableSave')" :open-delay="300" placement="top">
                    <base-button type="success" size="sm" round icon @click="saveRow">
                        <i class="tim-icons icon-check-2"></i>
                    </base-button>
                </el-tooltip>

                <el-tooltip :content="$i18n.t('stakeholder.tableDelete')" :open-delay="300" placement="top">
                    <base-button type="danger" size="sm" round icon @click="authorTable.isAddingRow = false">
                        <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                </el-tooltip>
            </el-form-item>
        </el-form>
        <div :class="{ 'stackholder-search': isStackholderActive }">

            <div class="stackholder-search-box" v-if="isStackholderActive">
                <multiselect ref="multiselect" v-model="selectedValue" :options="filteredUsers" :multiple="true"
                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                    :placeholder="$i18n.t('stakeholder.searchPlaceholder')" label="name" track-by="name"
                    :preselect-first="false" :searchable="true" :internal-search="false"
                    @search-change="updateSearchQuery" :loading="isLoading" selectLabel="" deselectLabel=""
                    selectedLabel="">


                    <template slot="option" slot-scope="{ option }">
                        <div class="multiselect-dropdown">
                            <span style="">{{ option.name }} - </span> <span class="label-email">{{ option.email
                                }}</span>
                        </div>
                    </template>
                    <template slot="afterList">
                        <div class="dropdown-footer">
                            <base-button type="primary" @click="addStackholder">{{ $t('stakeholder.btnAdd')
                                }}</base-button>
                            <base-button type="default" @click="addNewRow">{{ $t('stakeholder.btnAddOther')
                                }}</base-button>
                        </div>
                    </template>
                    <span slot="noResult">
                        {{ $t('stakeholder.labelNoSearchResult') }}
                    </span>
                </multiselect>
            </div>

            <div class="add-btn">
                <base-button type="primary" simple round @click="searchStackholder"
                    :class="{ isClose: isStackholderActive }">
                    <span>+</span>
                </base-button>
            </div>
        </div>
    </div>

</template>

<script>

import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { Select, Option, Input } from 'element-ui';
import Multiselect from 'vue-multiselect'
import axios from 'axios';

Vue.use(Table)
Vue.use(TableColumn)

export default {
    name: 'StakeholderComponent',
    props: ['informations'],
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        [Input.name]: Input,
        Multiselect
    },
    data() {
        return {
            authorTable: {
                isAddingRow: false,
                editingRowIndex: false,
                newRowData: {
                    name: '',
                    role: '',
                    phone: '',
                    email: '',
                    organization: '',
                    isAuthor: false
                },
                editingRowData: {},
            },

            selectedValue: null,
            users: [],
            searchQuery: '',
            isLoading: false,
            isStackholderActive: false,
            modelValidations: {
                name: {
                    required: true
                },
                email: {
                    required: false,
                    email: true
                },
            }
        }
    },
    computed: {
        filteredUsers() {
            if (!this.searchQuery) return this.users;
            return this.users.filter(user =>
                user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    watch: {
        // Watch for changes in the 'parentValue' prop
        informations(info) {
            this.renameKeyInArray(info.agents,"inchargeof","name")
            this.renameKeyInArray(info.agents,"organisation","organization")
            this.informations = info; // Update the child's model
            if (!this.hasAuthorKey(this.informations.agents)) {
                this.informations.agents.unshift({
                    name: this.informations.inchargeof,
                    role: this.informations.role,
                    phone: this.informations.phone,
                    email: this.informations.email,
                    organization: '',
                    isAuthor: true
                })
            }
        }

    },
    methods: {
        addNewRow() {
            this.$refs.multiselect.deactivate()
            this.authorTable.isAddingRow = true;
            this.authorTable.editingRowIndex = null;
            this.authorTable.newRowData = {
                name: '',
                role: '',
                phone: '',
                email: '',
                organization: ''
            };
        },

        editRow(index) {
            this.authorTable.editingRowIndex = index;
            this.authorTable.isAddingRow = false;
        },

        async saveRow() {
            const valid = await this.$validator.validateAll();
            if (valid) {
                if (this.authorTable.isAddingRow) {
                    this.informations.agents.push({ ...this.authorTable.newRowData });
                    this.authorTable.isAddingRow = false;
                } else {
                    this.informations.agents[this.authorTable.editingRowIndex] = { ...this.informations.agents[this.authorTable.editingRowIndex] };
                    this.authorTable.editingRowIndex = null;
                }
            } 
        },
        deleteRow(index) {
            this.informations.agents.splice(index, 1);
            this.$notify({
                message: this.$i18n.t('quickCreate.message.handleDelete'),
                timeout: 3000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
            });
        },
        searchStackholder() {
            this.isStackholderActive = !this.isStackholderActive;
        },

        limitText(count) {
            return `and ${count} other Stakeholders`
        },
        async fetchUsers() {
            this.isLoading = true;
            try {
                const response = await axios.get('user/list/all');
                this.users = response.data.data.map(user => ({
                    uuid: user.uuid,
                    name: user.name,
                    email: user.email,
                    role: user.title,
                    phone: user.phone,
                    isStakeholder: true
                }));
            } catch(error) {
                console.error("Error fetching users:", error);
            } finally {
                this.isLoading = false;
            }

        },
        updateSearchQuery(query) {
            this.searchQuery = query;
        },
        addStackholder() {
            if(this.selectedValue != null) {
            this.$refs.multiselect.deactivate()
            this.informations.agents.push(...this.selectedValue)
            this.selectedValue = null;
        } else {
            this.$notify({
                message: "Please select stakeholder",
                timeout: 3000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger'
            });
        }

    },
    hasAuthorKey(mainObj) {
        return Object.values(mainObj).some(nestedObj => nestedObj.isAuthor === true);
    },
    renameKeyInArray(array, oldKey, newKey) {
        array.forEach(item => {
        if (oldKey in item) { // Check if the item has the old key using 'in'
            item[newKey] = item[oldKey]; // Assign the value to the new key
                delete item[oldKey];         // Remove the old key
            }
        });
    }
},

created() {
    this.fetchUsers();
}

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.author-form-row {
    /* display: table-row; */
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 2fr 1fr auto;
    grid-gap: 16px;
    margin-left: 16px;
}

.author-form-column {
    /* display: table-cell; */
}

.author-form-row-name {
    min-width: 150px;
    /* width:100%; */
}

.author-form-row-role {
    min-width: 100px;
    /* width:100%; */
}

.author-form-row-phone {
    /* width:100%; */
    min-width: 100px;
}

.author-form-row-email {
    /* width:100%; */
    min-width: 200px;
}

.author-form-row-organization {
    /* width:100%; */
    min-width: 100px;
}


.multiselect-dropdown {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.multiselect-checkbox {
    transform: scale(1.3);
}

.multiselect-dropdown span {
    margin-left: 8px;
}



.dropdown-footer {
    position: sticky;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    padding: 8px;
    width: 100%;
    text-align: center;
    /* background: white; */
    background: #222a42;
    /* Match the dropdown background */
    border-top: 1px solid #eee;
    /* Optional: add a border top for separation */
}


.white-content .dropdown-footer{
    background: white;

}

.add-btn {
    text-align: center;
}

.add-btn button {
    font-size: 24px;
    padding: 0 16px !important;
    font-weight: 400;

}


.add-btn span {
    transition: 500ms rotate;
}

.add-btn .isClose {
    margin-left: 16px;
}

.add-btn .isClose span {
    rotate: 45deg;
}

.stackholder-search {
    display: flex;
    /* flex-direction: row-reverse; */
}

.stackholder-search-box {
    width: 800px;
}

.white-content .label-email {
    color: #A1A2A8;
}

/* multiselect__option--selected */

.multiselect__option--selected .label-email{
    color: #A1A2A8;
}

.white-content .multiselect__option--highlight .label-email {
    color: #fff;
}

.multiselect__option--highlight .label-email {
    color: none;
}
.white-content .multiselect__tags{
    background: #fff;
}

.multiselect__tags, .multiselect__tags input{
    background: #222a42;
    color: #A1A2A8;
}

.white-content .multiselect__tags,.white-content .multiselect__tags input{
    background: #fff;
    color: #000;
}

.white-content .multiselect .multiselect__content-wrapper{
    background: #fff;
}

.multiselect .multiselect__content-wrapper{
    background: #222a42;
}


</style>