<template>
  <div class="projects-details-content">
    <card v-for="(item, index) in projects" :key='index' >
      <template slot="header"></template>
      <collapse-project  :multiple-active="false" :active-index="0">
        <collapse-item-project :id="item.uuid" :title="item.informations.name" class="project-details-item" v-on:close="$emit('close', [item.uuid])">
          <card class="card-subcategories card-plain projects-tabs-container">
            <template slot="header" >
              &nbsp;
              <a v-show="isAdmin || isUser" @click='goTo(item.uuid)'><i class="tim-icons icon-pencil"></i></a>
              <a v-show="isViewer" @click='goToView(item.uuid)'><i class="tim-icons icon-zoom-split"></i></a>
            </template>
            <tabs type="primary" tabContentClasses="tab-subcategories col-md-12" square class="row" >
              <tab-pane>
                <span slot="label"><i class="tim-icons icon-atom"></i></span>
                <div class="project-details-item-container">
                  <div class="project-details-item-element">
                    <i class="tim-icons icon-square-pin"></i>
                    <div class="project-details-item-title">
                      <span>{{item.entraves | computedTitle}} </span>
                    </div>
                  </div>
                  <div class="project-details-item-element">
                    <i class="tim-icons icon-calendar-60"></i>
                    <div class="project-details-item-date">
                      <span>{{ $t('mapPage.from')}} </span>
                      <strong>{{item.entraves | computedSDate}} </strong>
                      <span>{{ $t('mapPage.to')}} </span>
                      <strong>{{item.entraves | computedEDate}} </strong>
                    </div>
                  </div>
                  <div v-show="showTags" class="project-details-item-element">
                    <i class="tim-icons icon-tag"></i>
                    <div v-html='computedTags(item.informations)' class="project-details-item-tags">
                      <!--<span class="badge badge-info">Some Title</span>-->
                    </div>
                  </div>
                  <div class="project-details-item-element">
                    <div class="grid-div">
                      <strong>{{ $t('mapPage.inchargeof')}} </strong>
                      <span>{{item.informations.inchargeof}}</span>
                    </div>
                  </div>
                  <div class="project-details-item-element">
                    <div class="grid-div">
                      <strong>{{ $t('mapPage.note')}}</strong>
                      <span v-html='item.informations.comment'></span>
                    </div>
                  </div>
                </div>
              </tab-pane>
  
              <tab-pane>
                <span slot="label"><i class="tim-icons icon-bullet-list-67"></i></span>
                <div class="phases-container">
                  <phases-details v-bind:selectedColor='divStyle.backgroundColor' v-bind:entravesTab='item.entraves' v-bind:phasesTab='item.phases' v-bind:display="display"
                    v-on:uncheck="$emit('uncheck', [item.uuid, $event])"
                    v-on:check="$emit('check', [item.uuid, $event])" ></phases-details>
                </div>
                <div class="project-details-item-element">
                  <div class="grid-div">
                    <strong>{{ $t('mapPage.chooseColor')}}</strong>
                    <div class="choose-color-container">
                      <ul class="navbar-nav" :class="ml-auto">
                        <base-dropdown
                          tag="li"
                          :menu-on-right="!$rtl.isRTL"
                          title-tag="a"
                          title-classes="nav-link"
                          class="nav-item color-picker dropup color-dropup">
                          <template slot="title">
                            <div class="choose-color-content" :style="divStyle"></div>
                          </template>
                          <li class="nav-link">
                            <color-picker :color='divStyle.backgroundColor' :visible-formats="['hex']" @color-change="updateColor" />
                          </li>
                        </base-dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </tab-pane>
  
              <!--<tab-pane>
                <span slot="label"><i class="tim-icons icon-settings"></i></span>
                3rd pane
              </tab-pane>-->
            </tabs>
          </card>
        </collapse-item-project>
      </collapse-project>
    </card>
  </div>
</template>

<script>
import { TabPane, Tabs, CollapseProject, CollapseItemProject } from 'src/components';
import { ColorPicker } from 'vue-accessible-color-picker';

import moment from 'moment';

import PhasesDetails from  './PhasesDetailsComponent.vue';
import filters from './../shared/filters';
import RoadNetwork from './../shared/roadNetwork';
import HelpersMaps from './../helpers/helpersMap';

export default {
  name: 'projects-details',
  components: {
    TabPane,
    Tabs, 
    CollapseProject,
    CollapseItemProject,
    ColorPicker,
    PhasesDetails
  },
  props: ['projects', 'display', 'instance'],
  filters: {
    computedTitle(entraves) {
      return entraves.entraves[0].value;
    },
    computedSDate(entraves) {
      let dates = entraves.date;
      //return moment(dates[0]).format('DD-MM-YYYY hh:mm:ss');
      return moment(dates[0]).format('DD-MM-YYYY');
    },
    computedEDate(entraves) {
      let dates = entraves.date;
      //return moment(dates[1]).format('DD-MM-YYYY hh:mm:ss');
      return moment(dates[1]).format('DD-MM-YYYY');
    }
  },
  watch: {
    projects(val){
      if (val.length>0) {
        this.divStyle.backgroundColor=val[0].color;
      }
      this.showTags = RoadNetwork[this.instance.city].displayTags;
    }
  },
  created() {
  },
  mounted () {},
  computed: {
    isAdmin() {
      return (window.localStorage.getItem('role')==='admin' || window.localStorage.getItem('role')==='adminGis');
    },
    isUser() {
      return window.localStorage.getItem('role')==='user';
    },
    isUserRes() {
      return window.localStorage.getItem('role')==='userRes';
    },
    isViewer() {
      return window.localStorage.getItem('role')==='viewer';
    },
  },
  data() {
    return {
      divStyle: {
        backgroundColor:"#f80b" 
      },
      showTags: true
    };
  },
  methods: {
    updateColor(event) {
      this.divStyle.backgroundColor=event.colors.hex;
    },
    goTo(uuid) {
      window.location.href='/#/projects/edit?uuid='+uuid;
    },
    goToView(uuid) {
      window.location.href='/#/projects/view?uuid='+uuid;
    },
    computedTags(informations) {
      let tags='';
      let _this=this;
      let keys = Object.keys(filters[this.instance.city]);
      keys.forEach(function(key) {
        let filter = filters[_this.instance.city][key].value;
        let values = informations[key];
        if (!Array.isArray(values)) {
          values = [values+''];
        }
        values.forEach((value) => {
          filter.forEach((element) => {
            if (element.value==value) {
              tags += ' <span class="badge badge-info">' + element.label + '</span>';
            }
          });
        });
      });
      return tags;
    }
  }
};
</script>
<style>
  div.projects-details-content div.card-header {
    margin-top: 15px;
  }
  div.projects-details-content  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    float: left !important;
    top: 5px !important;
  }
  div.projects-details-content  a.collapsible {
    margin-top: -20px;
    margin-left: 0px;
  }
  div.projects-details-content  i.tim-icons.icon-minimal-down {
    margin-right: 10px;
  }
  div.projects-details-content i.tim-icons.icon-simple-remove {
    float: right;
    margin-top: -15px;
    cursor: pointer;
  }
  
  div.projects-details-content div.card-body {
    padding: 5px;
  }
  div.projects-details-content ul.nav-pills li.nav-item i {
    line-height: 24px;
  }
  div.projects-details-content .nav-pills .nav-item .nav-link {
    min-width: 50px !important;
    padding: 6px 12px !important;
  }
  div.projects-tabs-container div.card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  div.project-details-item div.card-body {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  div.project-details-item div.card-body div.tab-content.tab-space.tab-subcategories {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
  }
  div.project-details-item-container div.project-details-item-element {
    margin-bottom: 20px;
    display: flex;
  }
  div.project-details-item-container div.project-details-item-element > div {
    margin-left: 10px;
  }
  div.project-details-item-container div.project-details-item-element i {
    font-size: 18px;
  }
  div.project-details-item-container div.project-details-item-element div.project-details-item-tags span.badge {
    margin-right: 10px;
  }
  div.grid-div {
    display: grid;
    margin-left: 0px !important;
  }
  div.projects-tabs-container div.card-header {
    position: absolute !important;
    right: -20px;
    top: -20px;
    display: flex;
  }
  div.projects-tabs-container div.card-header i {
    cursor: pointer;
  }
  div.projects-tabs-container div.card-header a {
    margin-right: 10px;
  }
  div.projects-tabs-container div.card-header div.project-color {
    height: 26px;
    width: 24px;
    cursor: pointer;
  }
  div.choose-color-container {
    z-index:9;
    margin-top: 15px;
  }
  div.choose-color-container ul li:first-child a {
    text-align: right;
    color: black;
  }
  div.choose-color-content {
    width: 32px;
    height: 32px;
    border: 2px solid #eee;
    float:left;
    margin-top: -10px;
  }
  li.color-picker ul.dropdown-menu.dropdown-menu-right {
    margin-top: 20px;
  }
</style>