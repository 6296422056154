<template>
  <div class="entrave-element" >
    <div class="" style="display: flex; padding: 0">
      <base-input
        v-model="item.value"
        class="entrave-component"
        v-bind:class="[item.showError?'has-danger':'']"
        @input="$emit('input', index)"
        @click.native="$emit('showModal', [index, item])"
        v-on:keydown="keyDown"
        v-bind:placeholder="$t('projectForm.placeholderProjectEntrave')"
        addon-left-icon="tim-icons icon-map-big">
      </base-input>
      <button class="btn btn-icon btn-simple btn-dribbble entrave-remove" v-on:click="$emit('remove', index)">
        <i class="tim-icons icon-simple-remove" ></i>
      </button>
    </div>
    <label v-show="item.showError" class="error">{{ $t('projectForm.requiredField') }}</label>
  </div>
</template>
<script>
export default {
  name: 'entrave-component',
  props: ['item', 'index'],
  data () {
    return {};
  },
  methods: {
    keyDown(event) {
      if (event.key!=='Enter') {
        event.preventDefault();
      }
    }
  }
}
</script>
<style>
  .entrave-remove {
    margin: 0 !important;
    margin-left: 10px !important;
  }
  .entrave-component {
    width: 90%;
  }
  div.entraves-container div.entrave-element:only-child button.entrave-remove{
    display: none;
  }
  label.error {
    color: #ec250d !important;
  }
  .form-group.entrave-component.has-success {
    margin-bottom: 10px;
  }
  .form-group.entrave-component.has-danger {
    margin-bottom: 0px;
  }
</style>
