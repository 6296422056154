<template>
  <div class="card card-plain">
    <div role="tab" id="headingOne" class="card-header">
      <a data-toggle="collapse"
         data-parent="#accordion"
         :href="`#${itemId}`"
         @click.prevent="activate"
         class="collapsible"
         :aria-expanded="active"
         :aria-controls="`content-${itemId}`">
        <i class="tim-icons icon-minimal-down"></i>
        <slot name="title"> <span class="colapse-title">{{ title }} </span></slot>
      </a>
      <i class="tim-icons icon-simple-remove" @click="$emit('close', `#${itemId}`)" ></i>
    </div>
    <collapse-transition :duration="animationDuration">
      <div v-show="active" :id="`content-${itemId}`" role="tabpanel" :aria-labelledby="title" class="collapsed">
        <div class="card-body"><slot></slot></div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  name: 'collapse-item-project',
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: String
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title;
    }
  },
  data() {
    return {
      //active: false
      active: true
    };
  },
  methods: {
    activate() {
      let wasActive = this.active;
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !wasActive;
    }
  },
  mounted() {
    this.addItem(this);
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  }
};
</script>
<style>
  .colapse-title {
    float: left;
  }
</style>
