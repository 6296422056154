<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12 closing-phases">
        <h6 class="title phases-title">{{ $t('projectForm.phasesTitle') }}</h6>
        <div class="card card-timeline card-plain">
          <div class="card-body">
            <ul class="timeline">
              <phase-component v-for="(item, index) in phases" v-bind:key="item.phaseId" v-bind:item="item" v-bind:index="index" v-bind:layer="entraves.layer" v-bind:date="[sdate, edate]"
                               v-on:remove="removePhase($event)"
                               v-on:addClosing="addClosing($event)"
                               v-on:removeClosing="removeClosing($event)"
                               v-on:removeClosingDate="removeClosingDate($event)"
                               v-on:updateClosingDate="updateClosingDate($event)"
                               v-on:addClosingDate="addClosingDate($event)"
                               v-on:showModal="showModal($event)"
                               v-on:fillClosing="fillClosing($event)"
                               v-on:input="inputClosing($event)"
                               :statusOfUser="statusOfUser">
              </phase-component>
              <li class="timeline-inverted">
                <div class="timeline-badge danger phase-add-btn" v-if="!statusOfUser" v-on:click="addPhase">
                  <i class="tim-icons icon-simple-add"></i>
                </div>
              </li>
            </ul>
            <modal-map-closing-component v-if="!statusOfUser"
                                         v-bind:modalMap="modalMap"
                                         v-bind:entraves="entraves"
                                         v-bind:layer="layer"
                                         v-bind:selectedClosing="selectedClosing"
                                         v-bind:mapMode="mode"
                                         v-bind:perimeter="perimeter"
                                         v-bind:date="[sdate, edate]"
                                         v-bind:uuid="uuid"
                                         v-on:saveMapModal="saveMapModal($event)"
                                         v-on:closeMapModal="closeMapModal()">
            </modal-map-closing-component>
          </div>
        </div>
        <!--
        <time-line class="closing-container">
          <phase-component v-for="item in closing"  v-bind:key="item.name" v-bind:item="item" ></phase-component>
          
          <time-line-item
            inverted
            badge-type="danger timeline-phase-icon"
            badge-icon="tim-icons icon-simple-add">
          </time-line-item>
        </time-line>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from 'src/components';
import PhaseComponent from './PhaseComponent.vue';
import { uuid } from 'vue-uuid';
import ModalMapClosingComponent from './../map/ModalMapClosingComponent.vue';
import tags from './../shared/filters';

export default {
  components: {
    TimeLine,
    TimeLineItem,
    PhaseComponent,
    ModalMapClosingComponent
  },
  props: ['sdate', 'edate', 'entraves', 'phases', 'perimeter', 'mode', 'uuid','statusOfUser','layer'],
  data() {
    return {
      isActive: false,
      activeName: 'first',
      validateClosingStep: true,
      selectedPhase:'',
      selectedClosingIndex:'',
      selectedClosing: null,
      modalMap: false,
      nbClosingDate: 1
    };
  },
  created() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    if (user.nbClosingDate) {
      this.nbClosingDate = user.nbClosingDate;
    }
    let  instance = JSON.parse(window.localStorage.getItem('instance'));
    this.filters = tags[instance.city];
  },
  methods: {
    addPhase() {
      let trafficImpact = JSON.parse(JSON.stringify(this.filters.trafficImpact));
      let portableWater = JSON.parse(JSON.stringify(this.filters.portableWater));
      let dates=this.createDates();
      this.phases.push({
        phaseId: uuid.v1(),
        closing: [{
          closingId: uuid.v1(),
          selected:
            {
            path : [],
            startPoint : null,
            endPoint : null,
            streetsTrack : []
          },
          value: '',
          label : '',
          showError: false,
          type: 'linear',
          dates: dates,
          detours: [],
          workType1: '',
          trafficImpact: this.filters.trafficImpact?trafficImpact:{},
          portableWater: this.filters.portableWater?portableWater:{},
          publish: true
        }]
      });
    },
    removePhase(params) {
      let phaseId = params[1];
      let that = this;
      this.phases.forEach(function(e, i) {
        if (e.phaseId == phaseId) {
          that.phases.splice(i, 1);
        }
      });
      this.validateClosingStep=(this.phases.length===0);
    },
    addClosing(params) {
      let phaseId = params[1];
      let dates=this.createDates();
      let _this=this;
      let trafficImpact = JSON.parse(JSON.stringify(this.filters.trafficImpact));
      let portableWater = JSON.parse(JSON.stringify(this.filters.portableWater));
      this.phases.forEach(function(e) {
        if (e.phaseId == phaseId) {
          e.closing.push({
            closingId: uuid.v1(),
            selected:
              {
              path : [],
              startPoint : null,
              endPoint : null,
              streetsTrack : []
            },
            value: '',
            label : '',
            showError: false,
            type: 'linear',
            dates: dates,
            detours: [],
            workType1: '',
            trafficImpact: _this.filters.trafficImpact?trafficImpact:{},
            portableWater: _this.filters.portableWater?portableWater:{},
            publish: true
          });
        }
      });
    },
    removeClosing(params) {
      let phaseId = params[1];
      let closingId = params[2];
      let _this=this;
      this.phases.forEach(function(e) {
        if (e.phaseId == phaseId) {
          e.closing.forEach(function(close, i) {
            if (close.closingId == closingId) {
              e.closing.splice(i, 1);   
            }
          });
          if (e.closing.length==0) {
            _this.removePhase(['', phaseId]);
          }
        }
      });
    },
    addClosingDate(params)  {
      let phaseId = params[1];
      let closingId = params[2];
      var that = this;
      this.phases.forEach(function(e) {
        if (e.phaseId == phaseId) {
          e.closing.forEach(function(close) {
            if (close.closingId == closingId) {
              close.dates.push({
                dateId: uuid.v1(),
                sdate: that.sdate,
                edate: that.edate,
                days: [1,2,3,4,5,6,7],
                closingType: 'complete',
                closingSide: '',
                typeStreet: '',
                comment: ''
              });
            }
          });
        }
      });
    },
    removeClosingDate(params) {
      let dateId = params[1];
      let closingId = params[2];
      let phaseId  = params[3];
      this.phases.forEach(function(phase) {
        if(phase.phaseId == phaseId) {
          phase.closing.forEach(function(closing) {
            if (closing.closingId  == closingId) {
              closing.dates.forEach(function(date, i) {
                if (date.dateId == dateId) {
                  closing.dates.splice(i, 1);
                }
              });
            }
          });
        }
      });
    },
    updateClosingDate(params) {
      let dateId = params[0];
      let closingId = params[2];
      let phaseId  = params[3];
      this.phases.forEach(function(phase) {
        if(phase.phaseId == phaseId) {
          phase.closing.forEach(function(closing) {
            if (closing.closingId  == closingId) {
              closing.dates.forEach(function(date, i) {
                if (date.dateId == dateId) {
                  let item = params[1];
                  closing.dates.splice(i, 1, item);
                }
              });
            }
          });
        }
      });
    },
    createDates() {
      let dates=[];
      for (var i=0; i < this.nbClosingDate; i++) {
        dates.push({
          dateId: uuid.v1(),
          sdate: this.sdate,
          edate: this.edate,
          days: [1,2,3,4,5,6,7],
          closingType: 'complete',
          closingSide: '',
          typeStreet: '',
          comment: ''
        });
      }
      return dates;
    },
    showModal(params) {
      this.selectedClosingIndex = params[0].closingId;
      this.selectedClosing = params[0];
      this.selectedPhase = params[1];
      this.modalMap = true;
    },
    closeMapModal() {
      this.modalMap = false;
    },
    saveMapModal(selected) {
      this.fillClosing([this.selectedPhase, this.selectedClosingIndex, selected]);
      this.modalMap = false;
    },
    inputClosing(params) {
      let closingId = params[0];
      let phaseId = params[1];
      this.phases.forEach(function(phase){
        if(phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if (close.closingId==closingId) {
              close.showError=false;
            }
          });
        }
      });
    },
    fillClosing(params) {
      let phaseId = params[0];
      let closingId = params[1];
      let data = params[2];
      this.phases.forEach(function(phase){
        if(phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if (close.closingId==closingId) {
              close.showError=false;
              close.value=data.value;
              close.label=data.label;
              close.type=data.type;
              close.selected.path = data.selected.path;
              close.selected.startPoint = data.selected.startPoint;
              close.selected.endPoint = data.selected.endPoint;
              close.selected.streetsTrack = data.selected.streetsTrack;
            }
          });
        }
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.validateClosing();
         if (!this.validateClosingStep) {
          this.$notify({
            message: this.$i18n.t('projectForm.closingMandatory'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        } else {
          this.$emit('on-validated', res && (this.validateClosingStep), 'closing', this.phases);
          return res && (this.validateClosingStep);
        }
      });
    },
    validateClosing() {
      this.validateClosingStep=true;
      var that=this;
      this.phases.forEach(function(phase) {
        phase.closing.forEach(function(close) {
          if (close.value==='') {
            close.showError=true;
            that.validateClosingStep=false;
          } else {
            close.showError=false;
          }
        });
      });
    }
  }
};
</script>
<style>
  .white-content .card-timeline .timeline::before {
    background-color: #e3e3e3;
  }
  .card.card-timeline .timeline::before {
    background-color: #FFF !important;
  }
  body.white-content .card.card-timeline .timeline::before {
    background-color: #d3d7e9 !important;
  }
  .card.card-timeline .timeline > li > .timeline-badge {
    left: 5%;
  }
  .card.card-timeline .timeline::before {
    left: 5%;
  }
  .card.card-timeline .timeline > li > .timeline-panel {
    width: 90%;
  }
  .phases-title {
    margin-left: 3%;
    margin-bottom:  0px !important;
  }
  .card.card-timeline .timeline > li > .timeline-badge {
    /*width: 60px !important;
    height: 60px !important;*/
  }
  .card.card-timeline .timeline > li:last-child > .timeline-badge.timeline-phase-icon {
    cursor: pointer;
    /*
    line-height: 60px !important;
    font-size: 0.8em*/
  }
  .closing-phases div.card.card-timeline.card-plain div.card-body ul.timeline li:last-child div.timeline-panel {
    display: none;
  }
  .closing-date-remove-btn,
  .closing-remove-btn,
  .phase-remove-btn {
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 0.7em;
    text-align: center;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  .closing-remove-btn {
    position: relative;
    /*top: -47px;*/
    top: -22px;  
    right: -100px;
  }
  .close-title {
    float: left;
  }
  .phase-remove-btn {
    margin-left: -25px;
  }
  .closing-date-remove-btn {
    margin-top: 50%;
  }
  .closing-date-add-btn {
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 0.7em;
    text-align: center;
    margin-left: 48%;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  .closing-add-btn {
    color: #ffffff;
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 1em;
    text-align: center;
    position: absolute;
    top: 15px;
    right: 15px;
    margin-left: -25px;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  div.card-collapse.closing-content div.collapsed div.card-body {
    background: transparent !important;
  }
  div.closing-days-content span {
    margin-right: 5px !important;
    /*margin-top: 30px;*/
    cursor: pointer;
  }
  div.closing-days-content,
  div.closing-date-content {
    padding-left: 0px !important;
  }
  div.closing-days-content span.badge-disable {
    background-color: #8e8e8c;
    color: white;
  }
  body.white-content div.closing-days-content span.badge-disable {
    background-color: #8e8e8c;
    color: white;
  }
  .phase-add-btn {
    cursor: pointer;
  }
</style>
