<template>
    <collapse-item
      class="faq-collapse-item"
      :title=$t(label)
      :name="uniqueKey+1"
      :isClose=isClose
      @fetchURL="fetchURL"
    >
      <div class="col-8">
        <VideoComponent :options="playerOptions" />
      </div>
    </collapse-item>
</template>
<script>
import { Collapse, CollapseItem } from "src/components";
import VideoComponent from "./VideoComponent.vue";
import axios from 'axios';

export default {
  name: "faq-component",
  components: {
    Collapse,
    CollapseItem,
    VideoComponent,
  },
  props: ['url','label','isClose','uniqueKey'],
  data() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [],
      },
    };
  },
  watch:{
    url(newUrl){
      this.fetchVideoUrl(newUrl);
    },
  },
  
  methods: {
    async fetchVideoUrl() {
      try {
        // Make sure this.url is available
        if (this.url) {
          const response = await axios.post('/aws/presign', { "url": this.url });
          const videoUrl = response.data.presigned_url; // Assuming the API returns the presigned URL

          // Update playerOptions with the fetched video URL
          this.$set(this.playerOptions, 'sources', [
            {
              src: videoUrl,
              type: "video/mp4",
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching video URL:", error);
      }
    },
    fetchURL(){
      this.fetchVideoUrl();
    }
  },
};
</script>

<style>

.faq-collapse-item .card-header {
  background-color: #f5f6fa !important;
}
.faq-collapse-item i{
    color: black !important;
    font-weight:700 ;
}
</style>
