<template>
    <span class="not-very-good-exp-main py-2" :class="{ 'active': feedbackRating === 4 }">😕<span>{{ $t('feedbackForm.notVeryGoodExp') }}</span></span>
  </template>
  
  <script>
  
  export default {
    name: 'NotVeryGoodExperience',
    props: {
      feedbackRating: {
        required: true,
        type: Number
      }
    },
  }
  
  </script>
  
  <style> 
  .not-very-good-exp-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #9f9797;
    border-radius: 8px;
    width: 150px;
    font-size: 14px;
    color: #9f9797;
  }
  
  .not-very-good-exp-main.active {
    color: white !important;
    background-color: #DB6565;
    font-weight: 600;
    border: none;
  }  
  </style>