<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="login">
        <card class="card-login card-white">
          <template slot="header">
            <!--<img src="static/img/card-primary.png" alt="" />-->
            <img v-if="instanceCity === 'safelane'" class="opa-logo" src="static/img/new/safelane.png" alt="" />
            <img v-else class="opa-logo" src="static/img/new/roadworks.svg" alt="" />
            <h1 class="card-title">&nbsp;</h1>
          </template>

          <div>
            <base-input
              v-validate="'required'"
              name="login"
              :error="getError('login')"
              v-model="model.login"
              v-bind:placeholder= "$t('loginPage.placeHoldLogin')"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              v-validate="'required|min:8'"
              name="password"
              :error="getError('password')"
              v-model="model.password"
              type="password"
              v-bind:placeholder= "$t('loginPage.placeholderPass')"
              addon-left-icon="tim-icons icon-lock-circle"
            >
            </base-input>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block :loading="loading">
              {{ $t('loginPage.btnLogin') }}
            </base-button>

            <div class="forget-password" style="text-align: right;">
              <span @click="forgetPassword()">{{ $t('loginPage.forget') }}</span>
            </div>
            <div v-if="(!!features.ssoLoginEsri && features.ssoLoginEsri.show) || (!!features.ssoLoginMicrosoft && features.ssoLoginMicrosoft.show)" id="div-center">- or -</div>
            <base-button v-if="!!features.ssoLoginEsri && features.ssoLoginEsri.show" @click="authO" type="secondary" class="mb-3" size="lg" block>
              {{ $t('loginPage.btnLoginSSO') }}
            </base-button>
            <base-button
              v-if="!!features.ssoLoginMicrosoft && features.ssoLoginMicrosoft.show"
              @click="azureAuth"
              type="secondary"
              class="mb-3"
              size="lg"
              block
            >
              {{ $t("loginPage.btnLoginAzure") }}
            </base-button>

            <div class="footer-logo">
              <div id="div-center">-by</div>
              <img class="opa-footer-logo" src="static/img/new/OPA.svg" alt="" />
            </div>
          </div>

        </card>
      </form>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import features from './../app/shared/features';

export default {
  data() {
    return {
      model: {
        login: "",
        password: "",
        subscribe: true,
      },
      showAzureAuthBtn: false,
      loading: false,
      features: []
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    forgetPassword(){
      this.$router.push({path: '/forget'});
    },
    async login() {
      let isValidForm = await this.$validator.validateAll();
      let _this = this;
      if (isValidForm) {
        _this.loading = true;
        // TIP use this.model to send it to api and perform login call
        axios.post('login', {
          login: this.model.login,
          password: this.model.password
        }).then(response => {
          console.log("orignal",response);
          if (response.data.success) {
            let user = response.data.user;
            if (!user.lang) {
              user.lang='fr';
            }

              window.localStorage.setItem("access_token", user.uuid);
              window.localStorage.setItem("role", user.type);
              window.localStorage.setItem("user", JSON.stringify(user));
              window.localStorage.setItem(
                "instance",
                JSON.stringify(response.data.instance)
              );
              // if(user.type == 'adminGis'){
              //   window.localStorage.setItem('role', 'admin');
              // }
              this.$root.$i18n.locale = user.lang;
              if (response.data.instance.city == "us") {
                window.localStorage.setItem("state", "California");
              }
              this.$router.push({ path: "/" });
            } else {
              _this.loading = false;
              if (response.data.need_auth) {
                this.$router.push({ path: "/login" });
              } else {
                _this.$notify({
                  message: _this.$i18n.t("serverReply.errorLoginFail"),
                  timeout: 30000,
                  icon: "tim-icons icon-bell-55",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger",
                });
              }
            }
          })
          .catch((e) => {
            _this.loading = false;
            //window.localStorage.setItem('access_token', 'hello');
            //this.$router.push({path: '/'});
          });
      }
    },
    
    loginArcgis(){
        let _this = this;
        var url_str = window.location.hash;
        var lst = url_str.split("code=");
        if(lst.length == 2){
           axios.get('loginarcgis', {
             params: {
               code: lst[1]
             } 
           }).then(response => {
                if (response.data.success) {
                  let user = response.data.user;
                  if (!user.lang) {
                    user.lang='fr';
                  }
                  window.localStorage.setItem('access_token', user.uuid);
                  window.localStorage.setItem('role', user.type);
                  window.localStorage.setItem('user',JSON.stringify(user));
                  window.localStorage.setItem('instance',JSON.stringify(response.data.instance));
                  this.$root.$i18n.locale = user.lang;
                  this.$router.push({path: '/'});
                }else {
                  _this.$notify({
                    message: _this.$i18n.t('serverReply.errorLoginFail'),
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                  });
                  this.$router.push({path: '/login'});
                }
           })
        }
      },
    
    authO(){
      axios.get('25442A472D4B6150645367566B597033')
           .then(response => {
                if (response.data.success) {
                  var ClientId = response.data.client_id;
                  var redirect_uri = response.data.redirect_url;
                  window.open(`https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=${ClientId}&response_type=code&expiration=20160&redirect_uri=${redirect_uri}`, "_self");
                }else {
                  _this.$notify({
                    message: _this.$i18n.t('serverReply.errorLoginFail'),
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                  });
                  this.$router.push({path: '/login'});
                }
           })
    },
    azureAuth() {
      axios.get('6ecc3496a11e11de50d02c61d4c426f0')
           .then(response => {
                if (response.data.success) {
                  const CLIENT_ID = response.data.client_id;
                  const REDIRECT_URI = response.data.redirect_url;
                  const TENANT = response.data.tenant;
                  window.location = `https://login.microsoftonline.com/${TENANT}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=token+id_token&redirect_uri=${REDIRECT_URI}&scope=user.read+openid+profile+email&response_mode=fragment&state=12345&nonce=678910`
                }else {
                  _this.$notify({
                    message: _this.$i18n.t('serverReply.errorLoginFail'),
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                  });
                  this.$router.push({path: '/login'});
                }
           })
    },
    getSubDomain() {
      var full = window.location.host;
      var parts = full.split(".");
      var sub = parts[0];
      this.instanceCity = sub;
      if (sub === "boisbriand_d" || sub === "boisbriand") {
        this.showAzureAuthBtn = true;
      }
    },
  },
  created() {
    this.loginArcgis();
    this.getSubDomain();
    const currentUrl = window.location.hostname;
    const city = currentUrl.split(/[_.]/)[0];
    this.features = features[city];
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
div.full-page.login-page div.card-header img {
  /* left: 50%; */
  /* margin-left: -125px; */
  /* margin-top: 20px; */
}
div.forget-password {
  text-align: right;
  margin-top: -10px;
  margin-bottom: 10px;
}
div.forget-password span {
  cursor: pointer;
  color: #d650d6;
}
.opa-logo{
  position: relative;
  padding: 2rem;
}
#div-center{
  text-align: center;
  margin:5px;
}
.login-page .card-login .card-header{
  padding: 0;
}
.footer-logo{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.opa-footer-logo{
  width:45%;
  float: right;
  padding:1.5rem;
}
</style>