<template>
  <div class="content">
     <div v-if="projectId == null" class="mt-3 text-right">
      <base-button class="" @click="$router.push('/archives/list')" type="primary">
        {{ $t('archiveForm.btnArchList') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <card style="min-height: 500px;">
          <template slot="header">
            <h3 class="card-title"></h3>
          </template>
          <div class="loading" v-if="loading">
            <img src="../../../public/img/loading.gif"/>
          </div>
          <form v-else class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label"> {{ $t('archiveForm.labelArchName') }}</label>
              <div class="col-sm-10">
                <base-input
                  name="nom"
                  v-bind:placeholder="$t('archiveForm.placeHoldName')"
                  required
                  v-validate="modelValidations.required"
                  :error="getError('nom')"
                  v-model="archive.name">
                </base-input>
              </div>
            </div>
            <div class="row margin-top-20">
              <label class="col-sm-2 col-form-label">{{ $t('archiveForm.labelArchDesc') }}</label>
              <div class="col-sm-10">
                <vue-pell-editor 
                  v-model="archive.description"
                  :actions="editorOptions"
                  :placeholder="editorPlaceholder"
                  :style-with-css="false"
                  :classes="editorClasses"
                  default-paragraph-separator="p"/>
              </div>
            </div>
            <div class="row margin-top-20">
              <label class="col-sm-2 col-form-label">{{ $t('archiveForm.labelArchProj') }}</label>
              <div class="col-sm-10">
                <el-select
                  class="test-select select-primary select-inline"
                  name="project"
                  size="large"
                  @change="onChangePublish"
                  v-model="archive.project">
                  <el-option
                    v-for="option in options"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row  margin-top-20">
              <label class="col-sm-2 col-form-label">{{ $t('archiveForm.labelArchRet') }}</label>
              <div class="col-sm-2">
                <base-input
                  name="retention"
                   @keypress="isNumber($event)"
                  v-model="archive.retention.value">
                </base-input>
              </div>
              <div  class="col-sm-2">
                <el-select
                  class="test-select select-primary select-inline"
                  name="retentionType"
                  size="large"
                  v-model="archive.retention.type">
                    <el-option
                    v-for="option in [{value: 'day', label: $t('select.day')}, {value: 'month', label: $t('select.month')}, {value: 'year', label: $t('select.year')}]"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                  <!--<el-option
                    v-for="option in [{value: 'minutes', label: $t('select.min')}, {value: 'day', label: $t('select.day')}, {value: 'month', label: $t('select.month')}, {value: 'year', label: $t('select.year')}]"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>-->
                </el-select>
              </div>
            </div>
            <div class="row margin-top-20">
              <label class="col-sm-2 col-form-label">{{ $t('archiveForm.labelArchTags') }}</label>
              <div class="col-sm-10">
                <tags-input  v-model="archive.tags"></tags-input>
              </div>
            </div>
            <div class="row row margin-top-20">
              <label class="col-sm-2 col-form-label" >{{ $t('archiveForm.labelArchiveChecked') }}</label>
              <div class="col-sm-10 archive-published">
                <base-checkbox v-model="archive.published" :checked="archive.published" ></base-checkbox>
              </div>
            </div>
            <!--
            <div class="row row margin-top-20">
              <label class="col-sm-2 col-form-label" >{{ $t('archiveForm.labelArchTypeD') }}</label>
              <div class="col-sm-10 checkbox-radios">
                <base-radio name="pdf" v-model="archive.type">PDF</base-radio>
                <base-radio name="cad"  v-model="archive.type">AUTOCAD</base-radio>
              </div>
            </div>
            -->
            <div class="row margin-top-20">
              <label class="col-sm-2 col-form-label">{{ $t('archiveForm.labelArchUpld') }}</label>
              <div class="col-sm-10">
                <div v-if="selectedFile">
                  <span class="file-selected"> {{archive.files}}</span>
                  <base-button @click="deleteSelectedFile" round type="danger" style="margin-left: 50px;">
                    <i class="fas fa-times"></i> {{ $t('archiveForm.btnDelete') }}
                  </base-button>
                </div>
                <image-upload v-else @change="onImageChange" class="hello" select-text="Select Image" />
              </div>
              <label class="col-sm-2 col-md-2 col-lg-2"></label>
              <label v-show="error.file" class="col-sm-10 col-md-10 col-lg-10 help-block text-danger"> {{ $t('archiveForm.fileRequired') }}</label>
            </div>
          </form>
          <template slot="footer" class="text-right">
            <base-button
              native-type="submit"
              @click.prevent="validate"
              class="pull-right"
              type="primary">{{ $t('archiveForm.btnArchSave') }}</base-button>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BaseCheckbox, BaseRadio, ImageUpload, TagsInput } from 'src/components/index';
import { Select, Option } from 'element-ui';

import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);

export default {
  name: 'ArchiveForm',
  props: ['projectId','modalStatus', 'archiveData', 'archives'],
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
    ImageUpload,
    TagsInput
  },
  created () {
    let _this = this;
    if(_this.projectId != null){
      _this.mode='create'
    }
    if (_this.$route.query && _this.$route.query.uuid && _this.projectId == null) {
      _this.mode='edit';
      _this.selectedFile=true;
      _this.archive.uuid=this.$route.query.uuid;
    }
    _this.getProjectNames();
  },
  watch: {
    archiveData(newValue, oldValue) {
      if(!newValue) return
      const { uuid, name, description, type, published, tags, file, retention, project } = newValue;
      this.archive = {
        ...newValue,
        uuid,
        name,
        description,
        type,
        published,
        tags: tags && tags.split(','),
        files: file,
        retention: JSON.parse(retention),
        project
      }
      delete this.archive.file
      this.mode='edit';
      this.selectedFile=true;
    },
    // call the method again if the route changes
    '$route': 'resetArchive'
  },
  data() {
    return {
      archive: {
        uuid: null,
        name: '',
        description:'',
        type: 'pdf',
        published: false,
        tags: [],
        file: null,
        retention: {
          value: 12,
          type: 'month'
        },
        project: this.projectId || null
      },
      error: {
        file: false
      },
      mode: 'create',
      selectedFile: false,
      loading: false,
      options: [{'value': '0', 'label': '---'}],
      editorOptions: [
        'bold',
        'underline', {
            name: 'italic',
            result: () => window.pell.exec('italic')
        }, 
        'heading1',
        'heading2',
        'paragraph',
        'strikethrough',
        'olist',
        'ulist',
        'code',
        'line'
      ],
      editorPlaceholder: this.$i18n.t('archiveForm.placeHArchDesc'),
      editorClasses: {
          actionbar: 'pell-actionbar custom-actionbar',
          button: 'pell-button',
          content: 'pell-content',
          selected: 'pell-button-selected'
      },
      modelValidations: {
        required: {
          required: true
        }
      }
    };
  },
  methods: {
    resetValue(){
        this.archive = {
          uuid: null,
          name: '',
          description:'',
          type: 'pdf',
          published: false,
          tags: [],
          file: null,
          retention: {
            value: 12,
            type: 'month'
          },
          project: this.projectId || null
        },
        
        this.mode = 'create',
        this.selectedFile =  false,
        this.loading = false,
        
        
        this.editorPlaceholder = this.$i18n.t('archiveForm.placeHArchDesc'),
       
        this.modelValidations = {
          required: {
            required: true
          }
        }
        this.getProjectNames();
    },
    deleteSelectedFile() {
      this.selectedFile=false;
      this.archive.file=null;
    },
    onImageChange(file) {
      this.archive.file = file;
      this.error.file=false;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      let _this=this;
      _this.error.file=false;
      if ((_this.archive.file==null) && (_this.mode=='create')) {
        _this.error.file=true;
      } else {
        _this.error.file=false;
      }
      this.$validator.validateAll().then(isValid => {
        if (isValid && _this.archive.file && (_this.mode=='create')) {
          _this.create(); 
        } else if (isValid &&  (_this.mode=='edit')) {
          _this.create();
        }
      });
    },
    create() {
      //window.location.href='/#/archives/list';
      this.loading=true;
      let url = '/archive/create';
      if (this.mode=='edit') {
        url = '/archive/edit';
      }
      let fd = new FormData();
      fd.append('name', this.archive.name);
      fd.append('description', this.archive.description);
      fd.append('project', this.archive.project);
      fd.append('type', this.archive.type);
      fd.append('tags', this.archive.tags);
      fd.append('published', this.archive.published);
      fd.append('retention', JSON.stringify(this.archive.retention));
      if (this.archive.file) {
        fd.append('file', this.archive.file);
      }
      if (this.archive.uuid) {
        fd.append('uuid', this.archive.uuid); 
      }

      let _this=this;
      axios({
        method: 'post',
        url: url,
        data: fd,
        headers: this.getHeaders()
      }).then( (response) => {
        _this.loading=false;
        if (response.data.success) {
          if(_this.projectId != null){
            _this.$emit("update", false);
            _this.resetValue();
          }else{
            window.location.href = _this.$route.query.returnedUrl; 
          }
        }
      })
      .catch( e =>  {
        console.log("error:::", e);
      });
    },
    getHeaders() {
      let size=0;
      if (this.archive.file && this.archive.file.size) {
        size = this.archive.file.size;
      }
      return {
        'Filesize': size
      };
    },
    getArchive() {
      let _this=this;
      axios.get('archive/edit?uuid='+_this.archive.uuid).then(response => {
        let data = response.data;
        if (data.success) {
          _this.archive = data.data;
          _this.archive.retention = JSON.parse(_this.archive.retention);
          _this.archive.tags = (_this.archive.tags) && (_this.archive.tags.split(','));
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorArchiveRead'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {});
    },
    getProjectNames() {
      let _this=this;
      axios.post('project/get_names', {}).then(response => {
        let data = response.data;
        if (data.success) {
          data.data.forEach((e) => {
          //  _this.options.push({'value': e.uuid, 'label': e.name});
          _this.options.push({'value': e.uuid, 'label': e.name.length > 150 ? e.name.substring(0,146) + "..." : e.name});
          });
          if (_this.archive.uuid) {
            _this.getArchive();
          }
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorArchiveRead'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {});
    }
  }
};
</script>
<style lang="scss">
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .margin-top-40 {
    margin-top: 40px;
  }
  .map-container {
    height: 350px !important;
  }
  
  #mapview {
    padding: 0;
    margin: 0;
    height: 350px !important;
    width: 100%;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .custom-actionbar {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(43, 53, 83);
  }
  
  .custom-actionbar button.pell-button {
    color: #d3d7e9 !important;
  }
  .vp-editor {
    border: 1px solid rgb(43, 53, 83);
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    height: 150px;
  }
  body.white-content .test-select.select-primary.el-select .el-input input,
  body.white-content .vp-editor{
    border-color: #8d919c !important;
  }
  .pell-content {
    height: 150px;
  }
  .vp-editor:focus {
    border: 1px solid #cd51e0 !important;
  }
  .pell-button {
    width: 40px;
  }
  .input-new-tag {
    margin-left: 0px;
    width: 200px !important;
    height: 32px;
    display: inline;
  }
  span.el-tag.el-tag--primary.el-tag--small.el-tag--light {
    margin-right: 10px;
  }
  label.text-danger {
    margin-left: -20px;
    margin-top: -40px;
    color:  #e5262b !important;
  }
  body:not(.white-content) span.file-selected {
    color: hsla(0,0%,100%,.8);
    font-weight: bold;
  }
  body.white-content span.file-selected {
    color: #344675;
    font-weight: bold;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.archive-published {
    margin-top: -15px;
  }
</style>