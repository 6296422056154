<template>
    <span class="bad-exp-main py-2" :class="{ 'active': feedbackRating === 5 }">😞<span>{{ $t('feedbackForm.badExp') }}</span></span>
</template>

<script>

export default {
    name: 'BadExperience',
    props: {
        feedbackRating: {
            required: true,
            type: Number
        }
    },
}

</script>
  
<style>
.bad-exp-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #9f9797;
    border-radius: 8px;
    width: 150px;
    font-size: 14px;
    color: #9f9797;
}

.bad-exp-main.active {
    color: white !important;
    background-color: #BE4545;
    font-weight: 600;
    border: none;
}
</style>