<template>
  <div>
    <modal :show.sync="publicPage" ref="publicPageModalRef" headerClasses="justify-content-center" class="modal-public-page" type="custom" :scrollToBottom="false" v-on:hideModal="hideModal">
      <h4 slot="header" class="title title-up">{{ $t('settings.titlePPage') }}</h4>
      <template slot="close-button">
        <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="row public-page-container">
        <div class="row row-pbttom" >
          <label class="col-sm-12 col-md-12 col-lg-12 ppage-label" >{{ $t('settings.rangePPage') }}</label>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <base-input name="date">
              <el-date-picker
                type="datetimerange"
                value-format="timestamp"
                @change="onChangeDate"
                range-separator="-"
                format="yyyy-MM-dd HH:mm"
                lang="fr"
                v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
                v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
                v-model="urlParams.date">
              </el-date-picker>
            </base-input>
          </div>
        </div>
        <div class="row row-pbttom">
          <label class="col-sm-12 col-md-12 col-lg-12 ppage-label" >{{ $t('settings.typeMapPPage') }}</label>
          <div class="row col-sm-12 col-md-12 col-lg-12" style="height: 300px; overflow-y: auto; border: 1px solid #eee; border-radius: 5px;margin-left:15px;margin-right:15px;padding-right:15px;">
            <div class="col-sm-3 col-md-3 col-lg-3 map-image-content" v-bind:key="item.key" v-for="item in maps" :class="[ { selected: item.active }]">
              <img class='selected' :src="item.fname" @click="changeMap(item);">
              <label>{{item.label}}</label>
            </div>
          </div>
        </div>
        <div class="row theme-row">
          <label class="col-sm-12 col-md-12 col-lg-12 ppage-label">{{ $t('settings.title') }}</label>
          <div style="display:contents" class="col-sm-12 col-md-12 col-lg-12">
            <div style="display:grid;" class="col-sm-4 col-md-4 col-lg-4">
              <span class="label-switch">{{ $t('settings.entraves') }} </span>
              <base-switch v-model="display.entraves" @input="changeDisplay"></base-switch>
            </div>
            <div style="display:grid;" class="col-sm-4 col-md-4 col-lg-4">
              <span class="label-switch">{{ $t('settings.closing') }} </span>
              <base-switch v-model="display.closing" @input="changeDisplay"></base-switch>
            </div>
            <div style="display:grid;" class="col-sm-4 col-md-4 col-lg-4">
              <span class="label-switch">{{ $t('settings.detour') }} </span>
              <base-switch v-model="display.detours" @input="changeDisplay"></base-switch>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div role="alert" class="alert alert-info alert-with-icon">
          <span data-notify="message">
            <a :href="url" target="_blank" class="url-link">{{ $t('settings.clickPPage') }}</a>
            <input id="public-link" type="text" :value="url" class="inputHidden" />
            <!--<i class="tim-icons icon-single-copy-04 icon-copy" @click="copyLink();"></i>-->
          </span>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  
import { Modal } from 'src/components';
import { TabPane, Tabs, BaseSwitch } from 'src/components';
import { Select, Option, TimeSelect, DatePicker } from 'element-ui';

export default {
  name:'public-page',
  props: ['publicPage'],
  components: {
    Modal,
    TabPane,
    Tabs,
    BaseSwitch,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },
  watch: {
    'publicPage'(){
      if (this.publicPage) {
        this.generateUrl();
      }
    }
  },
  data() {
    return {
      instance:null,
      typeMap: 'streets',
      modeMap: "0",
      display: {
        'entraves': true,
        'closing': true,
        'detours': true
      },
      maps: [
        { active: true,  modeMap: '0', key:'osm', label: 'OpenStreetMap', fname: 'static/img/map/osm.jpg' },
        { active: false, modeMap: '0', key:'streets', label: 'Streets', fname: 'static/img/map/streets.jpg' },
        { active: false, modeMap: '0', key:'topo', label: 'Topographic', fname: 'static/img/map/topo.jpg' },
        { active: false, modeMap: '0', key:'dark-gray', label: 'Dark Gray Canvas', fname: 'static/img/map/dark_gray_canvas.png' },
        { active: false, modeMap: '0', key:'gray', label: 'Light Gray Canvas', fname: 'static/img/map/light_gray_canvas.jpg' },
        { active: false, modeMap: '0', key:'satellite', label: 'Imagery', fname: 'static/img/map/imagery.jpg' },
        { active: false, modeMap: '0', key:'national-geographic', label: 'National Geographic', fname: 'static/img/map/nat_geo.jpg' },
        { active: false, modeMap: '0', key:'terrain', label: 'Terrain with Labels', fname: 'static/img/map/terrain_labels.jpg' },
        { active: false, modeMap: '1', key:'098132d21c014e62908d847e3b74d740', label: 'Dark Grey-Community Map of Canada', fname: 'static/img/map/dark_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'e1de1cfd7a624639b65e2cc0f10aad70', label: 'Light Grey-Community Map of Canada', fname: 'static/img/map/light_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'41fff870ffc2461ab79cd949da2ac1bc', label: 'Streets (Night)-Community Map of Canada', fname: 'static/img/map/streets_night_canada.png' },
        { active: false, modeMap: '1', key:'cba1e859423a424eb26fb73c5df5ecdf', label: 'Streets-Community Map of Canada', fname: 'static/img/map/streets_canada.png' },
        { active: false, modeMap: '1', key:'98652eb8458a464fa95feb9bd812b29a', label: 'Topographic-Community Map of Canada', fname: 'static/img/map/topographic_canada.jpeg' }
      ],
      urlParams: {
        date: [new Date().getTime() - (6*30*24*60*60*1000), new Date().getTime() + (6*30*24*60*60*1000)],
        published: true,
        typeMap: 'osm',
        modeMap: '0'
      },
      url: ''
    };
  },
  mounted() {
    this.instance=JSON.parse(window.localStorage.getItem('instance'));
    if( this.instance.city === 'sjsr'){
      this.maps.push({ active: false, modeMap: '2', key:'92c9dbcdd3ba4b95a07c2116c7b1cb09', label: 'SJSR Custom basemap', fname: 'static/img/map/sjsr.png' });
    }
  },
  methods: {
    hideModal() {
      this.maps.forEach(listItem => {
        listItem.active = false;
      });
      this.maps[0].active=true;
      this.urlParams = {
        date: [new Date().getTime() - (6*30*24*60*60*1000), new Date().getTime() + (6*30*24*60*60*1000)],
        published: true,
        typeMap: 'osm',
        modeMap: '0'
      };
      this.$emit('hidePublicPage');
    },
    changeMap(item) {
      this.maps.forEach(listItem => {
        listItem.active = false;
      });
      item.active=true;
      this.urlParams.typeMap=item.key;
      this.urlParams.modeMap=item.modeMap;
      this.generateUrl();
    },
    changeDisplay() {
      this.generateUrl();
    },
    generateUrl() {
      let user = JSON.parse(window.localStorage.getItem('user'));
      let lang = user.lang || 'fr';
      let url = 'https://' + window.location.host + '/#/public?city=' + this.instance.city + 
                '&long=' + this.instance.center.long + 
                '&lat=' + this.instance.center.lat + 
                '&type=' + this.urlParams.typeMap +
                '&modeMap=' + this.urlParams.modeMap +
                '&entraves=' + this.display.entraves +
                '&closing=' + this.display.closing +
                '&detours=' + this.display.detours +
                '&lang=' + lang + 
                '&sdate=' + this.urlParams.date[0]+ 
                '&edate=' + this.urlParams.date[1];
      this.url = url;
    },
    onChangeDate() {
      this.generateUrl();
    },
    copyLink() {
      let copyText = document.querySelector("#public-link");
      copyText.select();
      //copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
    }
  }
}
</script>
<style>
  div.public-page-container {
    margin: 30px;
  }
  div.public-page-container label.ppage-label {
    font-weight: bold;
  }
  div.public-page-container .tab-content .tab-pane {
    color: rgb(82, 95, 127) !important;
  }
  div.public-page-container span.badge {
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
  }
  div.public-page-container  span.badge-vue {
    background-color: #42b883 !important;
  }
  div.public-page-container span.badge.active, div.public-page-container.span.badge:hover {
    border-color: #1d253b;
  }
  div.modal-custom div.modal-dialog {
    width: 45% !important;
  }
  div.theme-row {
    margin: 0px;
    margin-bottom: 15px;
  }
  div.row.theme-row label {
    padding-left: 5px;
  }
  
  div.colors-div {
    padding-top: 5px;
    margin-left: -45px;
  }
  div.flex-div {
    display: flex;
  }
  div.map-image-container {
    margin: 0;
  }
  
  div.map-image-content {
    padding: 10px;
    display: grid;
  }
  div.map-image-content label {
    font-weight: 600;
    text-align: center;
  }
  
  div.map-image-content img {
    cursor: pointer;
  }
  div.map-image-content img {
    border: 1px solid #000;
  }
  div.map-image-content.selected img {
    border-color: #fff;
    box-shadow: 3px 3px 10px #000;
  }
  div.row.public-page-container span.el-range-separator,
  div.row.public-page-container input.el-range-input {
    color: #222a42 !important;
  }
  div.modal-public-page div.modal-footer {
    display: block;
    text-align: center;
  }
  div.modal-public-page a.url-link {
    font-weight: bold;
    color: white !important;
  }
  div.row-pbttom {
    margin-bottom: 30px;
  }
  div.row-marginauto {
    margin: auto;
  }
  input.inputHidden {
    border: none;
    width: 0px;
    background-color: #419ef9;
  }
  i.icon-copy {
    margin-left: 20px;
    cursor: pointer;
  }
</style>