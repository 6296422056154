<template>
  <div class="content">
    <div class="mt-3 text-right">
      <base-button class=""  @click="$router.push('/layer/create')" type="primary">
      {{ $t('layerForm.label_layerCreate') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <card class="archives-card" card-body-classes="table-full-width">
          <!--<h4 slot="header" class="card-title"></h4>-->

          <div class="loading" v-if="loading">
            <img src="../../../public/img/loading.gif"/>
          </div>
          <div v-else>
            <div v-show="tableData.length==0">
              <base-alert type="info"> {{ $t('layerForm.errorMessage') }}</base-alert>
            </div>
            <div v-show="tableData.length > 0" style="overflow-x: auto;">
              <base-table :data="tableData" thead-classes="text-primary project-table-head" tbody-classes="archive-table-body">
                <template slot="columns" slot-scope="{ columns }">
                  <!--<th width="80" class="text-right"></th>-->
                  <th>{{ $t('layerForm.layerName') }}</th>
                  <th>{{ $t('layerForm.url') }}</th>
                  <th>{{ $t('layerForm.labelDate') }}</th>
                  <th width="0" class="text-right"></th>
                </template>
            
                <template slot-scope="{ row, index }" >
                  <!--<td>
                    <img style="width: 24px;" v-if="row.type=='cad'" src="../../../public/img/cad3.png" />
                    <img style="width: 24px;" v-else src="../../../public/img/pdf3.png" />
                  </td>-->
                  <td ><p class="title">{{ row.label_en }}</p></td>
                  <td class="url-label" v-html="row.url"></td>
                  <td class="pad-left-20">{{ row.cdate | formatDate}}</td>
                  <td class="text-right btn-container">
                    <div>
                      <el-tooltip
                        v-bind:content= "$t('archivePage.tipEdit')"
                        effect="light"
                        :open-delay="300"
                        placement="top">
                        <base-button
                          :type="'success'"
                          icon
                          size="sm"
                          @click.native="updateLayer(row.uuid)"
                          class="btn-link">
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip
                        v-bind:content= "$t('archivePage.tipDelete')"
                        effect="light"
                        :open-delay="300"
                        placement="top">
                        <base-button
                          :type="'danger'"
                          icon
                          size="sm"
                          @click.native="deleteLayer(row.uuid)"
                          class="btn-link">
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div v-show="tableData.length > 0" class="col-sm-2 list-project-action">
            </div>
            <div v-show="tableData.length > 0"  class="paginate-container">
              <p class="card-category"> {{ $t('archivePage.pageResultsFrom') }} {{ from + 1 }} {{ $t('archivePage.pageResultsTo') }} {{ to }} {{ $t('archivePage.pageResultsOn') }} {{ total }}</p>
              <base-pagination
                v-show="tableData.length > 0"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { BaseTable } from '@/components';
import { BasePagination, BaseAlert } from 'src/components';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert2';

import {Slider, BaseProgress} from 'src/components/index';

export default {
  components: {
    BaseTable,
    BasePagination,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseProgress
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    }
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.total;
    }
  },
  created () {
    this.getLayers();
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      loading:false,
      tableData: [],
      total: 0,
      selectedProject: '0',
     
      date: null,
    };
  },
  methods: {
    onChangeDate() {
       this.getLayers();
    },
 
    getLayers() {
      let _this = this;
      this.loading=true;
      let filters = {};
      filters.from = (this.pagination.perPage * (this.pagination.currentPage - 1)) + 1;
      filters.limit = this.pagination.perPage;
      filters.ranges = this.date || [];
      axios.get('layer/list').then(response => {
        let data = response.data;
        if (data.success) {
          _this.loading=false;
          let dt;
          _this.tableData = data.data.map((element) => {
            
            return element;
          });
        
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message:  this.$i18n.t('serverReply.errorProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: this.$i18n.t('serverReply.errorProject'),
          timeout:   10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    },
    updateLayer(uuid) {
      this.$router.push({path: '/layer/edit?uuid=' + uuid});
    },
    deleteLayer(uuid) {
      let _this = this;
      swal({
        title: this.$i18n.t('archivePage.titleArchiveDelete'),
        text:  this.$i18n.t('archivePage.textArchiveDelete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$i18n.t('archivePage.textConfirmArchiveDelete'),
        cancelButtonText: this.$i18n.t('archivePage.textCancelArchiveDelete'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          axios.post('layer/delete', {uuid: uuid}).then(response => {
            let _this = this;
            let data = response.data;
            if (data.success) {
              swal({
                title: this.$i18n.t('archivePage.titleDeletedArchive'),
                text:  this.$i18n.t('archivePage.textDeletedArchive'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              }).then(() => {
                _this.getLayers();
              });
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorDeleteArchive'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorDeleteArchive'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      });
    },

  },
  mounted() {},
  watch: {
    'pagination.currentPage'() {
      this.getLayers();
    }
  }
};
</script>
<style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  table.el-table__header {
    margin-bottom: 0px;
  }
  .bootstrap-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
    vertical-align: middle;
    cursor: text;
  }
  .bootstrap-tagsinput .tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
        padding-left: 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: .75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
    padding-left: .8em;
    color: #fff;
  }
  div.card.archives-card div.card-body {
    min-height: 500px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }
  div.card.archives-card .pad-left-20 {
    padding-left: 20px
  }
  .pell-button {
    width: 35px !important;
  }
  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
  /* td.url-label{
    display: block;
    width: 70vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } */
</style>
