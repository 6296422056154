<template>
  <div class="fileinput text-center">
    <div class="select-image-btn">
      <span class="btn btn-primary btn-simple btn-file">
        <span v-if="fileExists" class="fileinput-new">{{ $t('archiveForm.btnChange') }}</span>
        <span v-else class="fileinput-new">{{ $t('archiveForm.btnFileSelect') }}</span>
        <input type="hidden" value="" name="" />
        <input
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"/>
      </span>
      <base-button v-if="fileExists" @click="removeFile" round type="danger">
        <i class="fas fa-times"></i> {{ $t('archiveForm.btnDelete') }}
      </base-button>
    </div>
    <div v-if="isImage"
      class="fileinput-new thumbnail"
      :class="{ 'img-circle': type === 'avatar' }">
      <!--<img :src="image" alt="preview" />-->
      <img :src="image" alt="" />
    </div>
    <div v-else > {{ fileName }}  </div>
  </div>
</template>
<script>
export default {
  name: 'image-upload',
  props: {
    type: {
      type: String,
      default: '',
      description: 'Image upload type (""|avatar)'
    },
    src: {
      type: String,
      default: '',
      description: 'Initial image to display'
    },
    selectText: {
      type: String,
      default: 'Choisir un fichier'
    },
    changeText: {
      type: String,
      default: 'Changer'
    },
    removeText: {
      type: String,
      default: 'Supprimer'
    }
  },
  data() {
    /*let avatarPlaceholder = 'img/placeholder.jpg';
    let imgPlaceholder = 'img/placeholder.jpg';*/
    let avatarPlaceholder = '';
    let imgPlaceholder = '';
    return {
      isImage:true,
      placeholder: this.type === 'avatar' ? avatarPlaceholder : imgPlaceholder,
      imagePreview: null,
      name: null
    };
  },
  computed: {
    fileExists() {
      return this.imagePreview !== null;
    },
    image() {
      return this.imagePreview || this.src || this.placeholder;
    },
    fileName() {
      return this.name
    }
  },
  methods: {
    handlePreview(event) {
      let file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(file);
      this.name=file.name;
      if (file.type.indexOf('image')===0) {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
      this.$emit('change', file);
    },
    removeFile() {
      this.imagePreview = null;
      this.name = null;
      this.$emit('change', null);
    }
  }
};
</script>
<style>
  div.select-image-btn {
  }
</style>
