<template>
    <span class="good-exp-main py-2" :class="{ 'active': feedbackRating === 3 }">😊<span>{{ $t('feedbackForm.goodExp') }}</span></span>
</template>
  
<script>

export default {
    name: 'GoodExperience',
    props: {
        feedbackRating: {
            required: true,
            type: Number
        }
    },
}

</script>
  
<style>
.good-exp-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #9f9797;
    border-radius: 8px;
    width: 150px;
    font-size: 14px;
    color: #9f9797;
}

.good-exp-main.active {
    color: white !important;
    background-color: #E27841;
    font-weight: 600;
    border: none;
}
</style>