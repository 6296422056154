<template>
    <div class="row mt-3">
        <h2 class="ml-3">{{ $t('projectList.ProjectsConflict') }}</h2>
        <div class="col-12">
            <card class="users-card" card-body-classes="table-full-width">
                <projects-conflicts v-bind:showConflicts="showConflicts" :isPage="true" />
                </projects-conflicts>
            </card>
        </div>
    </div>
</template>

<script>
import ProjectsConflicts from './ProjectsConflicts.vue';
export default {
    name: 'ProjectConflictsPage',
    components: {
        ProjectsConflicts
    },
    data() {
        return {
            showConflicts: false
        };
    },
    created() {
        this.showConflicts = true;
    }
}
</script>