<template>
    <svg v-if="direction" :width="size" :height="size" viewBox="0 0 24 24">
      <path v-if="direction === '1'" d="M16.59 15.41L12 10.83 7.41 15.41 6 14l6-6 6 6-1.41 1.41z"/>
      <path v-else-if="direction === '-1'" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  </template>
<script>
    export default {
        name: 'ArrowIcon',
        props: {
            direction: {
            type: String,
            required: true,
            validator: (value) => ['1', '-1'].includes(value),
            },
        },
        computed: {
            size() {
            return this.$attrs.size || 12;
            },
        },
        watch: {
            direction(newValue) {
            console.log(`New direction value: ${newValue}`);
            },
        },
    };
</script>