<template>
  <div class="content feedback-main">
    <div class="row mt-3">
      <div class="col-md-12 feedback-form">
        <card style="min-height: 500px;">
          <div class="d-flex flex-column feedback-wrapper" :class="darkMode && 'color-white'">
            <span class="primary-text mt-2">{{ $t('feedbackForm.secondaryHeading1') }}</span>
          </div>
          <template slot="header">
            <h3 class="card-title"></h3>
          </template>
          <form class="form-horizontal p-4">
            <div class="row">
              <label class="col-sm-2 col-form-label"> {{ $t('feedbackForm.labelFeedbackName') }}</label>
              <div class="col-sm-10">
                <base-input name="name" v-bind:placeholder="$t('feedbackForm.placeHoldName')" required
                  v-model="feedback.name" @keypress="filterSpecialCharacters" :class="darkMode && 'feedback-text-light'">
                </base-input>
                <label v-if="error.name" class="col-sm-10 col-md-10 col-lg-10 help-block text-danger-feedback"> {{
                  $t('feedbackForm.nameRequired') }}</label>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label"> {{ $t('feedbackForm.labelEmailAddressDesc') }}</label>
              <div class="col-sm-10">
                <base-input name="email" v-bind:placeholder="$t('feedbackForm.placeHoldName')" required
                  v-model="feedback.email" :class="darkMode && 'feedback-text-light'">
                </base-input>
                <label v-if="error.email" class="col-sm-10 col-md-10 col-lg-10 help-block text-danger-feedback"> {{
                  $t('feedbackForm.emailRequired') }}</label>
                <label v-if="error.invalidEmail" class="col-sm-10 col-md-10 col-lg-10 help-block text-danger-feedback">
                  {{
                    $t('feedbackForm.invalidEmail') }}</label>
              </div>
            </div>
            <div class="row align-items-center margin-bottom-10">
              <label class="col-sm-2 col-form-label">{{ $t('feedbackForm.labelTypeOfReport') }}</label>
              <div class="col-sm-10 d-flex">
                <base-radio name="2" v-model="feedback.feedback_type">{{ $t('feedbackForm.labelType2')
                }}</base-radio>
                <base-radio name="1" v-model="feedback.feedback_type" class="ml-5">{{ $t('feedbackForm.labelType1') }}</base-radio>
              </div>
            </div>
            <div v-if="feedback.feedback_type == 1" class="row margin-bottom-10 align-items-center">
              <label class="col-sm-2 col-form-label"> {{ $t('feedbackForm.labelHowWasYourExp') }}</label>
              <div class="col-sm-10 feedback-experiences-wrapper">
                <!-- <base-radio name="1" v-model="feedback.rating">1</base-radio>
                <base-radio name="2" v-model="feedback.rating" class="ml-5">2</base-radio>
                <base-radio name="3" v-model="feedback.rating" class="ml-5">3</base-radio>
                <base-radio name="4" v-model="feedback.rating" class="ml-5">4</base-radio>
                <base-radio name="5" v-model="feedback.rating" class="ml-5">5</base-radio> -->
                  <span @click="handleSelectFeedback(1)" class="cursor-pointer"><AmazingExperience :feedbackRating="feedback.rating" /></span>
                  <span @click="handleSelectFeedback(2)" class="cursor-pointer"><ReallyGoodExperience :feedbackRating="feedback.rating" /></span>
                  <span @click="handleSelectFeedback(3)" class="cursor-pointer"><GoodExperience :feedbackRating="feedback.rating" /></span>
                  <span @click="handleSelectFeedback(4)" class="cursor-pointer"><NotVeryGoodExperience :feedbackRating="feedback.rating" /></span>
                  <span @click="handleSelectFeedback(5)" class="cursor-pointer"><BadExperience :feedbackRating="feedback.rating" /></span>
              </div>
            </div>
            <div v-if="error.rating" class="row margin-bottom-10">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-10 d-flex flex-column">
                <label v-if="error.rating" class="col-sm-10 col-md-10 col-lg-10 help-block p-0 error"> {{
                  $t('feedbackForm.ratingRequired') }}</label>
              </div>
            </div>
            <div v-if="feedback.feedback_type == 2" class="row margin-bottom-10">
              <label class="col-sm-2 col-form-label"> {{ $t('feedbackForm.labelImpact') }}</label>
              <div class="col-sm-10">
                <el-select class="test-select select-primary select-inline feedback-impact" name="language" size="large"
                  v-model="feedback.impact">
                  <el-option
                    v-for="option in [{ value: 1, label: $t('feedbackForm.impactLow') }, { value: 2, label: $t('feedbackForm.impactMedium') }, { value: 3, label: $t('feedbackForm.impactHigh') }, { value: 4, label: $t('feedbackForm.impactEmergency') }]"
                    class="select-primary" :value="option.value" :label="option.label" :key="option.label">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row margin-bottom-10">
              <label v-if="feedback.feedback_type == 1" class="col-sm-2 col-form-label">{{
                $t('feedbackForm.labelDescUrExp') }}</label>
              <label v-if="feedback.feedback_type == 2" class="col-sm-2 col-form-label">{{
                $t('feedbackForm.labelBugExplaination') }}</label>
              <div class="col-sm-10">
                <div id="quill-editor"></div>
                <label v-if="error.descExp" class="col-sm-10 col-md-10 col-lg-10 help-block text-danger-feedback mt-2">
                  {{
                    $t('feedbackForm.descRequired') }}</label>
                <label v-if="error.bugExplain"
                  class="col-sm-10 col-md-10 col-lg-10 help-block text-danger-feedback m-2"> {{
                    $t('feedbackForm.bugExplainationRequired') }}</label>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label" style="visibility: hidden;">{{ $t('feedbackForm.labelUploadFile') }}</label>
              <div class="col-sm-10">
                <div class="select-image-btn margin-bottom-10">
                  <span class="btn btn-primary btn-simple btn-file">
                    <span class="fileinput-new">{{ $t('feedbackForm.btnFileSelect')
                    }}</span>
                    <!-- <span v-else class="fileinput-new">{{ $t('feedbackForm.btnUploadMoreFiles') }}</span> -->
                    <input type="hidden" value="" name="" />
                    <input @change="handlePreview" type="file" accept="image/*,video/*" name="..." class="valid" :multiple="true"
                      aria-invalid="false" />
                  </span>
                </div>
                <span v-if="feedback.files.length">
                  <span v-for="(file, index) in feedback.files" :key="index">
                    <div class="fileinput text-center position-relative" v-bind:class="{ 'ml-2': index > 0 }">
                      <div class="select-image-btn file-actions-container">
                        <img class="cursor-pointer" src="../../../public/img/feedback/remove-file.svg" alt="delete"
                          @click="removeFile(index)" />
                        <span class="btn-file">
                          <img class="ml-1 cursor-pointer" src="../../../public/img/feedback/edit-file.svg"
                            alt="edit" />
                          <input type="hidden" value="" name="" />
                          <input @change="e => handlePreview(e, index)" type="file" accept="image/*,video/*" name="..." class="valid"
                            :multiple="false" aria-invalid="false" />
                        </span>
                      </div>
                      <div class="fileinput-new thumbnail file" :class="{ 'img-circle': type === 'avatar' }">
                        <img v-if="isImage(file)" :src="getFileUrlForPreview(file)" alt="" />
                        <video v-else-if="isVideo(file)" :src="getFileUrlForPreview(file)" controls autoplay muted></video>
                      </div>
                    </div>
                  </span>
                </span>
              </div>
              <label class="col-sm-2 col-md-2 col-lg-2"></label>
            </div>
          </form>
          <template slot="footer" class="text-right">
            <base-button native-type="submit" @click.prevent="create" class="pull-right" type="primary"
              :loading="loading">{{
                $t('feedbackForm.btnFeedbackSubmit') }}</base-button>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BaseCheckbox, BaseRadio, AmazingExperience, ReallyGoodExperience, GoodExperience, NotVeryGoodExperience, BadExperience } from 'src/components/index';
import { Select, Option } from 'element-ui';
import quillEditorToolbar from 'src/app/shared/quillEditorToolbar';

import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);

export default {
  name: 'FeedbackForm',
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
    AmazingExperience, 
    ReallyGoodExperience,
    GoodExperience, 
    NotVeryGoodExperience, 
    BadExperience
  },
  data() {
    return {
      feedback: {
        uuid: null,
        name: '',
        email: '',
        experience_description: '',
        bug_explanation: '',
        feedback_type: 2,
        impact: 1,
        files: [],
        lang: '',
        rating: 0
      },
      error: {
        name: false,
        email: false,
        descExp: false,
        bugExplain: false,
        rating: false,
        email: false,
        invalidEmail: false
      },
      mode: 'create',
      loading: false,
      options: [{ 'value': '0', 'label': '---' }],
      editorOptions: [
        'bold',
        'underline', {
          name: 'italic',
          result: () => window.pell.exec('italic')
        },
        'heading1',
        'heading2',
        'paragraph',
        'strikethrough',
        'olist',
        'ulist',
        'code',
        'line'
      ],
      editorPlaceholder: this.$i18n.t('feedbackForm.placeHArchDesc'),
      editorClasses: {
        actionbar: 'pell-actionbar custom-actionbar',
        button: 'pell-button',
        content: 'pell-content',
        selected: 'pell-button-selected'
      },
      modelValidations: {
        required: {
          required: true
        }
      },
      typeOfReport: "feedback",
      feedbackSelected: "",
      impact: "low",
      darkMode: false,
      quillEditor: null,
    };
  },
  methods: {
    resetValue() {
      this.feedback = {
        uuid: null,
        name: '',
        experience_description: '',
        bug_explanation: '',
        feedback_type: 1,
        impact: "low",
        files: [],
        lang: '',
        rating: 0
      },
        this.error = {
          name: false,
          email: false,
          descExp: false,
          bugExplain: false,
          rating: false,
          email: false,
          invalidEmail: false
        },
        this.editorPlaceholder = this.$i18n.t('feedbackForm.placeHArchDesc'),
        this.modelValidations = {
          required: {
            required: true
          }
        }
    },
    handlePreview(event, idx) {
      if (!event.target.files.length) return;
      const files = event.target.files;
      let showError;
      if (isNaN(idx)) {
        const filesArray = Array.from(files);
        for (const file of filesArray) {
          if (this.isValidFileSize(file)) {
            this.feedback.files.push(file);
          }
          else {
            showError = true;
          }
        }
      } else {
        const file = files[0];
        if (this.isValidFileSize(file)) {
          this.feedback.files.splice(idx, 1, file);
        } else {
          showError = false;
        }
      }
      if (showError) {
        this.$notify({
          message: this.$i18n.t('feedbackForm.fileMaxSizeError'),
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    },
    removeFile(index) {
      this.feedback.files = this.feedback.files.filter((file, idx) => idx !== index);
    },
    deleteSelectedFile() {
      this.filesSelected = false;
      this.feedback.file = null;
    },
    getFileUrlForPreview(file) {
      return URL.createObjectURL(file)
    },
    async create() {
      const isBug = this.feedback.feedback_type == 2;
      let error;
      if (!this.feedback.name) {
        this.error.name = true;
        error = true;
      }
      if (!this.feedback.email) {
        this.error.email = true;
        error = true;
      } else if (this.feedback.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.feedback.email)) {
        this.error.invalidEmail = true;
        error = true;
      }
      if (!isBug) {
        if (!this.feedback.experience_description) {
          this.error.descExp = true;
          error = true;
        }
        if (!this.feedback.rating) {
          this.error.rating = true;
          error = true;
        }
      } else if (isBug && !this.feedback.bug_explanation) {
        this.error.bugExplain = true
        error = true;
      }
      if (error) return;
      const user = JSON.parse(localStorage.getItem('user'));
      const url = '/feedback/add';
      let fd = new FormData();
      fd.append('uuid', user.uuid);
      fd.append('name', this.feedback.name);
      fd.append('email', this.feedback.email);
      fd.append('experience_description', this.feedback.experience_description);
      fd.append('feedback_type', this.feedback.feedback_type);
      fd.append('impact', this.feedback.impact);
      fd.append('lang', user.lang);
      fd.append('rating', this.feedback.rating);
      if (this.feedback.files.length) {
        for (const file of this.feedback.files) {
          fd.append('file', file);
        }
      }
      let _this = this;
      _this.loading = true;
      if(isBug){
        let session_name;
        try{
          session_name = JSON.parse(window.localStorage.getItem('instance')).city;
        }catch(error){
          console.log(error)
        }
        const browserMetaData = await this.getBrowserMetaData();
        const { os, ip_address, browser_details, gpu_details, cpu_cores } = browserMetaData;
        const { browser_name, browser_version } = browser_details;
        const { vendor, renderer, display } = gpu_details;
        fd.append('bug_explanation', this.feedback.bug_explanation);
        fd.append('device_info', `OS - ${os}\n IP - ${ip_address}\n CPU cores - ${cpu_cores}\n Browser name - ${browser_name}\n Browser version - ${browser_version}\n Vendor - ${vendor}\n Renderer - ${renderer}\n Display - ${display}`)
        fd.append('session_name', session_name);
      }
      axios({
        method: 'post',
        url,
        data: fd,
        headers: this.getHeaders()
      }).then((response) => {
        if (response.data.success) {
          // _this.resetValue();
          _this.$notify({
            message: `${this.feedback.feedback_type == 1 ? 'Feedback' : 'Bug'} added successfully!`,
            timeout: 3000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          this.$router.push({ path: '/feedback/list' });
        } else {
          if (response.data.need_auth) {
            _this.$router.push({ path: '/login' });
          } else {
            _this.$notify({
              message: response.data.message || "Something went wrong, please try again later.",
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(e => {
        console.log("error:::", e);
        _this.$notify({
          message: e.message || "Something went wrong, please try again later.",
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }).finally(() => { _this.loading = false });
    },
    handleSelectFeedback(rating) {
      this.error.rating = false;
      this.feedback.rating = rating;
    },
    getHeaders() {
      let size = 0;
      const files = this.feedback.files;
      if (files.length) {
        for (const file of files) {
          size += file.size;
        }
      }
      return {
        'Filesize': size
      };
    },
    filterSpecialCharacters(event) {
      const charCode = event.keyCode || event.which;
      const charStr = String.fromCharCode(charCode);

      // Allow only alphanumeric characters and whitespace
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

      if (!alphanumericRegex.test(charStr)) {
        event.preventDefault();
      }
    },
    isImage(file){
      return file.type.includes('image');
    },
    isVideo(file){
      return file.type.includes('video');
    },
    bytesToMB(bytes) {
      return (bytes / (1024 * 1024)).toFixed(2);
    },
    isValidFileSize(file){
      return this.isImage(file) && this.bytesToMB(file.size) <= 10 || this.isVideo(file) && this.bytesToMB(file.size) <= 50;
    },
    async getBrowserMetaData() {
      const os = this.getOS();
      const browser_details = this.getBrowserDetails();
      const gpu_details = this.getGPUDetails();
      const ip_address = await this.getIPAddress();
      const cpu_cores = this.getCPUCores();
      return {
        os, browser_details, gpu_details, ip_address, cpu_cores
      }
    },
    getOS (){
      return navigator.platform;
    },
    getBrowserDetails() {
      const browserAgent = window.navigator.userAgent;
      let browser_name = window.navigator.appName;
      let browser_version = '' + parseFloat(window.navigator.appVersion);
      let browserMajorVersion = parseInt(window.navigator.appVersion, 10);
      let Offset, OffsetVersion, ix;

      // For Chrome  
      if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
        browser_name = "Chrome";
        browser_version = browserAgent.substring(OffsetVersion + 7);
      }

      // For Microsoft internet explorer  
      else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
        browser_name = "Microsoft Internet Explorer";
        browser_version = browserAgent.substring(OffsetVersion + 5);
      }

      // For Firefox 
      else if ((OffsetVersion = browserAgent.indexOf("Firefox")) != -1) {
        browser_name = "Firefox";
      }

      // For Safari 
      else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
        browser_name = "Safari";
        browser_version = browserAgent.substring(OffsetVersion + 7);
        if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
          browser_version = browserAgent.substring(OffsetVersion + 8);
      }

      // For other browser "name/version" is at the end of userAgent  
      else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
        (OffsetVersion = browserAgent.lastIndexOf('/'))) {
        browser_name = browserAgent.substring(Offset, OffsetVersion);
        browser_version = browserAgent.substring(OffsetVersion + 1);
        if (browser_name.toLowerCase() == browser_name.toUpperCase()) {
          browser_name = window.navigator.appName;
        }
      }

      // Trimming the fullVersion string at  
      // semicolon/space if present  
      if ((ix = browser_version.indexOf(";")) != -1)
        browser_version = browser_version.substring(0, ix);
      if ((ix = browser_version.indexOf(" ")) != -1)
        browser_version = browser_version.substring(0, ix);


      browserMajorVersion = parseInt('' + browser_version, 10);
      if (isNaN(browserMajorVersion)) {
        browser_version = '' + parseFloat(window.navigator.appVersion);
        browserMajorVersion = parseInt(window.navigator.appVersion, 10);
      }

      return {
        browser_name, browser_version
      }
    },
    getGPUDetails() {
      const canvas = document.createElement('canvas');
      let gl;
      let debugInfo;
      let vendor;
      let renderer;

      try {
        gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      } catch (e) {
      }

      if (gl) {
        debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;

      const colorDepth = window.screen.colorDepth || window.screen.pixelDepth;
      return {
        vendor,
        renderer,
        display: screenWidth + ' x ' + screenHeight + ' - ' + colorDepth + ' bits/pixel'
      }
    },
    async getIPAddress(){
      let result;
      try {
        const response = await axios('https://ipinfo.io/json');
        result = response.data.ip;
      } catch (error) {
        console.log('error', error);
      }
      finally{
        return result
      }
    },
    getCPUCores() {
      // works on chrome and firefox but not on safari, other browsers were not tested
      return navigator.hardwareConcurrency || 'NA';
    }
  },
  mounted() {
    const _this = this;
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      _this.feedback.email = user.email;
      _this.feedback.name = user.name;
      _this.darkMode = user.appsettings.darkMode;
    } catch (error) {
      console.log(error)
    }

    _this.quillEditor = new Quill('#quill-editor', {
      modules: {
        toolbar: quillEditorToolbar
      },
      theme: 'snow',
    });
    _this.quillEditor.on('text-change', function() {
      const editorContent = _this.quillEditor.root.innerHTML;
      if(_this.feedback.feedback_type == 1){
        _this.feedback.experience_description = editorContent;
      }else{
        _this.feedback.bug_explanation = editorContent;
      }
    });

  },
  watch: {
    'feedback.feedback_type'(newValue) {
      const _this = this;
      _this.quillEditor.clipboard.dangerouslyPasteHTML('');
      if (newValue == 1) {
        _this.error.bugExplain = false;
        _this.feedback.bug_explanation = '';
        _this.quillEditor.root.dataset.placeholder = _this.$i18n.t('feedbackForm.placeholder');

        const qlEditor = document.querySelector('.ql-editor.ql-blank');
        if(!qlEditor) return;
        qlEditor.classList.add(_this.darkMode ? 'dark-mode-placeholder' : 'light-mode-placeholder');
        qlEditor.classList.remove(_this.darkMode ? 'light-mode-placeholder': 'dark-mode-placeholder');  

      } else if (newValue == 2) {
        _this.error.descExp = false;
        _this.error.rating = false;
        _this.feedback.experience_description = '';
        _this.quillEditor.root.dataset.placeholder = "";
      }
    },
    'feedback.name'(newValue) {
      if (newValue) {
        this.error.name = false;
      }
    },
    'feedback.experience_description'(newValue) {
      if (newValue) {
        this.error.descExp = false;
      }
    },
    'feedback.bug_explanation'(newValue) {
      if (newValue) {
        this.error.bugExplain = false;
      }
    },
  }
};
</script>
<style lang="scss">
.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.map-container {
  height: 350px !important;
}

#mapview {
  padding: 0;
  margin: 0;
  height: 350px !important;
  width: 100%;
}

.margin-top-20 {
  margin-top: 20px;
}

.custom-actionbar {
  background-color: transparent !important;
  border-bottom: 1px solid rgb(43, 53, 83);
}

.custom-actionbar button.pell-button {
  color: #d3d7e9 !important;
}

.vp-editor {
  border: 1px solid rgb(43, 53, 83);
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  height: 150px;
}

body.white-content .test-select.select-primary.el-select .el-input input,
body.white-content .vp-editor {
  border-color: #8d919c !important;
}

.pell-content {
  height: 150px;
}

.vp-editor:focus {
  border: 1px solid #cd51e0 !important;
}

.pell-button {
  width: 40px;
}

.input-new-tag {
  margin-left: 0px;
  width: 200px !important;
  height: 32px;
  display: inline;
}

span.el-tag.el-tag--primary.el-tag--small.el-tag--light {
  margin-right: 10px;
}

label.text-danger-feedback {
  margin-left: -15px;
  margin-top: -40px;
  color: #e5262b !important;
}

label.error {
  // margin-left: -20px;
  color: #e5262b !important;
}

body:not(.white-content) span.file-selected {
  color: hsla(0, 0%, 100%, .8);
  font-weight: bold;
}

body.white-content span.file-selected {
  color: #344675;
  font-weight: bold;
}

div.loading {
  position: absolute;
  margin-left: 50%;
  margin-right: -20px;
  top: 50%;
}

div.archive-published {
  margin-top: -15px;
}

.feedback-form .card-header {
  display: none;
}

.feedback-form .card-body {
  padding: 0;
}

.feedback-form .primary-text {
  font-size: calc(1em + .5vw);
  font-weight: 500;
}

.feedback-form .secondary-text {
  font-size: calc(1em + .1vw);
}

.feedback-form .feedback-wrapper {
  padding: 20px 40px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.feedback-type {
  height: calc(2.25rem + 2px);
}

.cursor-pointer {
  cursor: pointer;
}

.file img, .file video {
  height: 120px !important;
}

.feedback-main .el-select-dropdown {
  width: 73% !important;
  left: 334px;
}

.select-image-btn img {
  width: 30px;
}

.file-actions-container {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1;
}

.feedback-main textarea {
  width: 100%;
  border-color: #9f9797;
  border-radius: 8px;
  resize: none;
  outline: none;
  color: #222a42;
}

.feedback-experiences-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.feedback-experiences-wrapper > span {
  display: flex;
}

.color-white {
  color: hsla(0,0%,100%,.6);;
}
/* Light mode placeholder color */
.light-mode-placeholder::before {
    color: #344675 !important; /* Change to your desired light mode color */
}

/* Dark mode placeholder color */
.dark-mode-placeholder::before {
    color: hsla(0,0%,100%,.6) !important; /* Change to your desired dark mode color */
}

</style>