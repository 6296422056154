<template>
  <!-- https://fontawesome.bootstrapcheatsheets.com/  -->
  <div class="filters-container" v-show="hover" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="filters-content type-filters dropdown show-dropdown"  :class="{ show: displayType }"  @mouseover="displayType = true" @mouseleave="displayType = false">
       <a data-toggle="dropdown" class="settings-icon">
        <i class="fas map-icons list-icon"></i>
      </a>
      <div class="dropdown-menu" :class="{ show: displayType }">
        <span class="header-title">{{ $t('mapPage.type')}}</span>
        <div class="type-filters-element">
          <base-switch v-model='filters.nopublish' on-text="" off-text="" @input="onChangeFilters('nopublish')"></base-switch>
          <i class='fas type-icons no-published-icon'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.nopublish')}}</span>
        </div>
        <div class="type-filters-element">
          <base-switch v-model='filters.publish' on-text="" off-text="" @input="onChangeFilters('publish')"></base-switch>
          <i class='fas type-icons published-icon'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.publish')}}</span>
        </div>
        <div class="divider"></div>
        <div class="type-filters-element">
          <base-switch v-model='filters.linear' on-text="" off-text="" @input="onChangeFilters('linear')"></base-switch>
          <i class='fas type-icons linear-icon'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.linear')}} </span>
        </div>
        <div class="type-filters-element">
          <base-switch v-model='filters.ponctuel' on-text="" off-text="" @input="onChangeFilters('ponctuel')"></base-switch>
          <i class='fas type-icons ponctuel-icon'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.ponctual')}}</span>
        </div>
      </div>
    </div>
    <div class="filters-content conflicts-filters dropdown show-dropdown" :class="{ show: displayConflict }" @mouseover="displayConflict = true" @mouseleave="displayConflict = false">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fas map-icons conflict-icon"></i>
      </a>
      <div class="dropdown-menu" :class="{ show: displayConflict }">
        <span class="header-title">{{ $t('mapPage.conflicts')}}</span>
        <div class="type-filters-element">
          <base-switch v-model='filtersConflict.sites' on-text="" off-text="" @input="onChangeConflicts('entraves')"></base-switch>
          <i class='fas conflict-icons conflict-c4'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.conflict1')}}</span>
        </div>
        <div class="type-filters-element">
          <base-switch v-model='filtersConflict.detours' on-text="" off-text="" @input="onChangeConflicts('closing')"></base-switch>
          <i class='fas conflict-icons conflict-c2'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.conflict2')}}</span>
        </div>
        <div class="type-filters-element">
          <base-switch v-model='filtersConflict.sitesDetours' on-text="" off-text="" @input="onChangeConflicts('detours')"></base-switch>
          <i class='fas conflict-icons conflict-c1'></i>
          <span class="label-switch label-right no-published"> {{ $t('mapPage.conflict3')}}</span>
        </div>
      </div>    
    </div>
    <div class="filters-content phases-filters dropdown show-dropdown" :class="{ show: displayOpen }" @mouseover="displayOpen = true" @mouseleave="displayOpen = false">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fas map-icons eye-icon"></i>
      </a>
      <div class="dropdown-menu" :class="{ show: displayOpen }">
        <span class="header-title">{{ $t('mapPage.displaying')}}</span>
        <div class="phases-filters-element">
          <base-switch v-model='display.entraves' on-text="" off-text="" @input="onChange('entraves')"></base-switch>
          <i class="fa fa-map"></i>
          <!--<i class='fas fa-map-marked-alt'></i>-->
          <!--<i class="fa fa-map-marker"></i>-->
          <!--<i class="fa fa-map-marker-alt"></i>-->
          <!--<i class="fa fa-map-pin"></i>-->
          <!--<i class="fa fa-map-signs"></i>-->
          <!--<i class="fa fa-map"></i>-->
          <span class="label-switch label-right"> {{ $t('mapPage.worksites')}}</span>
        </div>
        <div class="phases-filters-element">
          <base-switch v-model='display.closing' on-text="" off-text="" @input="onChange('closing')"></base-switch>
           <i class="fa fa-exclamation-triangle"></i>
          <span class="label-switch label-right"> {{ $t('mapPage.phases')}}</span>
        </div>
        <div class="phases-filters-element">
          <base-switch v-model='display.detours' on-text="" off-text="" @input="onChange('detours')"></base-switch>
          <!--<font-awesome-icon icon="user-secret" />-->
          <i class='fas fa-recycle'></i>
          <span class="label-switch label-right"> {{ $t('mapPage.detours')}}</span>
        </div>
        <div class="divider"></div>
        <div class="phases-filters-element">
          <base-switch v-model='display.pin' on-text="" off-text="" @input="onChange('pin')"></base-switch>
          <i class="fa fa-map-marker-alt"></i>
          <span class="label-switch label-right"> {{ $t('mapPage.pin')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { BaseSwitch } from 'src/components';

export default {
  name: 'filters-component',
  props: {
    'hover': {type: Boolean},
    'display': {type: Object},
    'filters': {type: Object},
    'filtersConflict': {type: Object}
  },
  components: {
    BaseSwitch
  },
  watch: {},
  data () {
    return {
      displayOpen: false,
      displayType: false,
      displayConflict: false
    };
  },
  methods: {
    onChange(type) {
      this.$emit('changeDisplaying', [type, this.display[type]]);
    },
    onChangeFilters(filter) {
      this.$emit('changeFilters', [filter, this.filters[filter]]);
    },
    onChangeConflicts(conflict) {
      this.$emit('changeConflicts', [conflict, this.filtersConflict[conflict]]);
    }
  }
}
</script>
<style>
  div.filters-container{
    display: flex;
    justify-content: center;
    position: fixed;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 80px;
    right: 0px;
    z-index: 999;
    cursor: pointer;
    z-index: 1;
  }
   div.filters-container div.dropdown-menu {
    min-width: 11rem !important;
  }
  
  div.filters-content {
    min-width: 60px;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-box-shadow: rgba(0,0,0,.3) 0 1px 4px -1px;
    box-shadow: rgba(0,0,0,.3) 0 1px 4px -1px;
    background: #FFF;
    /*padding: 5px;*/
    text-align: center;
    margin-left: 5px;
    background-color: #fff;
    opacity: 0.6;
    filter: alpha(opacity=80);
  }
  div.filters-container:hover div.filters-content {
    opacity: 1;
  }
  div.filters-content div.dropdown-menu {
    padding: .5rem .5rem;
    width: 180px;
  }
  
  div.type-filters-element,
  div.phases-filters-element {
    margin-top: 10px;
    display: inline-table;
  }
  div.phases-filters-element i{
    margin-left: 5px;
    top: 0px;
    font-size: 14px;
  }
  div.type-filters-element span,
  div.phases-filters-element span{
    font-size: 12px;
    font-weight: bold;
  }
  div.divider {
    border-bottom: 1px solid #EEE;
    margin-top: 10px;
  }
  
  div.filters-content.conflicts-filters,
  div.filters-content.type-filters,
  div.filters-content.phases-filters {
    margin-right: 20px;
  }
  
  div.filters-content.type-filters a {
    position: relative;
    top: 4px;
  }
  div.filters-content.phases-filters a {
    position: relative;
    top: 2px;
  }
  
  i.type-icons {
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: 14px;
    height: 18px;
    background: url("../../../public/img/filters_type.png") no-repeat 0 0;
    top: 5px !important;
  }
  i.no-published-icon {
    background-position: -43px 0;
  }
  i.published-icon {
    background-position: -29px 0;
  }
  i.linear-icon {
    background-position: 0px 0;
  }
  i.ponctuel-icon {
    background-position: -15px 0;
  }
  i.list-icon {
    background-position: -24px 0 !important;
  }
  i.eye-icon {
    background-position: -72px 0 !important;
  }
  i.conflict-icon {
     background-position: -48px 0 !important;
  }
  i.map-icons {
    width: 24px;
    height: 24px;
    background: url("../../../public/img/map_icons.png") no-repeat 0 0;
  }
  i.conflict-c1 {
    width: 24px;
    height: 24px;
    background: url("../../../public/img/conflict-c1.png") center no-repeat;
    top: 10px !important;
  }
  i.conflict-c2 {
    width: 24px;
    height: 24px;
    background: url("../../../public/img/conflict-c2.png") center no-repeat;
    top: 10px !important;
  }
  i.conflict-c3 {
    width: 24px;
    height: 24px;
    background: url("../../../public/img/conflict-c3.png") center no-repeat;
    top: 10px !important;
  }
  i.conflict-c4 {
    width: 24px;
    height: 24px;
    background: url("../../../public/img/conflict-c4.png") center no-repeat;
    top: 10px !important;
  }
</style>
