<template>
  <div>
    <modal :show.sync="alertProject" headerClasses="justify-content-center" type="custom-alert" :scrollToBottom="false" v-on:hideModal="hideModal">
      <h4 slot="header" class="title title-up"> {{ $t('alertProject.titleProject') }}</h4>
      <template slot="close-button">
        <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="col-md-12 alert-project-container">
        <!--
        <div class="row alert-element">
          <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.title') }}</label>
          <div class="col-sm-5">
            <base-radio  name="sms" class="col-sm-12" v-model="alert.reminder">
              {{ $t('alertProject.sms') }}
            </base-radio>
          </div>
          <div class="col-sm-5">
            <base-radio  name="email" class="col-sm-12" v-model="alert.reminder">
              {{ $t('alertProject.email') }}
            </base-radio>
          </div>
        </div>
        -->
        <div class="row alert-element">
           <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.type') }}</label>
          <div class="col-sm-3">
            <base-radio inline name="start" class="mb-3 col-sm-12" v-model="alert.type">
              {{ $t('alertProject.typeStart') }}
            </base-radio>
            <!-- <base-checkbox v-model="alert.type.start" class="alert-checkbox" :checked="alert.type.start" @input="updateType()">{{ $t('alertProject.typeStart') }}</base-checkbox> -->
          </div>
          <div class="col-sm-3">
            <base-radio inline name="end" class="mb-3 col-sm-12" v-model="alert.type">
              {{ $t('alertProject.typeEnd') }}
            </base-radio>
            <!-- <base-checkbox v-model="alert.type.end" class="alert-checkbox" :checked="alert.type.end" @input="updateType()">{{ $t('alertProject.typeEnd') }}</base-checkbox> -->
          </div>
          <!-- <div v-show="alert.selectType" class="col-sm-10 error-container"><label class="text-danger">{{ $t('alertProject.typeMandatory') }}</label></div> -->
          
        </div>
      
        <div class="row alert-element">
          <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.period') }}</label>
          <div class="col-sm-2">
            <base-checkbox v-model="alert.period.oneDay" class="alert-checkbox" :checked="alert.period.oneDay" @input="updatePeriod()">24h</base-checkbox>
          </div>
          <div class="col-sm-2">
            <base-checkbox v-model="alert.period.twoDay" class="alert-checkbox" :checked="alert.period.twoDay" @input="updatePeriod()">48h</base-checkbox>
          </div>
          <div class="col-sm-2">
            <base-checkbox v-model="alert.period.threeDay" class="alert-checkbox" :checked="alert.period.threeDay" @input="updatePeriod()">72h</base-checkbox>
          </div>
          <div class="col-sm-2">
            <base-checkbox v-model="alert.period.sameDay" class="alert-checkbox" :checked="alert.period.sameDay" @input="updatePeriod()">{{ $t('alertProject.period4') }}</base-checkbox>
          </div>
          <div class="col-sm-2">
            <base-checkbox v-model="alert.period.week" class="alert-checkbox" :checked="alert.period.week" @input="updatePeriod()">{{ $t('alertProject.period5') }}</base-checkbox>
          </div>
          <div class="col-sm-2"></div>
          <div v-show="alert.selectPeriod" class="col-sm-10 error-container"><label class="text-danger">{{ $t('alertProject.periodMandatory') }}</label></div>
        </div>
        
        <div class="row alert-element" v-if="features.projectReminderButtonInProjectListing.remindByEmail.show">
          <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.title') }}</label>
          <div class="col-sm-10" style="display: flex;">
            <div class="col-sm-4" style="padding-left: 0px; padding-right: 0px;">
              <base-checkbox v-model="alert.remail" class="alert-checkbox" :checked="alert.remail" @input="">{{ $t('alertProject.email') }}</base-checkbox>
            </div>
            <div v-if="alert.remail" class="col-sm-8" style="padding-left: 0px; padding-right: 0px;">
               <base-input
                name="email"
                type="email"
                required
                v-bind:placeholder="$t('userform.placeholderEmail')"
                v-validate="modelValidations.email"
                :error="getError('email')"
                v-model="alert.email">
              </base-input>
            </div>
          </div>
        </div>
        <div class="row alert-element" v-if="features.projectReminderButtonInProjectListing.remindBySMS.show">
          <label class="col-sm-2 col-form-label user-label"></label>
          <div class="col-sm-10" style="display: flex;">
            <div class="col-sm-4" style="padding-left: 0px; padding-right: 0px;">
              <base-checkbox v-model="alert.rsms" class="alert-checkbox" :checked="alert.rsms" @input="">{{ $t('alertProject.sms') }}</base-checkbox>
            </div>
            <div v-if="alert.rsms" class="col-sm-8" style="padding-left: 0px; padding-right: 0px;">
              <VuePhoneNumberInput @update="updatePhone" name="phone" required v-validate="modelValidations.phone" :error="getError('phone')" v-model="alert.phone" :only-countries="['CA','US','IN']" default-country-code="CA" :countries-height="40" :translations="{countrySelectorLabel:$t('alertProject.code'), countrySelectorError:$t('alertProject.choose'), phoneNumberLabel:$t('alertProject.phonenumber'), example:$t('alertProject.example')}" />
            </div>
          </div>
        </div>
        <!--
        <div v-if="alert.reminder=='sms'" class="row alert-element">
          <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.phone') }}</label>
          <div class="col-sm-10 col-md-10 col-lg-10">
            <VuePhoneNumberInput @update="updatePhone" name="phone" required v-validate="modelValidations.phone" :error="getError('phone')" v-model="alert.phone" :only-countries="['CA']" default-country-code="CA" :countries-height="40" :translations="{countrySelectorLabel:$t('alertProject.code'), countrySelectorError:$t('alertProject.choose'), phoneNumberLabel:$t('alertProject.phonenumber'), example:$t('alertProject.example')}" />
          </div>
        </div>
        <div v-if="alert.reminder=='email'" class="row alert-element">
          <label class="col-sm-2 col-form-label user-label">{{ $t('alertProject.email') }}</label>
          <div class="col-sm-10 col-md-10 col-lg-10">
            <base-input
              name="email"
              type="email"
              required
              v-bind:placeholder="$t('userform.placeholderEmail')"
              v-validate="modelValidations.email"
              :error="getError('email')"
              v-model="alert.email">
            </base-input>
          </div>
        </div>
        -->
      </div>
      <template slot="footer">
        <base-button type="danger" style="display: contents;" v-on:click="hideModal()">{{ $t('shareProject.btnClose') }}</base-button>
        <base-button @click='validate()'>{{ $t('shareProject.Save') }}</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { Modal } from 'src/components';

import Vue from 'vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from 'moment';

import features from './../shared/features';

var periodMap = {
  'oneDay': 86400000,
  'twoDay': 172800000,
  'threeDay': 259200000,
  'sameDay': 28800000,
  'week': 604800000
};
var periodMapAddHours = {
  'oneDay': 115200000,
  'twoDay': 201600000,
  'threeDay': 288000000,
  'sameDay': 28800000,
  'week': 633600000
};
var typeMap = {
  'start': 1,
  'end': 2,
};

export default {
  name:'alert-project',
  props: ['alertProject', 'alertProjectUUID', 'alertProjectPublish','sDate','eDate'],
  components: {
    Modal,
    BaseRadio,
    VuePhoneNumberInput,
    BaseCheckbox
  },
  created () {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.features = features[this.instance.city];
  },
  data() {
    return {
      alert: {
        /*'reminder':'sms',
        'start': true,
        'publish': false,*/
        'selectPeriod': false,
        'selectType': false,
        'rsms': true,
        'remail': true,
        // 'type':{
        //   'start': true,
        //   'end': false,
        // },
        'type':'start',
        'phone':'',
        'fphone':'',
        'email':'',
        'period': {
          'oneDay': false,
          'twoDay': false,
          'threeDay': false,
          'sameDay': true,
          'week': false
        }
      },
      showPublish: true,
      instance : null,
      features: null,
      modelValidations: {
        email: {
          required: true,
          email: true
        },
        phone: {
          required: true
        }
      }/*,
      translations:{
        countrySelectorLabel: this.$i18n.t('alertProject.code'),
        countrySelectorError: this.$i18n.t('alertProject.choose'),
        phoneNumberLabel: this.$i18n.t('alertProject.phonenumber'),
        example: this.$i18n.t('alertProject.example')
      }*/
    };
  },
  methods: {
    updatePeriod() {
      this.alert.selectPeriod=false;
    },
    updateType() {
      this.alert.selectType=false;
    },
    updatePhone(phone) {
      this.alert.fphone=phone.formattedNumber;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      let _this=this;
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          _this.saveAlert(); 
        }
      });
    },
    hideModal() {
      let _this=this;
      _this.$emit('hideAlertProject');
    },
    formatDate(value) {
      return moment(value);
    },
    saveAlert() {
      let _this=this;
      let obj = {
        uuid: this.alertProjectUUID,
        lang: this.$root.$i18n.locale,
        // type: this.alert.type
        //reminder: alert.reminder,
        type: ( this.alert.type === "start" ? 1 : 2)
      };
      if ((!this.alert.rsms) && (!this.alert.remail)) {
        return;
      }
      if (this.alert.rsms) {
        obj.phone=this.alert.fphone;
      }
      if (this.alert.remail) {
        obj.email=this.alert.email;
      }
      if ((!this.alert.period.oneDay) && (!this.alert.period.twoDay) && (!this.alert.period.threeDay) && (!this.alert.period.sameDay) && (!this.alert.period.week) ) {
        this.alert.selectPeriod=true;
        return;
      } else {
        let keys = Object.keys(this.alert.period);
        obj.period = [];
        console.log(obj.type)
        if(obj.type == 1){
          var date = this.formatDate(this.sDate);
          if (date.utc().hour() == 4 && date.utc().minute() == 0) {
            keys.forEach(function (item) {
              if (_this.alert.period[item]) obj.period.push(periodMapAddHours[item]); 
            })
          } else {
            keys.forEach(function (item) {
              if (_this.alert.period[item]) obj.period.push(periodMap[item]); 
            })
          }
        }else{
          var date = this.formatDate(this.eDate);
          if (date.utc().hour() == 4 && date.utc().minute() == 0) {
            keys.forEach(function (item) {
              if (_this.alert.period[item]) obj.period.push(periodMapAddHours[item]); 
            })
          } else {
            keys.forEach(function (item) {
              if (_this.alert.period[item]) obj.period.push(periodMap[item]); 
            })
          }
        }
      }
      obj.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      axios.post('/notification/add', obj).then(response => {
        let data = response.data;
        if (data.success) {
          _this.$notify({
            message: _this.$i18n.t('serverReply.messageAlertSuc'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          _this.hideModal();
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorAlertProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: _this.$i18n.t('serverReply.errorAlertProject'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    },
    onChange(checked, type, uuid) {
      
    }
  },
  watch: {
    'alertProject'(val) {
      if (val)  {
        this.showPublish=!this.alertProjectPublish;
      }
    }
  }
}
</script>

<style>
  div.modal-custom-alert div.modal-dialog {
    width: 85% !important;
  }
  div.alert-project-container {
    margin-top: 50px;
  }
  div.row.alert-element {
    margin-bottom: 30px;
  }
  /* #ec250d   #00bf9a */
  div.input-tel.input-phone-number.has-error input.input-tel__input {
    border-color: #ec250d !important;
  }
  
  div.input-tel.input-phone-number.is-focused.is-valid.has-value input.input-tel__input {
    box-shadow: #00bf9a 0px 0px 0px 0.125rem !important;
  }

  div.input-tel.input-phone-number.is-valid.has-value input.input-tel__input,
  div.country-selector.input-country-selector.has-value.is-valid input.country-selector__input {
    border-color: #00bf9a !important;
  }
  
  div.country-selector.input-country-selector.has-value.is-valid label.country-selector__label,
  div.input-tel.input-phone-number.is-valid.has-value label.input-tel__label {
    color: #00bf9a !important;
  }
  .close-alert {
    display: none;
  }
  div.error-container {
    margin-top: -20px;
  }
  div.error-container label.text-danger {
    margin-left: 0px;
    margin-top: 0px;
  }
  
  input.form-control,input.form-control:focus,input.form-control:active{
    color: inherit;
  }

</style>