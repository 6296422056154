<template>
  <div class="loading" v-if="loading">
    <img src="../../../public/img/loading.gif"/>
  </div>
  <div v-else>
    <div v-show="tableData.length==0">
      <base-alert type="info"> {{ $t('archivePage.alert1') }}</base-alert>
    </div>
    <div v-show="tableData.length > 0" style="overflow-x: auto;">
      <base-table :data="tableData" thead-classes="text-primary project-table-head" tbody-classes="archive-table-body">
        <template slot="columns" slot-scope="{ columns }">
          <!--<th width="80" class="text-right"></th>-->
          <th width="20" class="pad-left-20" >{{ $t('archivePage.labelArchivePublished') }}</th>
          <th>{{ $t('archivePage.labelName') }}</th>
          <th>{{ $t('archivePage.labelDesc') }}</th>
          <th>{{ $t('archivePage.labelTag') }}</th>
          <template v-if="isArchivesListPage()">
            <th width="280">{{ $t('archivePage.labelRetention') }}</th>
            <th width="200" class="pad-left-20" >{{ $t('archivePage.labelCDate') }}</th>
          </template>
          <th width="0" class="text-right"></th>
        </template>
    
        <template slot-scope="{ row, index }" >
          <!--<td>
            <img style="width: 24px;" v-if="row.type=='cad'" src="../../../public/img/cad3.png" />
            <img style="width: 24px;" v-else src="../../../public/img/pdf3.png" />
          </td>-->
          <td class="published-content" v-html="row.publishedLabel"></td>
          <td ><p class="title">{{ row.name }}</p></td>
          <td v-html="row.description"></td>
          <td v-html="row.fTags"></td>
          <!--<td>{{ row.fRetention}}</td>-->
          <template v-if="isArchivesListPage()">
            <td><base-progress type="primary" :label="row.fRetentionLabel" value-position="right" :value="row.fRetentionValue" /></td>
            <td class="pad-left-20">{{ row.cdate | formatDate}}</td>
          </template>

          <td class="text-right btn-container">
            <div>
              <el-tooltip
                v-bind:content= "$t('archivePage.tipEdit')"
                effect="light"
                :open-delay="300"
                placement="top">
                <base-button
                  :type="'success'"
                  icon
                  size="sm"
                  @click.native="updateArchive(row)"
                  class="btn-link">
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip
                v-bind:content= "$t('archivePage.tipDld')"
                effect="light"
                :open-delay="300"
                placement="top">
                <base-button
                  :type="'info'"
                  icon
                  size="sm"
                  @click.native="downloadArchive(row.uuid)"
                  class="btn-link">
                  <i class="tim-icons icon-cloud-download-93"></i>
                </base-button>
              </el-tooltip>
              <el-tooltip
                v-bind:content= "$t('archivePage.tipDelete')"
                effect="light"
                :open-delay="300"
                placement="top">
                <base-button
                  :type="'danger'"
                  icon
                  size="sm"
                  @click.native="deleteArchive(row.uuid)"
                  class="btn-link">
                  <i class="tim-icons icon-trash-simple"></i>
                </base-button>
              </el-tooltip>
            </div>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { BaseTable } from '@/components';
import { BasePagination, BaseAlert } from 'src/components';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert2';

import {Slider, BaseProgress} from 'src/components/index';

export default {
  name: 'ArchivesTable',
  components: {
    BaseTable,
    BasePagination,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseProgress,
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    onDownload: {
      type: Function,
      required: true
    },
    componentName: {
      type: String,
      required: true
    },
    getArchives: {
      type: Function,
      required: false
    },
    updateModal: {
      type: Function,
      required: false
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    }
  },
  methods: {
    isArchivesListPage() {
      return this.componentName === 'ArchivesPage';
    },
    updateArchive(archive) {
      const { uuid } = archive
      if (this.isArchivesListPage()) {
        const returnedUrl = window.location.href;
        this.$router.push({
          path: '/archives/edit',
          query: { uuid, returnedUrl },
        });    
      } else {
        this.updateModal(true, archive);
      }
    },
    downloadArchive(uuid) {
      let filePath='/archive/download?uuid=' + uuid;
      var a = document.createElement('A');
      a.href = filePath;
      a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleteArchive(uuid) {
      let _this = this;
      swal({
        title: _this.$i18n.t('archivePage.titleArchiveDelete'),
        text:  _this.$i18n.t('archivePage.textArchiveDelete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: _this.$i18n.t('archivePage.textConfirmArchiveDelete'),
        cancelButtonText: _this.$i18n.t('archivePage.textCancelArchiveDelete'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          axios.post('archive/delete', { uuid }).then(response => {
            let data = response.data;
            if (data.success) {
              swal({
                title: _this.$i18n.t('archivePage.titleDeletedArchive'),
                text:  _this.$i18n.t('archivePage.textDeletedArchive'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              }).then(() => {
                if (_this.isArchivesListPage()) {
                  _this.getArchives()
                } else {
                  const updatedArchives = _this.tableData.filter(archive => archive.uuid !== uuid)
                  _this.tableData = updatedArchives
                }
              });
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorDeleteArchive'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorDeleteArchive'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      });
    },
  },
}
</script>

<style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  table.el-table__header {
    margin-bottom: 0px;
  }
  .bootstrap-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
    vertical-align: middle;
    cursor: text;
  }
  .bootstrap-tagsinput .tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
        padding-left: 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: .75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
    padding-left: .8em;
    color: #fff;
  }
  div.card.archives-card div.card-body {
    min-height: 500px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }
  div.card.archives-card .pad-left-20 {
    padding-left: 20px
  }
  .pell-button {
    width: 35px !important;
  }
  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
</style>