<template>
  <div>
    <modal :show.sync="shareProject" headerClasses="justify-content-center" type="custom-users" :scrollToBottom="false" v-on:hideModal="hideModal">
      <h4 slot="header" class="title title-up"> {{ $t('shareProject.titleShare') }}</h4>
      <template slot="close-button">
        <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="col-md-12 share-project-container">
        <div v-show="userRes.length>0" class="row users-liste">
          <label class="col-sm-6 col-form-label user-label">{{ $t('shareProject.labelUserRW') }} </label>
          <div class="col-sm-6">
            <base-checkbox v-for="(item, index) in userRes" v-bind:key="index" value="item.uuid" class="shared-checkbox" @input="onChange($event, 'user', item.uuid)">{{item.login}}</base-checkbox>
          </div>
        </div>
        <div v-show="viewerRes.length>0" class="row users-liste">
          <label class="col-sm-6 col-form-label user-label"> {{ $t('shareProject.labelUserR') }}</label>
          <div class="col-sm-6 checkbox-radios">
            <base-checkbox v-for="(item, index) in viewerRes" v-bind:key="index" value="item.uuid" class="shared-checkbox" @input="onChange($event, 'viewer', item.uuid)">{{item.login}}</base-checkbox>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button type="danger" v-on:click="hideModal()">{{ $t('shareProject.btnClose') }}</base-button>
        <base-button  @click='shareProjects()'>{{ $t('shareProject.Save') }}</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

import { BaseCheckbox} from 'src/components/index';

import { Modal } from 'src/components';

export default {
  name:'share-project',
  props: ['shareProject', 'selectedProject', 'userRes', 'viewerRes'],
  components: {
    Modal,
    BaseCheckbox
  },
  data() {
    return {
      selectedUser: [],
      selectedViewer: []
    };
  },
  methods: {
    hideModal() {
      console.log("X");
      document.querySelectorAll('div.shared-checkbox input').forEach((e) =>{ if (e.checked) e.click();});
      this.selectedViewer = this.selectedUser = [];
      this.$emit('hideShareProject');
    },
    shareProjects() {
      if (this.selectedUser.length > 0 || this.selectedViewer.length > 0) {
        let _this=this;
        let obj = {
          projects: this.selectedProject,
          user: this.selectedUser,
          viewer: this.selectedViewer
        };
        axios.post('/project/shared', obj).then(response => {
          let data = response.data;
          if (data.success) {
            console.log("success");
            _this.$notify({
              message: _this.$i18n.t('serverReply.messageShareSuc'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
            this.hideModal();
          } else {
            if (data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              _this.$notify({
                message: _this.$i18n.t('serverReply.errorShareProject'),
                timeout: 10000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch(() => {
          _this.$notify({
            message: _this.$i18n.t('serverReply.errorShareProj'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        });
      }
    },
    onChange(checked, type, uuid) {
      if (type=='user') {
        this.selectedUser = this.selectedUser.filter((e) => { if (e!==uuid) return e;});
        if (checked) { this.selectedUser.push(uuid);}
      } else {
        this.selectedViewer = this.selectedViewer.filter((e) => { if (e!==uuid) return e;});
        if (checked) { this.selectedViewer.push(uuid);}
      }
    }
  },
  watch: {}
}
</script>
<style>
  div.modal-custom-users div.modal-dialog {
    width: 40% !important;
  }
  div.modal-custom-users  div.users-liste {
    margin-top: 10px;
  }
  div.modal-custom-users  div.users-liste {
    margin-bottom: 30px;
  }
  div.modal-custom-users  div.users-liste label.user-label {
    font-weight: bold;
  }
</style>