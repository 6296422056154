<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12 detours-phases">
        <h6 class="title phases-title">{{ $t('projectForm.phasesTitle') }}</h6>
        <div class="card card-timeline card-plain">
          <div class="card-body">
            <ul class="timeline">
              <li v-for="(item, index) in phases" v-bind:key="item.phaseId" class="timeline-inverted">
                <div class="timeline-badge info">
                  {{indexPhase(index)}}
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <!--<span class="badge badge-pill badge-info">Phase {{item.name}} </span>-->
                  </div>
                  <div class="timeline-body">
                    <detour-component v-for="(item1, index1) in item.closing"  v-bind:key="item1.closingId" v-bind:item="item1" v-bind:index="index1" v-bind:date="[sdate, edate]"
                                      v-on:addDetour="addDetour($event, item.phaseId)"
                                      v-on:removeDetour="removeDetour($event, item.phaseId)"
                                      v-on:addDetourDate="addDetourDate($event, item.phaseId)"
                                      v-on:removeDetourDate="removeDetourDate($event, item.phaseId)"
                                      v-on:input="inputDetour($event, item.phaseId)"
                                      v-on:showModal="showModal($event, item.phaseId)"
                                      :statusOfUser="statusOfUser">
                    </detour-component>
                  </div>
                </div>
              </li>
            </ul>
            <modal-map-detour-component v-bind:modalMap="modalMap"
                                        v-if="!statusOfUser"
                                        v-bind:selectedDetour="selectedDetour"
                                        v-bind:closing="selectedClosing"
                                        v-bind:mapMode="mode"
                                        v-bind:perimeter="perimeter"
                                        v-bind:date="[sdate, edate]"
                                        v-bind:uuid="uuid"
                                        v-bind:layer="layer"
                                        v-on:saveMapModal="saveMapModal($event)"
                                        v-on:closeMapModal="closeMapModal()">
            </modal-map-detour-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from 'src/components';
import PhaseComponent from './PhaseComponent.vue';
import DetourComponent from './DetourComponent.vue';
import { uuid } from 'vue-uuid';
import ModalMapDetourComponent from './../map/ModalMapDetourComponent.vue';

export default {
  components: {
    TimeLine,
    TimeLineItem,
    PhaseComponent,
    DetourComponent,
    ModalMapDetourComponent,
    nbClosingDate: 1
  },
  props: ['phases', 'sdate', 'edate', 'perimeter', 'mode', 'uuid','statusOfUser','layer'],
  data() {
    return {
      validateDetourStep: true,
      modalMap: false,
      selectedPhase: '',
      selectedClosingIndex: null,
      selectedClosing: null,
      selectedDetourIndex: null,
      selectedDetour: null
    };
  },
  created() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    if (user.nbClosingDate) {
      this.nbClosingDate = user.nbClosingDate;
    }
  },
  methods: {
    indexPhase(index) {
      return index + 1;
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.validateDetour();
        if (!this.validateDetourStep) {
          this.$notify({
            message:  this.$i18n.t('projectForm.detourMandatory'), 
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        } else {
          this.$emit('on-validated', res && (this.validateDetourStep), 'detour', this.model);
          return res && (this.validateDetourStep); 
        }
      });
    },
    showModal(params, phaseId) {
      this.selectedPhase = phaseId;
      this.selectedClosing = params[0];
      this.selectedClosingIndex = this.selectedClosing.closingId;
      this.selectedDetour = params[1];
      this.selectedDetourIndex  = this.selectedDetour.detourId;
      this.modalMap = true;
    },
    closeMapModal() {
      this.modalMap = false;
    },
    saveMapModal(data) {
      this.modalMap = false;
      var that=this;
      this.phases.forEach(function(phase) {
        if (phase.phaseId==that.selectedPhase) {
          phase.closing.forEach(function(close) {
            if(close.closingId==that.selectedClosingIndex) {
              close.detours.forEach(function(detour) {
                if(detour.detourId==that.selectedDetourIndex) {
                  detour.showError=false;
                  detour.value=data.value;
                  detour.label=data.label;
                  detour.selected.path = data.selected.path;
                  detour.selected.startPoint = data.selected.startPoint;
                  detour.selected.endPoint = data.selected.endPoint;
                  detour.selected.streetsTrack = data.selected.streetsTrack;
                }
              });
            }
          });
        }
      });
    },
    validateDetour() {
      this.validateDetourStep=true;
      var that=this;
      this.phases.forEach(function(phase) {
        phase.closing.forEach(function(close) {
          close.detours.forEach(function(detour) {
            if(detour.value==='') {
              detour.showError=true;
              that.validateDetourStep=false;
            } else {
              detour.showError=false;
            }
          });
        });
      });
    },
    inputDetour(params, phaseId) {
      let closingId = params[0];
      let detourId = params[1];
      this.phases.forEach(function(phase){
        if(phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if (close.closingId==closingId) {
              close.detours.forEach(function(detour) {
                if (detour.detourId==detourId) {
                  detour.showError=false;
                }
              });
            }
          });
        }
      });
    },
    addDetour(params, phaseId) {
      let closingId = params[1];
      let selectedDate = this.getCloseDates(phaseId, closingId);
      let that=this;
      let dates=[];
      for (var i=0; i < this.nbClosingDate; i++) {
        dates.push({
          dateId: uuid.v1(),
          sdate: selectedDate[0] || that.sdate,
          edate: selectedDate[1] || that.edate,
          days: [1,2,3,4,5,6,7],
          nbVoice: 1,
          detourSensValue: '1'
        });
      }
      this.phases.forEach(function(e) {
        if (e.phaseId == phaseId) {
          e.closing.forEach(function(close) {
            if (close.closingId == closingId) {
              close.detours.push({
                detourId: uuid.v1(),
                selected: {
                  path : [],
                  startPoint : null,
                  endPoint : null,
                  streetsTrack : []
                },
                value: '',
                showError: false,
                dates: dates
              });   
            }
          });
        }
      });
    },
    removeDetour (params, phaseId) {
      let closingId = params[1];
      let detourId = params[2];
      this.phases.forEach(function(phase) {
        if(phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if(close.closingId == closingId) {
              close.detours.forEach(function(detour, i) {
                if(detour.detourId == detourId) {
                  close.detours.splice(i, 1);
                }
              });
            }
          });
        }
      });
    },
    addDetourDate(params, phaseId) {
      let closingId = params[1];
      let detourId = params[2];
      var that=this;
      let selectedDate = this.getCloseDates(phaseId, closingId);
      this.phases.forEach(function(phase) {
        if(phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if(close.closingId == closingId) {
              close.detours.forEach(function(detour) {
                if(detour.detourId == detourId) {
                  detour.dates.push({
                    dateId: uuid.v1(),
                    sdate: selectedDate[0] || that.sdate,
                    edate: selectedDate[1] || that.edate,
                    days: [1,2,3,4,5,6,7],
                    nbVoice: 1,
                    detourSensValue: '1'
                  });
                }
              });
            }
          });
        }
      });
    },
    removeDetourDate(params, phaseId) {
      let dateId= params[0][1];
      let closingId = params[1];
      let detourId = params[2];
      this.phases.forEach(function(phase) {
        if (phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if (close.closingId==closingId) {
              close.detours.forEach(function(detour) {
                if(detour.detourId==detourId) {
                  detour.dates.forEach(function(date, i){
                    if (date.dateId==dateId) {
                      detour.dates.splice(i, 1);
                    }  
                  });
                }
              });
            }
          });
        }
      });
    },
    getCloseDates(phaseId, closingId) {
      let sdate, edate
      this.phases.forEach(function(phase) {
        if (phase.phaseId==phaseId) {
          phase.closing.forEach(function(close) {
            if (close.closingId==closingId) {
              let dates = close.dates;
              sdate = dates.map(d => { return d.sdate }).sort();
              edate = dates.map(d => { return d.edate }).sort();
            }
          });
        }
      });
      return [sdate[0], edate[edate.length-1]];  
    }
  }
};
</script>
<style>
   div.detour-days-content span {
    margin-right: 5px !important;
    cursor: pointer;
  }
  div.detour-days-content,
  div.detour-date-content {
    padding-left: 0px !important;
  }
  
  div.detour-days-content span.badge-disable {
    background-color: #8e8e8c;
    color: white;
  }
  .detour-date-remove-btn {
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 0.7em;
    text-align: center;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  .detour-date-remove-btn {
    position: relative;
    margin-top: 33px;
  }
  div.detour-dates-container div.row.margin-top-10:only-child div.detour-date-remove-btn.btn-danger {
    display: none;
  }
  .detour-date-add-btn {
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 0.7em;
    text-align: center;
    margin-left: 48%;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
</style>
