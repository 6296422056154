var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"modal",staticClass:"modal fade",class:[
      { 'show d-block': _vm.show },
      { 'd-none': !_vm.show },
      { 'modal-mini': _vm.type === 'mini' },
      { 'modal-custom': _vm.type === 'custom' },
      { 'modal-custom-users': _vm.type === 'custom-users' },
      { 'modal-custom-map': _vm.type === 'custom-map' },
      { 'modal-custom-alert': _vm.type === 'custom-alert' },
      { 'modal-custom-users-import': _vm.type === 'custom-users-import' },
      { 'filters-activated': _vm.advancedSettings }
    ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"modal-dialog",class:[
        { 'modal-notice': _vm.type === 'notice' },
        { 'modal-dialog-centered': _vm.centered },
        _vm.modalClasses
      ],attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",class:[
          _vm.gradient ? ("bg-gradient-" + _vm.gradient) : '',
          _vm.modalContentClasses
        ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})]):_vm._e()]})],2):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e(),(_vm.advancedSettings==true && _vm.$slots.filters)?_c('div',{staticClass:"modal-filters-container"},[_vm._t("filters")],2):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }