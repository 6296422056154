<template>
  <div>
    <modal :show.sync="importUsers" headerClasses="justify-content-center" type="custom-users-import" :scrollToBottom="false" v-on:hideModal="hideModal">
        <h4 slot="header" class="title title-up"> {{ $t('usersPage.importTitle') }}</h4>
        <template slot="close-button">
            <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
        </template>
         <div v-if="loading" class="loading-form">
          <img src="../../../public/img/loading.gif" />
        </div>
        <div v-else class="col-md-12 import-users-container">
            <div class="col-md-12 col-sm-12">
                <image-upload  @change="onImageChange" v-model="users.file" select-text="Select Image" />
            </div> 
        </div>
        <template slot="footer">
            <base-button @click='save()'>{{ $t('usersPage.saveBtn') }}</base-button>
        </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

import { BaseCheckbox, BaseRadio } from 'src/components/index';
import { Modal } from 'src/components';

import Vue from 'vue';
import { ImageUpload } from 'src/components/index';

export default {
    name:'import-users',
    props: ['importUsers'],
    components: {
        Modal,
        BaseRadio,
        BaseCheckbox,
        ImageUpload
    },
    data() {
        return {
            users: {
                file: ''
            },
            errors: {
                import_invalid_login: { 
                    fr: "Nom d'utilisateur invalide",
                    en: "Invalid user name"
                },
                import_invalid_password:{
                    fr: "Mot de passe invalide",
                    en: "Invalid password"
                },
                import_invalid_name: {
                    fr: "Nom invalide",
                    en: "Invalid Name"
                },
                import_invalid_email:{
                    fr: "Email invalide",
                    en: "Invalid Email"
                },
                import_invalid_lang: {
                    fr: "Langue invalide",
                    en: "Invalid Language"
                },
                import_user_exists: {
                    fr: "Le nom d'utilisateur existe déjà dans les utilisateurs système",
                    en: "The user name already exists in the system"
                }
            },
            loading: false
        };
    },
    methods: {
        hideModal() {
            this.$emit('hideImportUsers');
            this.loading=false;
            this.users.file='';   
            if (document.querySelector('div.import-users-container button.btn.btn-round.btn-danger')) {
                document.querySelector('div.import-users-container button.btn.btn-round.btn-danger').click(); 
            }        
        },
        save() {
            let _this=this;
            //e.preventDefault();
            if (!this.users.file) return;
            this.loading=true;
            let fd = new FormData();
            fd.append('users', this.users.file);
            axios({
                method: 'post',
                url: 'user/import',
                headers: this.getHeaders(),
                data: fd
            }).then( (data) => {
                if (data.data.success) {
                    _this.hideModal();
                    _this.$notify({
                        message: _this.$i18n.t('usersPage.textMsgSuc'),
                        timeout: 10000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'success'
                    });
                    window.location.reload();
                } else {
                    this.loading=false;
                    let error = _this.$i18n.t('serverReply.errorImportUsers');
                    if (data.data.error && _this.errors[data.data.error]) {
                        error = _this.errors[data.data.error][_this.$root.$i18n.locale];
                        if (data.data.line) {
                            error += ' ' + _this.$i18n.t('usersPage.line') + ' ' + data.data.line; 
                        }
                    }
                    _this.$notify({
                        message: error,
                        timeout: 10000,
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
                }                        
            })
            .catch( () =>  {
                _this.hideModal();
                _this.$notify({
                    message: _this.$i18n.t('serverReply.errorImportUsers'),
                    timeout: 10000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                });
            });
        },
        getHeaders() {
            return {
                'Filesize': (this.users.file && this.users.file.size) || 0
            };
        },
        onImageChange(file) {
            this.users.file=file;
        }
    },
    watch: {
        'importUsers'(val) {
            if (val)  {}
        }
    }
}
</script>

<style>
    .modal.show.modal-custom-users-import .modal-dialog {
        padding-top: 30vh;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
    }
    .modal-open .modal.show.modal-custom-users-import {
        /*overflow-x: hidden;*/
        overflow-y: hidden !important;
    }
    div.import-users-container {
    }
    
    .modal.show.modal-custom-users-import div.modal-body {
        min-height: 120px;
    }
    .modal.show.modal-custom-users-import div.modal-footer {
        display: block;
    }
    .modal.show.modal-custom-users-import div.modal-footer button.btn.btn-default {
        margin-right: 10px;
        margin-bottom: 15px;
        float: right;
    }
    div.import-users-container {
        text-align: center;
        padding-top: 20px;
    }
    div.loading-form {
        position: absolute;
        margin-left: 50%;
        margin-right: -20px;
        top: 50%;
    }
</style>