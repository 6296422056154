import icons from './../shared/icons';

export default {
  polylineMidPoint(entrave, Point, Polyline, GeometryEngine, webMercatorUtils){
    var polyline = this.updateSimpleLine(entrave, Point, Polyline, GeometryEngine);
    var midPoint;
    if(polyline.type === "polyline" && (polyline.paths.length > 0)){
      let coordinates = this.convertedPolylinePath(polyline, webMercatorUtils);
      let lineString = turf.lineString(coordinates);
      let toMidDistance = turf.length(lineString, {units : "meters"}) / 2;
      let along = turf.along(lineString, toMidDistance, {units : "meters"});
      midPoint = new Point({
        longitude : along.geometry.coordinates[0],
        latitude  : along.geometry.coordinates[1],
        spatialReference : polyline.spatialReference
      });
    }else{
      midPoint = new Point({
        latitude  : 0,
        longitude : 0,
        spatialReference : polyline.spatialReference
      });
    }
    return midPoint;
  },
  polylinePoints(entrave, Point, Polyline, GeometryEngine, webMercatorUtils){
    var polyline = this.updateSimpleLine(entrave, Point, Polyline, GeometryEngine);
    var points = [];
    var point;
    if(polyline.type === "polyline" && (polyline.paths.length > 0)){
      let coordinates = this.convertedPolylinePath(polyline, webMercatorUtils);
      let lineString = turf.lineString(coordinates);
      let length = turf.length(lineString, {units : "meters"});
      let step = length / 20;
      let distance = 0;
      while ( distance < length) {
        let along = turf.along(lineString, distance, {units : "meters"});
        distance = distance + step;
        point = new Point({
          longitude : along.geometry.coordinates[0],
          latitude  : along.geometry.coordinates[1],
          spatialReference : polyline.spatialReference
        });
        points.push(point);
      }
      
    }
    return points;
  },
  /*updateSimpleLine(entrave, Point, Polyline, GeometryEngine){
    var path = [];    
    entrave.selected.path.forEach(function(object){
      var toAdd = object.geometry.paths.flat();
      if (path.length === 0){
        path = [toAdd];
      }else{
        let latest = path[path.length - 1];
        let lep = latest[latest.length - 1];
        let endP = new Point({
          x : lep[0],
          y : lep[1],
          spatialReference : entrave.selected.path[0].geometry.spatialReference
        });
        let asp = toAdd[0], aep = toAdd[toAdd.length - 1];
        if ((lep[0] === asp[0]) && (lep[1] === asp[1])){
          toAdd.shift();
          path.push(toAdd);
        }else if ((lep[0] === aep[0]) && (lep[1] === aep[1])){
          toAdd.reverse();
          toAdd.shift();
        }else{
          let asPoint = new Point({
            x : asp[0],
            y : asp[1],
            spatialReference : object.geometry.spatialReference
          });
          let aePoint = new Point({
            x : aep[0],
            y : aep[1],
            spatialReference : object.geometry.spatialReference
          });
          let startD = GeometryEngine.distance(asPoint, endP, "meters");
          let endD   = GeometryEngine.distance(aePoint, endP, "meters");
          
          if (startD < endD) {// to Add start point is the nearest to the
            //toAdd.shift();
            path.push(toAdd);
          }else{ // to Add end point is the nearest to the
            toAdd.reverse();
            //toAdd.shift();
            path.push(toAdd);
          }
        }
      }
    });
    let sp = new Polyline({
      paths : [path.flat()],
      spatialReference : entrave.selected.path[0].geometry.spatialReference
    });
    return sp;
  },*/
  //updateSimpleLine(){
  updateSimpleLine(entrave, Point, Polyline, geometryEngine){
    var path = [];
    
    entrave.selected.path.forEach(function(object){
      var toAdd = object.geometry.paths.flat();
      if (path.length === 0) {
        path = [toAdd];
      } else {
        let latest = path[path.length - 1];
        let lep = latest[latest.length - 1];
        let endP = new Point({
          x : lep[0],
          y : lep[1],
          spatialReference : entrave.selected.path[0].geometry.spatialReference
        });
        let asp = toAdd[0], aep = toAdd[toAdd.length - 1];
        if (path.length === 1) {
          let firstS = path[0];
          let firstP = firstS[0];
          if((asp[0] === firstP[0] && asp[1] === firstP[1]) || (aep[0] === firstP[0] && aep[1] === firstP[1])) {
            path = [path[0].reverse()];
          }
        }
        if ((lep[0] === asp[0]) && (lep[1] === asp[1])){
          toAdd.shift();
          path.push(toAdd);
        } else if ((lep[0] === aep[0]) && (lep[1] === aep[1])){
          toAdd.reverse();
          toAdd.shift();
          path.push(toAdd);
        } else {
          let asPoint = new Point({
            x : asp[0],
            y : asp[1],
            spatialReference : object.geometry.spatialReference
          });
          let aePoint = new Point({
            x : aep[0],
            y : aep[1],
            spatialReference : object.geometry.spatialReference
          });
          let startD = geometryEngine.distance(asPoint, endP, "meters");
          let endD   = geometryEngine.distance(aePoint, endP, "meters");
          
          if (startD < endD) {// to Add start point is the nearest to the
            //toAdd.shift();
            path.push(toAdd);
          }else{ // to Add end point is the nearest to the
            toAdd.reverse();
            //toAdd.shift();
            path.push(toAdd);
          }
        }
      }
    });
    var sp = new Polyline({
      paths : [path.flat()],
      spatialReference : entrave.selected.path[0].geometry.spatialReference
    });
    return sp;
  },
  convertedPolylinePath(polyline, webMercatorUtils){
    if (polyline.type && (polyline.type === "polyline")){
      let converted = webMercatorUtils.webMercatorToGeographic(polyline);
      let line =  turf.lineString(converted.paths.flat());
      //return turf.cleanCoords(line).geometry.coordinates;
      return line.geometry.coordinates;
    }
  },
  repeatedPoints(polyline, PointConst, geometryEngine, webMercatorUtils, options){
    let _this = this;
    if (polyline.type && polyline.type === "polyline"){
      let basePercent, units;
      if(options.percent){basePercent = options.percent;}else{basePercent = 20;}
      if(options.units){units = options.units;}else{units = "meters";}
      let fullD = geometryEngine.geodesicLength(polyline, units);
      var at = 0, points = [];
      while(at < fullD){
        var point = _this.pointAt(polyline, PointConst, webMercatorUtils, at, units);
        points.push(point);
        at += (fullD * basePercent) / 100;
      }
      return points;
    }else{
      return [];
    }
  },
  repetitionPercent(polyline, geometryEngine, stepDistance, units){
    if (polyline.type && polyline.type === "polyline"){
      let step  = stepDistance || 20;
      let baseUnits =  units|| "meters";
      let d = geometryEngine.geodesicLength(polyline, baseUnits);
      if(d > (2*step)){
        return Math.floor(((step / d) * 100));
      }else{
        return 50;
      }
    }
  },
  drawRepeated(polyline, color, type, PolylineConst, PointConst, Graphic, geometryEngine, webMercatorUtils, geodesicUtils, detourSensValue="1"){
    let _this = this;
    let percent = _this.repetitionPercent(polyline, geometryEngine, 50, 'meters');
    let points = _this.repeatedPoints(polyline, PointConst, geometryEngine, webMercatorUtils,{percent : percent, units : "meters"});
    let col = color || "red";
    let g,g1, graphs=[];
    let path = type=='detour'?icons.arrowDetour:icons.pointClosing;
    if(detourSensValue == "3"){
      var angles = _this.bearingAt(polyline, points, PolylineConst, geometryEngine, webMercatorUtils, geodesicUtils);
      var anglesRev = _this.bearingAtRev(polyline, points, PolylineConst, geometryEngine, webMercatorUtils, geodesicUtils);  
    }
    else if(detourSensValue == "2"){
      var anglesRev = _this.bearingAtRev(polyline, points, PolylineConst, geometryEngine, webMercatorUtils, geodesicUtils);  
    }
    else{
      var angles = _this.bearingAt(polyline, points, PolylineConst, geometryEngine, webMercatorUtils, geodesicUtils);
    }
    if(angles){
      angles.forEach(function(a){
        let p   = a.point;
        let ang = a.angle;
        g = new Graphic({
          geometry : p,
          symbol : {
            type: "simple-marker",
            style : "path",
            path: path,
            size: 6,
            color: col,
            angle : ang,
            outline: {
              color: col,
              width: 1
            },
            xoffset : (Math.sign(ang) == -1)?"-4px":"0px",
            yoffset : (Math.sign(ang) == -1)?"0px":"4px",
          }
        });
        graphs.push(g);
      });
    }
    if(anglesRev){
      anglesRev.forEach(function(a){
        let p   = a.point;
        let ang = a.angle;
        g1 = new Graphic({
          geometry : p,
          symbol : {
            type: "simple-marker",
            style : "path",
            path: path,
            size: 6,
            color: col,
            angle : ang,
            outline: {
              color: col,
              width: 1
            },
            xoffset : (Math.sign(ang) == -1)?"0px":"4px",
            yoffset : (Math.sign(ang) == -1)?"-4px":"0px",
          }
        });
        graphs.push(g1);
      });
    }
    return graphs;
  },
  /*
  bearingAt(polyline, point, Polyline, geometryEngine, webMercatorUtils, geodesicUtils){
    let _this = this;
    var angle = 0;
    if (polyline.type && polyline.type === "polyline" && point.type && point.type === "point"){
      let flattened = polyline.paths.flat();
      let spatialRef = polyline.spatialReference;
      let i = 0, paths, segment, ep;
      do {
        paths = [[flattened[i], flattened[i + 1]]];
        segment = new Polyline({
          paths : paths,
          spatialReference : spatialRef
        });
        if (geometryEngine.intersects(segment, polyline)){
          ep = polyline.getPoint(0, 1);
          angle = _this.computeAngle(point, ep, webMercatorUtils, geodesicUtils);
          i = flattened.length;
        } else { i++; }
      } while (i < flattened.length - 1);
    } else { angle = 0; }
    return angle;
  },
  */
  bearingAt(polyline, points, Polyline, geometryEngine, webMercatorUtils, geodesicUtils){
    let _this = this;
    let time0 = Date.now();
    let time1;
    var angle, angles = [];
    if (polyline.type && polyline.type === "polyline"){
      let flattened = polyline.paths.flat();
      let possible = null;
      let spatialRef = polyline.spatialReference;
      let i, paths, segment, ep, m = 0;
      let p = 0; // points index
      time1 = Date.now();
      //console.log("++ 1", time1 - time0);
      do{
        possible = null;
        i = m; // restart from the latest good position
        do{
          paths = [[flattened[i], flattened[i + 1]]];
          segment = new Polyline({
            paths : paths,
            spatialReference : spatialRef
          });
          ep = segment.getPoint(0, 1);
          if (geometryEngine.contains(segment, points[p])){
          //if(turf.booleanPointOnLine(tPoint, tSeg)){
            angle = _this.computeAngle(points[p], ep, webMercatorUtils, geodesicUtils);
            angles.push({point: points[p], angle: angle});
            m = i; // save last
            p++;
            i = flattened.length;
          }else{
            let d = geometryEngine.distance(points[p], segment);
            if(!possible){possible = {segment : segment, ep : ep, distance : d};}
            else{
              if (possible.distance > d){possible = {segment : segment, ep : ep, distance : d};}
            }
            i++;
          }
        } while (i < flattened.length - 1);
        //console.log("++ 2", Date.now() - time1);
        if (i === flattened.length - 1){
          angle = _this.computeAngle(points[p], possible.ep, webMercatorUtils, geodesicUtils);
          angles.push({point: points[p], angle: angle});
          p++;
        }
      } while (p < points.length )
    }else{
      points.forEach((p) => {angles.push({point: p, angle: 0})});
    }
    return angles;
  },
  bearingAtRev(polyline, points, Polyline, geometryEngine, webMercatorUtils, geodesicUtils){
    let _this = this;
    let time0 = Date.now();
    let time1;
    var angle, angles = [];
    if (polyline.type && polyline.type === "polyline"){
      let flattened = polyline.paths.flat();
      let possible = null;
      let spatialRef = polyline.spatialReference;
      let i, paths, segment, ep, m = 0;
      let p = 0; // points index
      time1 = Date.now();
      //console.log("++ 1", time1 - time0);
      do{
        possible = null;
        i = m; // restart from the latest good position
        do{
          paths = [[flattened[i], flattened[i + 1]]];
          // console.log("flattened",paths)
          segment = new Polyline({
            paths : paths,
            spatialReference : spatialRef
          });
          ep = segment.getPoint(0, 1);
          if (geometryEngine.contains(segment, points[p])){
          //if(turf.booleanPointOnLine(tPoint, tSeg)){
            angle = _this.computeAngleRev(points[p], ep, webMercatorUtils, geodesicUtils);
            angles.push({point: points[p], angle: angle});
            m = i; // save last
            p++;
            i = flattened.length;
          }else{
            let d = geometryEngine.distance(points[p], segment);
            if(!possible){possible = {segment : segment, ep : ep, distance : d};}
            else{
              if (possible.distance > d){possible = {segment : segment, ep : ep, distance : d};}
            }
            i++;
          }
        } while (i < flattened.length - 1);
        //console.log("++ 2", Date.now() - time1);
        if (i === flattened.length - 1){
          angle = _this.computeAngleRev(points[p], possible.ep, webMercatorUtils, geodesicUtils);
          angles.push({point: points[p], angle: angle});
          p++;
        }
      } while (p < points.length )
    }else{
      points.forEach((p) => {angles.push({point: p, angle: 0})});
    }
    return angles;
  },
  pointAt(polyline, PointConst, webMercatorUtils, distance, units){
    let _this = this;
    if (!units){units = "meters";}
    var point;
    if(polyline.type === "polyline" && (polyline.paths.length > 0)){
      let coordinates = _this.convertedPolylinePath(polyline, webMercatorUtils);
      let lineString = turf.lineString(coordinates);

      let along = turf.along(lineString, distance, {units : units});
      point = new PointConst({
        longitude : along.geometry.coordinates[0],
        latitude  : along.geometry.coordinates[1],
        spatialReference : polyline.spatialReference
      });
    }else{
      point = new PointConst({
        latitude  : 0,
        longitude : 0,
        spatialReference : polyline.spatialReference
      });
    }
    return point;
  },
  computeAngle(pointA, pointB, webMercatorUtils, geodesicUtils){
    var A = webMercatorUtils.webMercatorToGeographic(pointA);
    var B = webMercatorUtils.webMercatorToGeographic(pointB);
    let geoRes = geodesicUtils.geodesicDistance(A,B);
    return geoRes.azimuth;
  },

  computeAngleRev(pointA, pointB, webMercatorUtils, geodesicUtils){
    var A = webMercatorUtils.webMercatorToGeographic(pointA);
    var B = webMercatorUtils.webMercatorToGeographic(pointB);
    let geoRes = geodesicUtils.geodesicDistance(B,A);
    return geoRes.azimuth;
  },

  hexToRgb (hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16));
  },
  rgbaToHex(r,g,b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
  
    return "#" + r + g + b;
  },
  rgba2hex(orig) {
    var a, isPercent,
      rgb = ('rgba(' + orig.join() +')').replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
      (rgb[1] | 1 << 8).toString(16).slice(1) +
      (rgb[2] | 1 << 8).toString(16).slice(1) +
      (rgb[3] | 1 << 8).toString(16).slice(1) : orig;
  
    if (alpha !== "") {
      a = alpha;
    } else {
      a = '01';
    }
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    hex = hex + a;
  
    return hex;
  }
};