<template>
  <div class="phases-tree-content">
    <tree refs='tree' :data="data" :options="{ checkbox: true, checkOnSelect: true, autoCheckChildren:false }" @node:checked="checkedNode" @node:unchecked="unCheckedNode" />
  </div>
</template>

<script>

import LiquorTree from 'liquor-tree';

export default {
  name: 'phases-details',
  components: {
    [LiquorTree.name]: LiquorTree
  },
  props: ['entravesTab', 'phasesTab', 'display', 'selectedColor'],
  watch: {
    /*'display.entraves'(val) {
      console.log("change...", val);
      //console.log(this.data[0]);
      //this.data[0].state.checked(val);
      let  node = this.$refs.tree.find({
        data: { type: 'entraves' }
      });
      console.log(node);
    }*/
  },
  created() {
  },
  mounted () {
    this.constEntravesTree();
    if (this.phasesTab.length) {
      this.constPhasesTree(); 
    }
  }, 
  data() {
    return {
      data: [],
      from: false
    };
  },
  methods: {
    checkedNode(event) {
      //if ((event.data.type=='entraves') || (event.data.type=='phases') || (event.data.type=='phase') || (event.data.type=='closing')) {
      if ((event.data.type=='entraves') || (event.data.type=='phases') || (event.data.type=='phase') ) {
        this.from = true;
        event.children.forEach((node)  => {
          node.check();
        });
      } else if ((event.data.type=='closing') && this.from) {
        event.children.forEach((node)  => {
          node.check();
        });
        this.$emit('check', [event.data.type, event.data.uuid, this.selectedColor]);
      } else if ((event.data.type=='detour') && this.from) {
        this.$emit('check', [event.data.type, event.data.uuid, this.selectedColor]);
      } else {
        this.from = false;
        this.$emit('check', [event.data.type, event.data.uuid, this.selectedColor]);
      }
    },
    unCheckedNode(event) {
      this.from = false;
      if ((event.data.type=='entraves') || (event.data.type=='phases') || (event.data.type=='phase')) {
        event.children.forEach((node)  => {
          node.uncheck();
          
          if(event.data.type=='phase') {
            node.children.forEach((detour) => {
              detour.uncheck();
            });
            let checkedChild=false;
            event.parent.children.forEach((child) => {
              if (child.checked()) {
                checkedChild=true;
                return;
              }
            });
            if (!checkedChild) {
              event.parent.uncheck();
            }
          }
          
        });
      } else if (event.data.type=='entrave') {
        //event.parent.uncheck();
        this.$emit('uncheck', [event.data.type, event.data.uuid]);
        let checkedChild=false;
        event.parent.children.forEach((child) => {
          if (child.checked()) {
            checkedChild=true;
            return;
          }
        });
        if (!checkedChild) {
          event.parent.uncheck();
        }
      } else if (event.data.type=='closing') {
        this.$emit('uncheck', [event.data.type, event.data.uuid]);
        let checkedChild=false;
        event.children.forEach((detour) => {
          if (detour.checked()) {
            checkedChild=true;
            return;
          }
        });
        event.parent.children.forEach((close) => {
          if (close.checked()) {
            checkedChild=true;
            return;
          }
          close.children.forEach((detour) => {
            if (detour.checked()) {
              checkedChild=true;
              return;
            }
          });
        });
        if (!checkedChild) {
          event.parent.uncheck();
        }
      } else if (event.data.type=='detour') {
        this.$emit('uncheck', [event.data.type, event.data.uuid]);
        let checkedChild=false;
        let phase = event.parent.parent;
        phase.children.forEach((close) => {
          if (close.checked()) {
            checkedChild=true;
            return;
          }
          close.children.forEach((detour) => {
            if (detour.checked()) {
              checkedChild=true;
              return;
            }
          });
        });
        if (!checkedChild) {
          phase.uncheck();
        }
      }
    },
    constEntravesTree() {
      let _this=this;
      let children=[];
      this.entravesTab.entraves.forEach((entrave) => {
        let child = _this.constEntravesChild(entrave.type, entrave.value);
        let data = {uuid: entrave.entraveId, type: 'entrave' };
        children.push({text: child, data: data, state: { checked: _this.display.entraves}});
      });
      let obj = {
        text: ' <i class="fas fa-map"></i> ' + _this.$i18n.t('mapPage.entraves'),
        state: {checked: _this.display.entraves},
        data: {type: 'entraves'},
        children: children
      };
      this.data.push(obj);
    },
    constEntravesChild(type, value) {
      let text='';
      if (type=='ponctuel') {
        text += '<svg class="ponctuel-icon" viewBox="-5 3 13 13" width="18px" height="18px"> <path d="M 0.70382781,4.444257 l 0,1.401381 C 2.0157222,6.113771 3.0467758,7.12323 3.3520839,8.42071 l 1.413127,0 C 4.4201601,6.367387 2.771468,4.747877 0.70382781,4.444257 Z m -1.5956401,0.02984 C -2.8678049,4.847848 -4.4241464,6.431669 -4.7580298,8.42073 l 1.4140291,0 C -3.05382,7.19004 -2.1091678,6.220907 -0.89181229,5.896257 Z M -4.7580298,10.01637 c 0.3338834,1.988932 1.8902249,3.572114 3.86621751,3.945727 l 0,-1.421255 C -2.1092048,12.216384 -3.0538292,11.247116 -3.3440007,10.016368 Z m 8.1101137,0 c -0.3053081,1.297313 -1.3363617,2.306171 -2.64825609,2.574165 l 0,1.401381 C 2.771468,13.688422 4.4201601,12.069563 4.7652109,10.01637 Z"/></svg>';
      } else {
        text +=  '<svg class="linear-icon" viewBox="-8 3 16 13" width="24px" height="24px"> <path d="M -4.1935256,7.555372 l -0.228604,0.854782 -2.029425,0 0,1.614683 1.596612,0 -0.229505,0.855683 1.27223,0 0.229506,-0.855683 0.500579,0 0.432812,-1.614683 -0.500578,0 0.228603,-0.854782 z m 8.013789,0 -0.228608,0.854782 -0.501481,0 -0.432813,1.614683 0.501486,0 -0.229511,0.855683 1.272231,0 0.22951,-0.855683 2.028519,0 0,-1.614683 -1.595711,0 0.228609,-0.854782 z m -5.197353,0.854782 -0.432813,1.614683 0.96140401,0 0.432813,-1.614683 z m 2.23363401,0 -0.432812,1.614683 0.96139899,0 0.432813,-1.614683 z"/></svg>';
      }
      text += '<span>' + value + '</span>';
      return text;
    },
    constPhasesTree() {
      let _this=this;
      let children=[];
      this.phasesTab.forEach((phase, index) => {
        let text = _this.constPhasesText(phase, index);
        let child = _this.constPhasesChild(phase);
        let data = {uuid: phase.phaseId, type: 'phase'};
        children.push({text: text, children: child, data: data, state: { checked: ((_this.display.closing) || (_this.display.detours))}});
      });
      let obj = {
        text: ' <i class="fas fa-map-signs"></i> ' + _this.$i18n.t('mapPage.phases'),
        data: {type: 'phases'},
        state: { checked: ((_this.display.closing) || (_this.display.detours))},
        children: children
      };
      this.data.push(obj);
    },
    constPhasesText(phase, index) {
      let text='<i class="fas fa-map-signs"></i> ' + this.$i18n.t('mapPage.phase') + (index+1);
      return text;
    },
    constPhasesChild(phase) {
      let _this=this;
      let phaseChild=[];
      phase.closing.forEach((close)  => {
        let text = '<i class="fas fa-exclamation-triangle"></i> ' + close.value;
        let child =  _this.constClosingChild(close, phase.phaseId);
        let data = {uuid: close.closingId, phaseId: phase.phaseId, type: 'closing'};
        phaseChild.push({text: text, children: child, data: data, state: { checked: _this.display.closing }});
      });
      return phaseChild;
    },
    constClosingChild(close, phaseId) {
      let closeChild=[];
      let _this=this;
      close.detours.forEach((detour) => {
        let text = '<i class="fas fa-recycle"></i> ' + detour.value;
        let data = {uuid: detour.detourId, phaseId: phaseId, closeId: close.closingId, type: 'detour'};
        closeChild.push({text: text, child: [], data: data, state: { checked: _this.display.detours }});
      });
      return closeChild;
    }
  }
};
</script>
<style>
  body:not(.white-content) ul.tree-root li.tree-node {
    background-color: #27293d; /*  #222a42    #27293d   #191f31  */ /*  #1e1e2d  #27293d  #25273a*/
  }
  body:not(.white-content) ul.tree-root li.tree-node:not(.selected) > .tree-content:hover,
  body:not(.white-content) ul.tree-root li.tree-node.checked  div.tree-content {
    background-color: #25273a !important;
  }
  body:not(.white-content) ul.tree-root li.tree-node span.tree-anchor {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .tree-checkbox {
    border: 1px solid #818181 !important
  }
  .tree-checkbox.indeterminate,
  .tree-checkbox.checked {
    background-color: #e14eca !important;
    border-color: #e14eca !important;
  }
  body:not(.white-content) i.tree-checkbox {
    background: #27293d;
  }
  body:not(.white-content) ul.tree-root li.tree-node i.icons-tree {
    color: hsla(0,0%,100%,.5) !important
  }
  i.icons-tree {
    width: 16px;
    height: 16px;
    background: url("../../../public/img/tree_icons.png") no-repeat 0 0;
  } 
  i.icon-entrave {
    background-position: 0 0;
  }
  
  .ponctuel path {
    fill: red;
  }
  body.white-content svg.linear-icon path,
  body.white-content svg.ponctuel-icon path {
    fill: #343434;
  }
  body:not(.white-content) svg.linear-icon path,
  body:not(.white-content) svg.ponctuel-icon path {
    fill: #93949e;
  }
</style>
