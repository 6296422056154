<template>
<div class="content">
    <div class="row mt-3">
        <div class="col-12">
            <card class="users-card" card-body-classes="table-full-width">
                    <collapse class="faq-collapse" :multiple-active="false" >
                        <FAQComponent v-for="(src,index) in url" :key="index" :uniqueKey="index" v-bind:url="src.url" v-bind:label="src.label"  :isClose="src.isClose">  </FAQComponent>
                    </collapse>
            </card>
        </div>
    </div>
</div>
</template>
<script>
import FAQComponent from './FAQComponent.vue';
import { Collapse } from "src/components";

export default {
    components: {
        FAQComponent,
        Collapse,
    },
    data(){
        return{
            url:[{label:'faq.label1',url:'s3://opa/tutoriel_creation_pdf.mov',isClose:false},
                {label:'faq.label2',url:'s3://opa/tutoriel_creation_projet.mov',isClose:true},
                {label:'faq.label3',url:'s3://opa/tutoriel_rappel_projet.mov',isClose:true},
                {label:'faq.label4',url:'s3://opa/tutoriel_saisie_rapide.mov',isClose:true},
            ]
        }
    }


};
</script>