<template>
  <div>
    <collapse :multiple-active="false" :active-index="0" class="closing-content" >
      <collapse-item v-bind:title="calculeTitle">
        <div class="closing-remove-btn btn-danger" v-if="!statusOfUser" v-on:click="$emit('remove', [index, item.closingId])">
          <span><i class="tim-icons icon-simple-remove"></i></span>
        </div>
        <div class="row closing-wrapper">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <base-checkbox v-model="item.publish" :checked="item.publish" :disabled="statusOfUser">  {{ $t('projectForm.labelProjectPublish') }}</base-checkbox>
          </div>
          <p>{{instance.city}}</p>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <base-checkbox v-model="item.publishWaze" :checked="item.publishWaze" :disabled="statusOfUser">  {{ $t('projectForm.labelProjectPublishWaze') }}</base-checkbox>
          </div>
          <div v-if='filters.workType1.show' class="col-sm-8 col-md-8 col-lg-8 margin-top-10">
            <label> {{ $t('projectForm.labelClosingDelimitation')}} *</label>
            <base-input
              v-model="item.value"
              @input="$emit('input', item.closingId)"
              @click.native="$emit('showModal', item)"
              v-on:keydown="keyDown"
              v-bind:class="[item.showError?'has-danger':'']"
              v-bind:placeholder="$t('projectForm.placeholderProjectEntrave')"
              :disabled="statusOfUser"
              addon-left-icon="tim-icons icon-map-big">
            </base-input>
            <label v-show="item.showError" class="error">{{ $t('projectForm.requiredField') }}</label>
          </div>
          <div v-else class="col-sm-12 col-md-12 col-lg-12  margin-top-10">
            <label> {{ $t('projectForm.labelClosingDelimitation')}} *</label>
            <base-input
              v-model="item.value"
              @input="$emit('input', item.closingId)"
              @click.native="$emit('showModal', item)"
              v-on:keydown="keyDown"
              v-bind:class="[item.showError?'has-danger':'']"
              :disabled="statusOfUser"
              v-bind:placeholder="$t('projectForm.placeholderProjectEntrave')"
              addon-left-icon="tim-icons icon-map-big">
            </base-input>
            <label v-show="item.showError" class="error">{{ $t('projectForm.requiredField') }}</label>
          </div>
          <div v-if='filters.workType1.show' class="col-sm-4 col-md-4 col-lg-4  margin-top-10">
            <label>{{ filters.workType1.title.label }}</label>
            <el-select multiple
              class="select-primary workertype-closing"
              name="workType1"
              @input="changeWorkType($event)"
              v-bind:placeholder="$t('projectForm.placeholderProjectworkType1')"
              :disabled="statusOfUser"
              v-model="item.workType1">
              <el-option
                v-for="option in filters.workType1.value"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="closing-dates-container">
          <closing-date-component v-for="(item1, index) in item.dates" v-bind:filters='filters'  v-bind:key="item1.dateId" v-bind:index="index" v-bind:item="item1" v-bind:pdate="date" v-bind:indexPhase="indexPhase"
                                  v-on:updateClosingDate="updateClosingDate(item1, $event)" :statusOfUser="statusOfUser"
                                  v-on:removeClosingDate="removeClosingDate($event)" >
          </closing-date-component>
        </div>
        <div class="row margin-top-10">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="closing-date-add-btn" v-if="!statusOfUser" v-on:click="addClosingDate" ><span><i class="tim-icons icon-simple-add"></i></span></div>
          </div>
        </div>
        <div class="row margin-top-10">
          <div v-if="filters.trafficImpact.show || filters.portableWater.show" class="col-sm-12 col-md-12 col-lg-12 ">
            <h4 class="impact-title">{{ $t('projectForm.impact') }}<span class="show-impact" @click="toggleTraffic($event)"><i class="tim-icons icon-simple-add impact-show"></i><i class="tim-icons icon-simple-delete impact-hide hide"></i></span></h4>
          </div>

          <div v-if="filters.trafficImpact.show" class="col-sm-12 col-md-12 col-lg-12 margin-top-20 traffic-impact hide">
            <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex;">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <label style="margin-left: -15px;">{{ filters.trafficImpact.title.label }}</label>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <label>{{ filters.trafficImpact.title1.label }}</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row" v-for="(item2,index2) in item.trafficImpact.value" v-bind:index="index2" v-bind:item="item2" v-bind:key="index2">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <base-checkbox v-model="item2.status" @input="check('trafficImpact', item2.value, $event)" :checked="item2.status" :disabled="statusOfUser"> {{ formatLabel(item2) }} </base-checkbox>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <base-input v-model="item2.content" :disabled="!item2.status" ></base-input>
                </div>
              </div>
            </div>
          </div>

          <div v-if="filters.portableWater.show" class="col-sm-12 col-md-12 col-lg-12 margin-top-20 portable-water hide">
            <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex;">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <label style="margin-left: -15px;">{{ filters.portableWater.title.label }}</label>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <label>{{ filters.trafficImpact.title1.label }}</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row" v-for="(item3,index3) in item.portableWater.value" v-bind:index="index3" v-bind:item="item3" v-bind:key="index3">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <base-checkbox v-model="item3.status" @input="check('portableWater', item3.value, $event)" :checked="item3.status" :disabled="statusOfUser"> {{ formatLabel(item3) }} </base-checkbox>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                  <base-input v-model="item3.content" :disabled="!item3.status" ></base-input>
                </div>
              </div>
            </div>
          </div>

        </div>
      </collapse-item>
    </collapse>
  </div>
</template>
<script>
import { Collapse, CollapseItem } from 'src/components';

import ClosingDateComponent from './ClosingDateComponent.vue';
import tags from './../shared/filters';
import { Select, Option } from 'element-ui';
import { BaseCheckbox} from 'src/components/index';

export default {
  name: 'closing-component',
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    BaseCheckbox,
    Collapse,
    CollapseItem,
    ClosingDateComponent
  },
  props: ['item', 'index', 'date','statusOfUser', 'indexPhase'],
  computed: {
    calculeTitle: function() {
      return this.$i18n.t('projectForm.closingItemTitle') + (parseInt(this.index,10) + 1);
    }
  },
  created() {
    let  instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city = instance.city;
    console.log(instance);
    this.filters = tags[instance.city];
  },
  data () {
    return {
      instance: '',
      city: '',
      filters: [],
      model: {}
    };
  },
  methods: {
    toggleTraffic(event) {
      let node = event.target.parentNode.parentNode.parentNode.parentNode;
      let traffic = node.querySelector('.traffic-impact');
      let pwater  = node.querySelector('.portable-water');
      traffic.classList.toggle("hide");
      pwater.classList.toggle("hide");
      document.querySelector('i.impact-show').classList.toggle("hide");
      document.querySelector('i.impact-hide').classList.toggle("hide");
    },
    formatLabel(item) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      if (user.lang=='fr'){
        return item.label_fr;
      } else {
        return item.label_en;
      }
    },
    addClosingDate() {
      this.$emit('addClosingDate', [this.index, this.item.closingId]);
    },
    removeClosingDate(params) {
      this.$emit('removeClosingDate', [params[0], params[1], this.item.closingId]);
    },
    updateClosingDate(item, params) {
      item.dates=params[1];
      this.$emit('updateClosingDate', [params[0], item, this.item.closingId]);
    },
    keyDown(event) {
      if (event.key!=='Enter') {
        event.preventDefault();
      }
    },
    changeWorkType(event) {
      //this.$emit('updateWorkType', [event]);
    },
    check(type, status, target) {
      let filter;
      if (type==='passanger') {
        filter=this.item.passanger.value;
      } else if (type==='portableWater') {
        filter=this.item.portableWater.value;
      } else if (type==='trafficImpact') {
        filter=this.item.trafficImpact.value;
      }
      filter.forEach(elem => {
        if (elem.value == status && !target) { 
          elem.content='';
        }
      });
    }
  }
}
</script>
<style>
  div.closing-content div.card-header a {
    font-weight: 700 !important;
  }
  div.closing-content div.card-body {
    padding-top: 0px;
    margin-top: 0px !important;
  }
  div.closing-content div.card-body div.row.closing-wrapper {
    padding-top: -15px;
  }
  div.form-group.has-danger {
    margin-bottom: 0px;
  }
  div.workertype-closing span.el-tag.el-tag--info.el-tag--small.el-tag--light {
    width: 100%;
  }
  div.workertype-closing span.el-select__tags-text {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  div.workertype-closing i.el-tag__close.el-icon-close {
    float: right;
    margin-top: 1px;
  }
  div.workertype-closing i.el-tag__close.el-icon-close::before {
    padding-top: 1px;
    padding-left: 1px;
    font-size: 11px;
  }
  div.workertype-closing i.el-tag__close.el-icon-close:hover {
    color: #e14eca !important;
  }
  div.traffic-impact,
  div.portable-water {

  }
  div.traffic-impact.hide,
  div.portable-water.hide {
    display: none;
  }
  h4.impact-title {
    margin-left: -15px;
  }
  h4.impact-title span {
    margin-left: 10px;
    cursor: pointer;
  }
  i.impact-hide.hide,
  i.impact-show.hide {
    display: none;
  }
</style>