export default {
    quebec: {
        lines: [
            {value: 'Tout', label: 'Tout'},
            {value: '1', label: '1'},
            {value: '107', label: '107'},
            {value: '11', label: '11'},
            {value: '11G', label: '11G'},
            {value: '13', label: '13'},
            {value: '133', label: '133'},
            {value: '136', label: '136'},
            {value: '13A', label: '13A'},
            {value: '13B', label: '13B'},
            {value: '14', label: '14'},
            {value: '14A', label: '14A'},
            {value: '14G', label: '14G'},
            {value: '15', label: '15'},
            {value: '15A', label: '15A'},
            {value: '15B', label: '15B'},
            {value: '16', label: '16'},
            {value: '18', label: '18'},
            {value: '185', label: '185'},
            {value: '19', label: '19'},
            {value: '214', label: '214'},
            {value: '215', label: '215'},
            {value: '22', label: '22'},
            {value: '230', label: '230'},
            {value: '236', label: '236'},
            {value: '238', label: '238'},
            {value: '239', label: '239'},
            {value: '25', label: '25'},
            {value: '250', label: '250'},
            {value: '251', label: '251'},
            {value: '253', label: '253'},
            {value: '254', label: '254'},
            {value: '255', label: '255'},
            {value: '25G', label: '25G'},
            {value: '272', label: '272'},
            {value: '273', label: '273'},
            {value: '274', label: '274'},
            {value: '277', label: '277'},
            {value: '279', label: '279'},
            {value: '28', label: '28'},
            {value: '280', label: '280'},
            {value: '281', label: '281'},
            {value: '282', label: '282'},
            {value: '283', label: '283'},
            {value: '284', label: '284'},
            {value: '289', label: '289'},
            {value: '29', label: '29'},
            {value: '290', label: '290'},
            {value: '3', label: '3'},
            {value: '31', label: '31'},
            {value: '315', label: '315'},
            {value: '32', label: '32'},
            {value: '33', label: '33'},
            {value: '330', label: '330'},
            {value: '331', label: '331'},
            {value: '332', label: '332'},
            {value: '336', label: '336'},
            {value: '337', label: '337'},
            {value: '338', label: '338'},
            {value: '34', label: '34'},
            {value: '350', label: '350'},
            {value: '354', label: '354'},
            {value: '355', label: '355'},
            {value: '358', label: '358'},
            {value: '36', label: '36'},
            {value: '372', label: '372'},
            {value: '374', label: '374'},
            {value: '377', label: '377'},
            {value: '380', label: '380'},
            {value: '381', label: '381'},
            {value: '382', label: '382'},
            {value: '384', label: '384'},
            {value: '39', label: '39'},
            {value: '391', label: '391'},
            {value: '3A', label: '3A'},
            {value: '3G', label: '3G'},
            {value: '4', label: '4'},
            {value: '44', label: '44'},
            {value: '50', label: '5'},
            {value: '51', label: '51'},
            {value: '52', label: '52'},
            {value: '53', label: '53'},
            {value: '530', label: '530'},
            {value: '536', label: '536'},
            {value: '537', label: '537'},
            {value: '538', label: '538'},
            {value: '54', label: '54'},
            {value: '54A', label: '54A'},
            {value: '54G', label: '54G'},
            {value: '55', label: '55'},
            {value: '550', label: '550'},
            {value: '555', label: '555'},
            {value: '558', label: '558'},
            {value: '55A', label: '55A'},
            {value: '55G', label: '55G'},
            {value: '55H', label: '55H'},
            {value: '57', label: '57'},
            {value: '572', label: '572'},
            {value: '574', label: '574'},
            {value: '577', label: '577'},
            {value: '58', label: '58'},
            {value: '580', label: '580'},
            {value: '581', label: '581'},
            {value: '582', label: '582'},
            {value: '584', label: '584'},
            {value: '59', label: '59'},
            {value: '61', label: '61'},
            {value: '64', label: '64'},
            {value: '65', label: '65'},
            {value: '70', label: '70'},
            {value: '72', label: '72'},
            {value: '74', label: '74'},
            {value: '75', label: '75'},
            {value: '76', label: '76'},
            {value: '77', label: '77'},
            {value: '79', label: '79'},
            {value: '79A', label: '79A'},
            {value: '79B', label: '79B'},
            {value: '79G', label: '79G'},
            {value: '80', label: '80'},
            {value: '800', label: '800'},
            {value: '801', label: '801'},
            {value: '802', label: '802'},
            {value: '803', label: '803'},
            {value: '804', label: '804'},
            {value: '807', label: '807'},
            {value: '80A', label: '80A'},
            {value: '80G', label: '80G'},
            {value: '81', label: '81'},
            {value: '82', label: '82'},
            {value: '82A', label: '82A'},
            {value: '84', label: '84'},
            {value: '84G', label: '84G'},
            {value: '85', label: '85'},
            {value: '86', label: '86'},
            {value: '86G', label: '86G'},
            {value: '88', label: '88'},
            {value: '91', label: '91'},
            {value: '907', label: '907'},
            {value: '915', label: '915'},
            {value: '92', label: '92'},
            {value: '925', label: '925'},
            {value: '92A', label: '92A'},
            {value: '93', label: '93'},
            {value: '931', label: '931'},
            {value: '936', label: '936'},
            {value: '94', label: '94'},
            {value: '950', label: '950'},
            {value: '954', label: '954'},
            {value: '972', label: '972'},
            {value: '980', label: '980'},
            {value: '982', label: '982'},
            {value: '984', label: '984'},
            {value: '992', label: '992'}
        ]
     },
     montreal: {
         lines: [
            {value: 'Tout', label: 'Tout'},
            {value: "Airlie", label: "Airlie"},
            {value: "Alfred-Nobel", label: "Alfred-Nobel"},
            {value: "Angus", label: "Angus"},
            {value: "Anse-à-l'Orme", label: "Anse-à-l'Orme"},
            {value: "Antoine-Faucon", label: "Antoine-Faucon"},
            {value: "Armand-Bombardier", label: "Armand-Bombardier"},
            {value: "Atateken", label: "Atateken"},
            {value: "Avenue des Pins", label: "Avenue des Pins"},
            {value: "Avenue-du-Mont-Royal", label: "Avenue-du-Mont-Royal"},
            {value: "Avenue du Parc", label: "Avenue du Parc"},
            {value: "Bannantyne", label: "Bannantyne"},
            {value: "Barclay", label: "Barclay"},
            {value: "Beaubien", label: "Beaubien"},
            {value: "Bélanger", label: "Bélanger"},
            {value: "bleue", label: "bleue"},
            {value: "Bois-Franc", label: "Bois-Franc"},
            {value: "Bord-du-Lac", label: "Bord-du-Lac"},
            {value: "Boulevard Saint-Joseph", label: "Boulevard Saint-Joseph"},
            {value: "Boulevard Saint-Laurent", label: "Boulevard Saint-Laurent"},
            {value: "Boulevard Shevchenko", label: "Boulevard Shevchenko"},
            {value: "Bridge", label: "Bridge"},
            {value: "Broadway / Provost", label: "Broadway / Provost"},
            {value: "Brunswick", label: "Brunswick"},
            {value: "Cardinal", label: "Cardinal"},
            {value: "Carson", label: "Carson"},
            {value: "Casgrain", label: "Casgrain"},
            {value: "Cavendish", label: "Cavendish"},
            {value: "Centrale", label: "Centrale"},
            {value: "Chabanel / Marché Central", label: "Chabanel / Marché Central"},
            {value: "Charland / Chabanel", label: "Charland / Chabanel"},
            {value: "Chemin Sainte-Marie", label: "Chemin Sainte-Marie"},
            {value: "Christophe-Colomb", label: "Christophe-Colomb"},
            {value: "Christophe-Colomb / Meilleur", label: "Christophe-Colomb / Meilleur"},
            {value: "Cité-du-Havre", label: "Cité-du-Havre"},
            {value: "Côte-des-Neiges", label: "Côte-des-Neiges"},
            {value: "Côte-des-Neiges", label: "Côte-des-Neiges"},
            {value: "Côte-Sainte-Catherine", label: "Côte-Sainte-Catherine"},
            {value: "Côte-Vertu-Ouest", label: "Côte-Vertu-Ouest"},
            {value: "Couture", label: "Couture"},
            {value: "Crémazie", label: "Crémazie"},
            {value: "Dalton", label: "Dalton"},
            {value: "Dawson", label: "Dawson"},
            {value: "Décarie", label: "Décarie"},
            {value: "Décarie", label: "Décarie"},
            {value: "De l'Acadie", label: "De l'Acadie"},
            {value: "De la Commune", label: "De la Commune"},
            {value: "De l'Assomption", label: "De l'Assomption"},
            {value: "De l'Esplanade", label: "De l'Esplanade"},
            {value: "de Liège", label: "de Liège"},
            {value: "De Lorimier", label: "De Lorimier"},
            {value: "De Salaberry", label: "De Salaberry"},
            {value: "Des Grandes-Prairies", label: "Des Grandes-Prairies"},
            {value: "Des Sources", label: "Des Sources"},
            {value: "D'Iberville", label: "D'Iberville"},
            {value: "Dollard", label: "Dollard"},
            {value: "Du Centre", label: "Du Centre"},
            {value: "Dudemaine", label: "Dudemaine"},
            {value: "Édouard-Montpetit", label: "Édouard-Montpetit"},
            {value: "Express Anse-à-l'Orme", label: "Express Anse-à-l'Orme"},
            {value: "Express Antoine-Faucon", label: "Express Antoine-Faucon"},
            {value: "Express Bord-du-Lac", label: "Express Bord-du-Lac"},
            {value: "Express Bout-de-l'Île", label: "Express Bout-de-l'Île"},
            {value: "Express Cégep Marie-Victorin", label: "Express Cégep Marie-Victorin"},
            {value: "Express Charleroi", label: "Express Charleroi"},
            {value: "Express Côte-des-Neiges", label: "Express Côte-des-Neiges"},
            {value: "Express Des Sources", label: "Express Des Sources"},
            {value: "Express Dollard-des-Ormeaux", label: "Express Dollard-des-Ormeaux"},
            {value: "Express du Parc", label: "Express du Parc"},
            {value: "Express Henri-Bourassa", label: "Express Henri-Bourassa"},
            {value: "Express Île-Bizard", label: "Express Île-Bizard"},
            {value: "Express John Abbott", label: "Express John Abbott"},
            {value: "Express Lachine", label: "Express Lachine"},
            {value: "Express Lachine / LaSalle", label: "Express Lachine / LaSalle"},
            {value: "Express Lacordaire", label: "Express Lacordaire"},
            {value: "Express Lionel-Groulx", label: "Express Lionel-Groulx"},
            {value: "Express Maurice-Duplessis", label: "Express Maurice-Duplessis"},
            {value: "Express Métropolitaine", label: "Express Métropolitaine"},
            {value: "Express Newman", label: "Express Newman"},
            {value: "Express Notre-Dame-de-Grâce", label: "Express Notre-Dame-de-Grâce"},
            {value: "Express Notre-Dame", label: "Express Notre-Dame"},
            {value: "Express Papineau", label: "Express Papineau"},
            {value: "Express Parcs industriels de l'Est", label: "Express Parcs industriels de l'Est"},
            {value: "Express Pie-IX", label: "Express Pie-IX"},
            {value: "Express Pierrefonds", label: "Express Pierrefonds"},
            {value: "Express Pierrefonds / Gouin", label: "Express Pierrefonds / Gouin"},
            {value: "Express Pointe-aux-Trembles", label: "Express Pointe-aux-Trembles"},
            {value: "Express Rivière-des-Prairies", label: "Express Rivière-des-Prairies"},
            {value: "Express Saint-Charles", label: "Express Saint-Charles"},
            {value: "Express Saint-Joseph", label: "Express Saint-Joseph"},
            {value: "Express Saint-Michel", label: "Express Saint-Michel"},
            {value: "Express Sherbrooke", label: "Express Sherbrooke"},
            {value: "Express Victoria", label: "Express Victoria"},
            {value: "Fleury", label: "Fleury"},
            {value: "Girouard", label: "Girouard"},
            {value: "Gouin Est", label: "Gouin Est"},
            {value: "Gouin", label: "Gouin"},
            {value: "Graham", label: "Graham"},
            {value: "Grenet", label: "Grenet"},
            {value: "Griffintown", label: "Griffintown"},
            {value: "Griffith / Saint-François", label: "Griffith / Saint-François"},
            {value: "Henri-Bourassa-Est", label: "Henri-Bourassa-Est"},
            {value: "Henri-Bourassa", label: "Henri-Bourassa"},
            {value: "Hochelaga", label: "Hochelaga"},
            {value: "Hochelaga / Notre-Dame", label: "Hochelaga / Notre-Dame"},
            {value: "Honoré-Beaugrand", label: "Honoré-Beaugrand"},
            {value: "Hymus", label: "Hymus"},
            {value: "Île-des-Soeurs", label: "Île-des-Soeurs"},
            {value: "Jacques-Bizard", label: "Jacques-Bizard"},
            {value: "Jarry", label: "Jarry"},
            {value: "jaune", label: "jaune"},
            {value: "Jean-Talon Est", label: "Jean-Talon Est"},
            {value: "Jean-Talon", label: "Jean-Talon"},
            {value: "Jean-Talon Ouest", label: "Jean-Talon Ouest"},
            {value: "Jolicoeur", label: "Jolicoeur"},
            {value: "Keller", label: "Keller"},
            {value: "Kieran", label: "Kieran"},
            {value: "Lachine / YUL Aéroport / Des Sources", label: "Lachine / YUL Aéroport / Des Sources"},
            {value: "Lacordaire", label: "Lacordaire"},
            {value: "Lacordaire / Maurice-Duplessis", label: "Lacordaire / Maurice-Duplessis"},
            {value: "Lafleur / Norman", label: "Lafleur / Norman"},
            {value: "Langelier", label: "Langelier"},
            {value: "Lapierre", label: "Lapierre"},
            {value: "La Ronde / La Plage", label: "La Ronde / La Plage"},
            {value: "La Ronde", label: "La Ronde"},
            {value: "Laurendeau", label: "Laurendeau"},
            {value: "Le Casino", label: "Le Casino"},
            {value: "Masson", label: "Masson"},
            {value: "Maurice-Duplessis", label: "Maurice-Duplessis"},
            {value: "McArthur", label: "McArthur"},
            {value: "Mercier-Est", label: "Mercier-Est"},
            {value: "Monkland", label: "Monkland"},
            {value: "Monk", label: "Monk"},
            {value: "Monselet", label: "Monselet"},
            {value: "Newman", label: "Newman"},
            {value: "Notre-Dame-de-Grâce", label: "Notre-Dame-de-Grâce"},
            {value: "Notre-Dame", label: "Notre-Dame"},
            {value: "O'Brien", label: "O'Brien"},
            {value: "Ontario", label: "Ontario"},
            {value: "orange", label: "orange"},
            {value: "Papineau", label: "Papineau"},
            {value: "Parc-du-Mont-Royal / Oratoire", label: "Parc-du-Mont-Royal / Oratoire"},
            {value: "Parc-du-Mont-Royal / Ridgewood", label: "Parc-du-Mont-Royal / Ridgewood"},
            {value: "Parc-Industriel-Lachine", label: "Parc-Industriel-Lachine"},
            {value: "Parc-Industriel-Saint-Laurent", label: "Parc-Industriel-Saint-Laurent"},
            {value: "Paré", label: "Paré"},
            {value: "Perras", label: "Perras"},
            {value: "Pie-IX", label: "Pie-IX"},
            {value: "Pierrefonds / Centre-ville", label: "Pierrefonds / Centre-ville"},
            {value: "Pierrefonds", label: "Pierrefonds"},
            {value: "Pierrefonds / Saint-Charles", label: "Pierrefonds / Saint-Charles"},
            {value: "Place du Commerce", label: "Place du Commerce"},
            {value: "Pointe-aux-Trembles", label: "Pointe-aux-Trembles"},
            {value: "Pointe-Nord / Île-des-Soeurs", label: "Pointe-Nord / Île-des-Soeurs"},
            {value: "Pointe-Saint-Charles", label: "Pointe-Saint-Charles"},
            {value: "Polyvalente Émile-Legault", label: "Polyvalente Émile-Legault"},
            {value: "Quartier Saint-Michel / Ahuntsic", label: "Quartier Saint-Michel / Ahuntsic"},
            {value: "Queen-Mary", label: "Queen-Mary"},
            {value: "Rachel", label: "Rachel"},
            {value: "René-Lévesque", label: "René-Lévesque"},
            {value: "Robert", label: "Robert"},
            {value: "Rockland", label: "Rockland"},
            {value: "Roger-Pilon", label: "Roger-Pilon"},
            {value: "Rosemont", label: "Rosemont"},
            {value: "Saint-Charles / Saint-Jean", label: "Saint-Charles / Saint-Jean"},
            {value: "Saint-Denis", label: "Saint-Denis"},
            {value: "Saint-Denis / Saint-Hubert", label: "Saint-Denis / Saint-Hubert"},
            {value: "Sainte-Anne-de-Bellevue / Centre-ville", label: "Sainte-Anne-de-Bellevue / Centre-ville"},
            {value: "Sainte-Anne-de-Bellevue", label: "Sainte-Anne-de-Bellevue"},
            {value: "Sainte-Anne", label: "Sainte-Anne"},
            {value: "Sainte-Catherine", label: "Sainte-Catherine"},
            {value: "Saint-Jacques", label: "Saint-Jacques"},
            {value: "Saint-Jean-Baptiste", label: "Saint-Jean-Baptiste"},
            {value: "Saint-Michel", label: "Saint-Michel"},
            {value: "Saint-Patrick", label: "Saint-Patrick"},
            {value: "Sauvé / Côte-Vertu", label: "Sauvé / Côte-Vertu"},
            {value: "Sauvé / YUL Aéroport", label: "Sauvé / YUL Aéroport"},
            {value: "Sherbrooke-Est", label: "Sherbrooke-Est"},
            {value: "Sherbrooke / Joseph-Renaud", label: "Sherbrooke / Joseph-Renaud"},
            {value: "Sherbrooke / Notre-Dame", label: "Sherbrooke / Notre-Dame"},
            {value: "Sherbrooke", label: "Sherbrooke"},
            {value: "Somerled", label: "Somerled"},
            {value: "Station Lionel-Groulx / CUSM", label: "Station Lionel-Groulx / CUSM"},
            {value: "The Boulevard", label: "The Boulevard"},
            {value: "Thimens", label: "Thimens"},
            {value: "Trainbus Acadie / Mont-Royal / Namur", label: "Trainbus Acadie / Mont-Royal / Namur"},
            {value: "Trainbus  Bois-Franc / Côte-Vertu", label: "Trainbus  Bois-Franc / Côte-Vertu"},
            {value: "Trainbus Roxboro / Côte-Vertu", label: "Trainbus Roxboro / Côte-Vertu"},
            {value: "Transcanadienne", label: "Transcanadienne"},
            {value: "Van Horne", label: "Van Horne"},
            {value: "Verdun / LaSalle", label: "Verdun / LaSalle"},
            {value: "Verdun", label: "Verdun"},
            {value: "verte", label: "verte"},
            {value: "Viau", label: "Viau"},
            {value: "Victoria", label: "Victoria"},
            {value: "Vieux-Montréal / Vieux-Port", label: "Vieux-Montréal / Vieux-Port"},
            {value: "Villeray", label: "Villeray"},
            {value: "Ville-Saint-Laurent", label: "Ville-Saint-Laurent"},
            {value: "Wellington", label: "Wellington"},
            {value: "Westminster", label: "Westminster"},
            {value: "YUL Aéroport / Centre-Ville", label: "YUL Aéroport / Centre-Ville"}
             ]
    }
}