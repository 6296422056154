<template>
  <div class="content">
    <div class="mt-3 text-right">
      <base-button class=""  @click="navigateToArchiveForm" type="primary">
        {{ $t('archivePage.btnNewArch') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <card class="archives-card" card-body-classes="table-full-width">
          <!--<h4 slot="header" class="card-title"></h4>-->
          <div slot="header" class="row">
            <div class="col-sm-8 col-md-8 col-lg-8">
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <base-input name="date">
                <el-date-picker
                  type="datetimerange"
                  value-format="timestamp"
                  @change="onChangeDate"
                  range-separator="-"
                  format="yyyy-MM-dd HH:mm"
                  lang="fr"
                  v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
                  v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
                  v-model="date">
                </el-date-picker>
              </base-input>
            </div>
          </div>
          <ArchivesTable :componentName="componentName" :tableData="tableData" :loading="loading" :getArchives="getArchives" />
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div v-show="tableData.length > 0" class="col-sm-2 list-project-action">
            </div>
            <div v-show="tableData.length > 0"  class="paginate-container">
              <p class="card-category"> {{ $t('archivePage.pageResultsFrom') }} {{ from + 1 }} {{ $t('archivePage.pageResultsTo') }} {{ to }} {{ $t('archivePage.pageResultsOn') }} {{ total }}</p>
              <base-pagination
                v-show="tableData.length > 0"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Table, TableColumn, Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { BaseTable } from '@/components';
import { BasePagination, BaseAlert } from 'src/components';
import { Slider, BaseProgress, ArchivesTable } from 'src/components/index';

export default {
  components: {
    BaseTable,
    BasePagination,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseProgress,
    ArchivesTable
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.total;
    }
  },
  created () {
    this.getArchives();
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      loading:false,
      tableData: [],
      componentName: 'ArchivesPage',
      total: 0,
      selectedProject: '0',
      retentionType: {
        'minutes':this.$i18n.t('archivePage.min'),
        'day':this.$i18n.t('archivePage.day'),
        'month':this.$i18n.t('archivePage.month'),
        'year':this.$i18n.t('archivePage.year')
      },
      date: null,
    };
  },
  methods: {
    navigateToArchiveForm(){
      const returnedUrl = window.location.href;
      this.$router.push({
        path: '/archives/create',
        query: { returnedUrl },
      }); 
    },
    onChangeDate() {
       this.getArchives();
    },
    formatRetention(value, type, date) {
      let now = new Date().getTime();
      var res, obj = {};
      if (type=='minutes') {
        let unit = this.$i18n.t('archivePage.minMinutes');
        res =  (now - date) / (60*1000);
        obj.value = value;
        obj.duration = Math.floor(100 * res/parseInt(value, 10));
        obj.label = res.toFixed(2) + unit + ' / ' + value + unit;
      } if (type=='day') {
        let unit = this.$i18n.t('archivePage.minDay');
        res =  (now - date) / (24* 60 * 60*1000);
        obj.value = value;
        obj.duration = Math.floor(100 * res/parseInt(value, 10));
        obj.label = res.toFixed(2) + unit + ' / ' + value + unit;
      }if (type=='month') {
        let unit = this.$i18n.t('archivePage.minMonth');
        res =  (now - date) / (30 * 24* 60 * 60*1000);
        obj.value = value;
        obj.duration = Math.floor(100 * res/parseInt(value, 10));
        obj.label = res.toFixed(2) + unit + ' / ' + value + unit;
      }if (type=='year') {
        let unit = this.$i18n.t('archivePage.minYear');
        res =  (now - date) / (365 * 24* 60 * 60*1000);
        obj.value = value;
        obj.duration = Math.floor(100 * res/parseInt(value, 10));
        obj.label = res.toFixed(2) + unit + ' / ' + value + unit;
      }
      return obj;
    },
    getArchives() {
      let _this = this;
      this.loading=true;
      let filters = {};
      filters.from = (this.pagination.perPage * (this.pagination.currentPage - 1)) + 1;
      filters.limit = this.pagination.perPage;
      filters.ranges = this.date || [];
      axios.post('archive/list', filters).then(response => {
        let data = response.data;
        if (data.success) {
          _this.loading=false;
          let dt;
          _this.tableData = data.data.map((element) => {
            let ret = JSON.parse(element.retention);
            if (!element.retChange) {
              dt = element.cdate;
            } else {
              dt = element.retChange;
            }
            let obj = _this.formatRetention(ret.value, ret.type, dt);
            element.fRetentionLabel = obj.label;
            element.fRetentionValue = obj.duration;
            element.fTags = '';
            element.tags.split(',').forEach((e) => {
              element.fTags += '<span class="badge badge-info">' + e+ '</span> ';
            });
            if (element.published) {
              element.publishedLabel = '<i class="tim-icons icon-check-2 success"></i>';
            } else {
              element.publishedLabel = '<i class="tim-icons icon-simple-remove"></i>';
            }
            return element;
          });
          _this.total = data.total;
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message:  this.$i18n.t('serverReply.errorProject'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: this.$i18n.t('serverReply.errorProject'),
          timeout:   10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    },
  },
  watch: {
    'pagination.currentPage'() {
      this.getArchives();
    }
  }
};
</script>
<style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  table.el-table__header {
    margin-bottom: 0px;
  }
  .bootstrap-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
    vertical-align: middle;
    cursor: text;
  }
  .bootstrap-tagsinput .tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
        padding-left: 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: .75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
    padding-left: .8em;
    color: #fff;
  }
  div.card.archives-card div.card-body {
    min-height: 500px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }
  div.card.archives-card .pad-left-20 {
    padding-left: 20px
  }
  .pell-button {
    width: 35px !important;
  }
  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
</style>
