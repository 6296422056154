<template>
    <span class="really-good-exp-main py-2" :class="{ 'active': feedbackRating === 2 }">😃<span>{{ $t('feedbackForm.reallyGoodExp') }}</span></span>
  </template>
  
  <script>
  
  export default {
    name: 'ReallyGoodExperience',
    props: {
      feedbackRating: {
        required: true,
        type: Number
      }
    },
  }
  
  </script>
  
  <style>
  .really-good-exp-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid #9f9797;
    border-radius: 8px;
    width: 150px;
    font-size: 14px;
    color: #9f9797;
  }
  
  .really-good-exp-main.active {
    color: white !important;
    background-color: #82A34E;
    font-weight: 600;
    border: none;
  }  
  </style>