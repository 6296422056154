export default {
  list: [
    "#5291d6",
    "#43c8ac",
    "#8b5d89",
    "#b27fc7",
    "#030063",
    "#4371d4",
    "#c44734",
    "#872957",
    "#db1b46",
    "#cb626b",
    "#db75a2",
    "#964129",
    "#e090d9",
    "#d4acbd",
    "#9a7ad0",
    "#609542",
    "#4d3483",
    "#852f79",
    "#58c07b",
    "#a02916",
    "#c8703a",
    "#f795aa",
    "#425dbf",
    "#003366",
    "#833ea9",
    "#b1ae43",
    "#f0b391",
    "#b4334f",
    "#6f81e7",
    "#d460ae",
    "#cb417f",
    "#7e1100",
    "#d49b35",
    "#d35146",
    "#5a57bc",
    "#85b43f",
    "#580c00",
    "#5ea4b7",
    "#6444b4",
    "#ffd7e0",
    "#310700",
    "#c15dbf"
  ]
};