<template>
  <div class="row modal-filters-content">
    <div class="filters-wrapper">
      <div class="col-md-12 switch-content">
        <div class="close" v-on:click="closeAdvancedTab()">
          <i class="tim-icons icon-simple-remove"></i>
        </div>
        <!--<p class="title-mode" >Mode</p>-->
        <span class="mode-text">{{ $t('mapPage.displayProject') }}&nbsp;&nbsp;</span>
        <base-switch v-model="modeFilter"></base-switch>
        <span class="mode-text">&nbsp;&nbsp;{{ $t('mapPage.detectConflict') }}</span>
      </div>
      <div v-if="modeFilter" class="col-md-12 conflicts-container">
        <div class="conflicts-filters-wrapper">
          <div v-show="loading" class="advanced-loading">
            <img src="../../../public/img/loading.gif" />
          </div>
          <div  class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row advfilters-conflicts-type-geom">
            <label> {{ $t('mapPage.conflictBetween') }}</label>
            <div  class="col-sm-12 col-md-12 col-lg-12">
              <div class="col-md-4 col-sm-4 col-lg-4">
                <span>{{ $t('mapPage.entraves') }}&nbsp;</span>
                <base-switch  on-text="" off-text="" @input="onChangeType('conflict')" v-model="cfilters.entrave"></base-switch>
              </div>
              <div class="col-md-4 col-sm-4 col-lg-4">
                <span>{{ $t('mapPage.phases') }}&nbsp;</span>
                <base-switch on-text="" off-text="" @input="onChangeType('conflict')" v-model="cfilters.closing"></base-switch>
              </div>
              <div class="col-md-4 col-sm-4 col-lg-4">
                <span>{{ $t('mapPage.detours') }}&nbsp;</span>
                <base-switch on-text="" off-text="" @input="onChangeType('conflict')" v-model="cfilters.detour"></base-switch>
              </div>
            </div>
          </div> 
          <div  class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row">
            <label> {{ $t('mapPage.projectType') }}</label>
            <el-select
              class="select-primary select-inline"
              name="published"
              size="large"
              @input="onChangeType('conflict')"
              v-model="cfilters.published">
              <el-option
                v-for="option in [{value: '', label: $t('select.all')}, {value: true, label: $t('select.publish')}, {value: false, label: $t('select.nopublish')}]"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </div>
          <div v-if="cfilters.pdates && cfilters.pdates.length > 0" class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row">
            <label> {{ $t('mapPage.projectsDate') }}</label>
            <base-input v-for="(item, index) in cfilters.pdates" >
              <el-date-picker
                type="datetimerange"
                value-format="timestamp"
                range-separator="-"
                format="yyyy-MM-dd HH:mm"
                lang="fr"
                @input="onChangeDate(item.dateId, $event, type)"
                v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
                v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
                v-bind:v-model="item.dateId"
                :value="[item.sdate, item.edate]">
              </el-date-picker>
            </base-input>
          </div>
          <div v-else class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row">
            <label> {{ $t('mapPage.projectsDate') }}</label>
            <base-input name="date">
              <el-date-picker
                type="datetimerange"
                value-format="timestamp"
                range-separator="-"
                format="yyyy-MM-dd HH:mm"
                lang="fr"
                @input="onChangeType('conflict')"
                v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
                v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
                v-model="cfilters.date">
                </el-date-picker>
            </base-input>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row advfilters-project-btn-container">
            <!--<base-button type="danger" v-on:click="resetConflicts()">{{ $t('mapPage.btnCancel') }}</base-button>-->
            <base-button type="danger" v-on:click="resetConflicts()"><i class="tim-icons icon-refresh-01"></i></base-button>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 advfilters-conflict-row" v-if="results.length > 0">
            <h4> {{ $t('mapPage.projectInConflict') }}: </h4>
            <tree ref='treeAdvanced' :data="results" :options="{ checkbox: false }" >
              <span class="tree-text" slot-scope="{ node }">
                <template v-if="node.data.animate">
                  <span class="node-animate"> {{ node.text }}</span>
                </template>
                <template v-else>
                  {{ node.text }}
                </template>
              </span>
            </tree>
          </div>
        </div>
      </div>
      <div v-else class="col-md-12 projects-container">
        <div v-show="loading" class="advanced-loading">
          <img src="../../../public/img/loading.gif" />
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 advfilters-project-row advfilters-project-type-geom">
          <label> {{ $t('mapPage.projectStep') }}</label>
          <div  class="col-sm-12 col-md-12 col-lg-12">
            <div class="col-md-4 col-sm-4 col-lg-4">
              <span>{{ $t('mapPage.entraves') }}&nbsp;</span>
              <base-switch  on-text="" off-text="" @input="onChangeType('project')" v-model="pfilters.entrave"></base-switch>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <span>{{ $t('mapPage.phases') }}&nbsp;</span>
              <base-switch on-text="" off-text="" @input="onChangeType('project')" v-model="pfilters.closing"></base-switch>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <span>{{ $t('mapPage.detours') }}&nbsp;</span>
              <base-switch on-text="" off-text="" @input="onChangeType('project')" v-model="pfilters.detour"></base-switch>
            </div>
          </div>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 advfilters-project-row">
          <label> {{ $t('mapPage.projectType') }}</label>
          <el-select
            class="select-primary select-inline"
            name="published"
            size="large"
            @input="onChangeType('project')"
            v-model="pfilters.published">
            <el-option
              v-for="option in [{value: '', label: $t('select.all')}, {value: true, label: $t('select.publish')}, {value: false, label: $t('select.nopublish')}]"
              class="select-primary"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </div>
        <div v-if="isMultiLayer" class="col-sm-12 col-md-12 col-lg-12 advfilters-project-row">
            <label> Layers</label>
            <el-select
              class="select-primary select-inline"
              name="published"
              size="large"
              @input="onChangeLayer()"
              v-model="layer">
              <el-option
                v-for="option in layersList"
                class="select-primary"
                :value="option.key"
                :label="option.label"
                :key="option.key">
              </el-option>
            </el-select>
          </div>
        <div class="col-sm-12 col-md-12 col-lg-12 advfilters-project-row">
          <label> {{ $t('mapPage.projectDate') }}</label>
          <base-input name="date">
            <el-date-picker
              type="datetimerange"
              value-format="timestamp"
              @input="onChangeType('project')"
              range-separator="-"
              format="yyyy-MM-dd HH:mm"
              lang="fr"
              v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
              v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
              v-model="pfilters.date">
            </el-date-picker>
          </base-input>
        </div>    
        <div class="advfilters-project-btn-container">
          <!--<base-button type="danger" v-on:click="resetProjects()">{{ $t('mapPage.btnCancel') }}</base-button>-->
           <base-button type="danger" v-on:click="resetProjects()"><i class="tim-icons icon-refresh-01"></i></base-button>
          <!--<base-button @click='getAppProjects()'>Appliquer</base-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseSwitch, BaseAlert } from 'src/components';
import { Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { Slider } from 'src/components/index';
import RoadNetwork from './../shared/roadNetwork';

import LiquorTree from 'liquor-tree';

export default {
  name:'advanced-filters',
  props: ['conflictsBuffer', 'ProjectsConflict', 'date', 'loading', 'mapLoad', 'selectedElement', 'advancedSettings','layer'],
  components: {
    Slider,
    BaseSwitch,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [LiquorTree.name]: LiquorTree,
    BaseAlert
  },
  data() {
    return {
      modeFilter: false,
      pfilters: {
        published: true,
        date: this.date,
        entrave:true,
        closing: false,
        detour: false
      },
      cfilters: {
        date: this.date,
        pdates: [],
        published: true,
        entrave: true,
        closing: true,
        detour: true
      },
      user: '',
      instance: '',
      layerKeys : [],
      layersList : [],
      results: [],
      isMultiLayer: false,
    };
  },
  created() {
    this.user     = JSON.parse(window.localStorage.getItem('user'));
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    if(RoadNetwork[this.instance.city].isMultiLayer != undefined){
      this.layersList = RoadNetwork[this.instance.city].layers;
      this.layerKeys = Object.keys(this.layersList);
      this.isMultiLayer = RoadNetwork[this.instance.city].isMultiLayer || false;
    }
  },
  mounted() {
    this.resetConflicts();
    this.resetProjects();
    if (this.mapLoad) {
      this.getAppProjects(); 
    }
    this.layerKeys.forEach((key) => {
        if (this.user.lang=='fr') {
            this.layersList[key].label=this.layersList[key].label_fr;
        } else {
            this.layersList[key].label=this.layersList[key].label_en;
        }
    });
  },
  methods: {
    onChangeType(type) {
      if (type=='project') {
        this.getAppProjects();
      } else {
        this.getConflictProjects();
      }
    },
    onChangeLayer(){
      this.$emit('onChangeLayer', this.layer);
    },
    onChangeDate(dateId, dates, type) {
      this.cfilters.pdates.forEach((element) => {
        if (element.dateId==dateId) {
          element.sdate=dates[0];
          element.edate=dates[1];
        }
      });
      this.getConflictProjects();
    },
    getAppProjects() {
      this.$emit('getAdvancedProjects', [this.formatFilters('project'), 'project']);
    },
    getConflictProjects(first) {
      this.$emit('getAdvancedProjects', [this.formatFilters('conflict'), 'conflict']);
      if (!first && !this.conflictsBuffer) {
        this.$notify({
          message: 'Veuillez sélectionner une entrave sur la map.',
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'info'
        });
      }
    },
    back() {
      this.results=[];
    },
    resetProjects() {
      this.pfilters = {
        date: this.date,
        published: true,
        entrave: true,
        closing: false,
        detour: false
      };
      this.$emit('resetAdvancedProjects', ['project']);
    },
    resetConflicts() {
      let pdates=[];
      if (this.selectedElement && this.selectedElement.dates) {
        this.selectedElement.dates.forEach((d) => {
          pdates.push({dateId: d.dateId, sdate: d.sdate, edate: d.edate, closingType: d.closingType, days: d.days});
          //pdates.push([d.sdate, d.edate]);
        });
      }
      this.cfilters = {
        published: true,
        date: this.date,
        pdates: pdates,
        entrave:true,
        closing: false,
        detour: false
      };
      this.results=[];
      this.$emit('resetAdvancedProjects', ['conflict']);
    },
    closeAdvancedTab() {
      this.$emit('closeAdvancedTab', []);
    },
    minDate(dates) {
      let d = dates.map((e) => { return e.sdate; });
      return Math.min.apply(null, d);
    },
    maxDate(dates) {
      let d = dates.map((e) => { return e.edate; });
      return Math.max.apply(null, d);
    },
    formatFilters(type) {
      let filter;
      if (type=='conflict') {
        filter = this.cfilters;
      } else {
        filter = this.pfilters;
      }
      let object = {};
      if (filter.published===true) {
        object.publish = true;
        object.nopublish = false;
      } else if (filter.published===false) {
        object.publish = false;
        object.nopublish = true;
      } else {
        object.publish = true;
        object.nopublish = true;
      }
      object.date = filter.date;
      object.ranges  = filter.date;
      if (type=='conflict') {
        //object.ranges  = [];
        if (this.selectedElement) {
          object.pdates = filter.pdates;
        }
      }
      object.entrave = filter.entrave;
      object.closing = filter.closing;
      object.detour  = filter.detour;
      return object;
    },
    formatDataToTree() {
      let _this=this;
      this.results=[];
      this.ProjectsConflict.forEach((pro) => {
        let obj = _this.constructProjectTree(pro);
        _this.results.push(obj);
      });
      if (this.$refs.treeAdvanced) this.$refs.treeAdvanced.setModel(_this.results);
    },
    constructProjectTree(project) {
      let entravesChild = this.constructProjectEntraves(project.entraves);
      let phasesChild = this.constructProjectPhases(project.phases);
      let name = project.name + ' ['  + project.inchargeof+ ' (' + project.phone +')]';
      let obj = {text: name, children: [{text: 'Entraves', children: entravesChild}, {text: 'Phases', children: phasesChild}]};
      return obj;
    },
    constructProjectEntraves(entraves) {
      let list = [];
      entraves.forEach((entrave) => {
        list.push({text: entrave.value, data: {'animate': true}});
      });
      return list;
    },
    constructProjectPhases(phases) {
      let _this=this;
      let phasesList=[];
      phases.forEach((phase) => {
        let phaseChildren = _this.constructPhaseChildren(phase);
        let obj = {text: (this.$i18n.t('mapPage.phase') + (phase.phaseIndex)), children: phaseChildren};
        phasesList.push(obj);
      });
      return phasesList;
    },
    constructPhaseChildren(phase) {
      let phasesChildren = [];
      if (phase.closing.length > 0 ) {
        //let closingList=[];
        phase.closing.forEach((close) => {
          let obj = {text: (this.$i18n.t('mapPage.closing') +close.closeIndex), children: [{text: close.value, data: {'animate': true}}]};
          phasesChildren.push(obj);
        });
        //phasesChildren.push({text: 'Fermetures', children: closingList});
      }
      if (phase.detours.length > 0 ) {
        //let detourList=[];
        phase.detours.forEach((detour) => {
          let obj = {text: (this.$i18n.t('mapPage.detour') +detour.detourIndex), children: [{text: detour.value, data: {'animate': true}}]};
          phasesChildren.push(obj);
          //phasesChildren.push({text: 'Detours', children: detourList});
        });
      }
      return phasesChildren;
    }
  },
  watch: {
    'modeFilter'(val) {
      if (val) {
        this.resetConflicts();
        this.getConflictProjects(true);
      } else {
        this.resetProjects();
        this.getAppProjects();
      }
    },
    'ProjectsConflict'(projectsConflict) {
      if (projectsConflict.length > 0) {
        this.formatDataToTree(); 
      } else {
        this.results=[];
      }
    },
    'conflictsBuffer'(val) {
      if (val && (this.modeFilter==true)) {
        this.getConflictProjects();
      }
      if (this.modeFilter && !val) {
        this.results=[];
      }
    },
    'mapLoad'(val) {
      if (val) {
        this.getAppProjects();
      } else {
         this.resetConflicts();
         this.resetProjects();
      }
    }
  }
}
</script>
<style>
  div.modal-filters-container div.modal-filters-content {
    display: grid;
    color: #525f7f;
    margin: 0px;
  }
  div.modal-filters-container div.modal-filters-content div.close {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #fd5d93;
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    height: 20px;
  }
  
  div.modal-filters-container div.modal-filters-content div.close i {
    cursor: pointer;
  }
  /*
  div.modal-filters-container div.modal-filters-content div.title {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px; 
  }
  div.modal-filters-container div.modal-filters-content div.title h4.card-title {
    color: #525f7f;
    margin: 0px;
    font-weight: bold;
  }
  */
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.switch-content {
    height: 70px;
    border-bottom: 1px solid #b0b0b1;
    padding-top: 20px;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper div.col-md-12,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.col-md-12 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0px;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper label {
    font-weight: bold;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper p.title-mode {
    font-weight: bold;
    color: #525f7f;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper span.mode-text {
    font-weight: bold;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.projects-container div.advfilters-project-row,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.advfilters-conflict-row {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper input,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper input.el-range-input,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper span.el-range-separator {
    color: #525f7f !important;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advfilters-conflict-row.advfilters-project-btn-container button {
    margin-bottom: 20px;
    border-bottom: 1px solid #b0b0b1;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advfilters-project-btn-container {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advfilters-project-btn-container button {
    
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.projects-container,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container {
    position: absolute;
    bottom: 0;
    top: 100px;
    left: 0;
    right: 0;
    overflow: auto;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper div.back-btn-container {
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper div.alert.alert-info {
    margin: 10px;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper h4,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper h4 {
    color: rgb(50, 50, 93);
    margin-left: 12px;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper ul.tree-root li.tree-node,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper ul.tree-root li.tree-node {
    background-color: white;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper ul.tree-root li.tree-node:not(.selected) > .tree-content:hover,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper  ul.tree-root li.tree-node.checked  div.tree-content,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper ul.tree-root li.tree-node:not(.selected) > .tree-content:hover,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper ul.tree-root li.tree-node.checked  div.tree-content {
    background-color: #f6f8fb !important;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-filters-wrapper ul.tree-root li.tree-node span.tree-anchor,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.conflicts-container div.conflicts-results-wrapper ul.tree-root li.tree-node span.tree-anchor {
    color: #525f7f !important;
  }
  
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advfilters-project-type-geom > div,
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advfilters-conflicts-type-geom > div {
    display: flex;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advanced-loading {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.9);
    margin-left: -20px;
    z-index: 9999;
  }
  div.modal-filters-container div.modal-filters-content div.filters-wrapper div.advanced-loading img {
    position: absolute;
    top: 30%;
    left: 50%;
  }
  
  .node-animate {
    font-weight: bold;
    animation: pulse 5s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
</style>