<template>
  <div class="content">
    <div class="mt-3 text-right">
      <base-button class=""  @click="$router.push('/users/create')" type="primary">
        {{ $t('usersPage.btnNewUser') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <card class="users-card" card-body-classes="table-full-width">
          <!--<h4 slot="header" class="card-title"></h4>-->
          <div slot="header" class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6">
              <base-button class="import-users" @click="exportUsers" type="default">
                {{ $t('usersPage.exportBtn') }}
              </base-button>
              <base-button class="import-users import-users-margin" @click="newUsers" type="primary">
                {{ $t('usersPage.importBtn') }}
              </base-button>
            </div>
          </div>
          <div class="loading" v-if="loading">
            <img src="../../../public/img/loading.gif" />
          </div>
          <div v-else>
            <div v-show="tableData.length==0">
              <base-alert type="info"> {{ $t('usersPage.alert1') }}</base-alert>
            </div>
            <div v-show="tableData.length > 0" style="overflow-x: auto;">
              <base-table :data="tableData" thead-classes="text-primary project-table-head" tbody-classes="user-table-body">
                <template slot="columns" slot-scope="{ columns }">
                  <th>{{ $t('usersPage.labelLogin') }}</th>
                  <th >{{ $t('usersPage.labelType') }}</th>
                  <th >{{ $t('usersPage.labelName') }}</th>
                  <th width="100">{{ $t('usersPage.labelTitle') }}</th>
                  <th>{{ $t('usersPage.labelEmail') }}</th>
                  <th>{{ $t('usersPage.labelPhone') }}</th>
                  <th>{{ $t('usersPage.labelPost') }}</th>
                  <th width="0" class="text-right"></th>
                </template>
            
                <template slot-scope="{ row, index }" >
                  <td ><p class="title">{{ row.login }}</p></td>
                  <td>{{ row.type}}</td>
                  <td>{{ row.name}}</td>
                  <td>{{ row.title}}</td>
                  <td>{{ row.email}}</td>
                  <td>{{ row.phone}}</td>
                  <td>{{ row.post}}</td>
                  <td class="text-right btn-container">
                    <div>
                      <el-tooltip
                        v-bind:content= "$t('usersPage.edit')"
                        effect="light"
                        :open-delay="300"
                        placement="top">
                        <base-button
                          :type="'success'"
                          icon
                          size="sm"
                          @click.native="updateUser(row.uuid)"
                          class="btn-link">
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip
                        v-if="row.login!='admin'"
                        v-bind:content= "$t('usersPage.delete')"
                        effect="light"
                        :open-delay="300"
                        placement="top">
                        <base-button
                          :type="'danger'"
                          icon
                          size="sm"
                          @click.native="deleteUser(row.uuid)"
                          class="btn-link">
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div v-show="tableData.length > 0" class="col-sm-2 list-project-action">
            </div>
            <div v-show="tableData.length > 0"  class="paginate-container">
              <p class="card-category"> {{ $t('usersPage.pageResultsFrom') }} {{ from + 1 }} {{ $t('usersPage.pageResultsTo') }} {{ to }} {{ $t('usersPage.pageResultsOn') }} {{ total }}</p>
              <base-pagination
                v-show="tableData.length > 0"
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total">
              </base-pagination>
            </div>
          </div>
          <import-users v-bind:importUsers="importUsers" v-on:hideImportUsers="hideImportUsers()"></import-users> 
          <modal :show.sync="exportUser" headerClasses="justify-content-center" type="custom-users-import" :scrollToBottom="false" v-on:hideModal="exportUser = false">
                  <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                  </template>
                  <template slot="close-button">
                    <button type="button" class="close" @click="exportUser = false" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
                  </template>
                  <div class="padding-model center-btn">
                    <base-button  class="import-users-margin" @click="exportCsv" type="default">
                      Export CSV
                    </base-button>
                    <base-button class="import-users-margin" @click="exportXls" type="default">
                      Export XLS
                    </base-button>
                  </div>
              </modal>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BasePagination, BaseAlert } from 'src/components';
import { BaseTable } from '@/components';
import swal from 'sweetalert2';
import { Modal } from 'src/components';


import ImportUsers from './ImportUsers';

export default {
  components: {
    Modal,
    BaseTable,
    BasePagination,
    BaseAlert,
    ImportUsers
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.total;
    }
  },
  created() {
    this.getUsers();
  },
  data() {
    return {
      pagination: {
        perPage: 20,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      total: 0,
      userType: {
        'admin':this.$i18n.t('usersPage.userTypeAdmin'),
        'adminGis':this.$i18n.t('usersPage.userTypeAdminGis'),
        'user':this.$i18n.t('usersPage.userTypeUser'),
        'userRes':this.$i18n.t('usersPage.userTypeUserRes'),
        'viewer':this.$i18n.t('usersPage.userTypeViewer'),
        'viewerRes':this.$i18n.t('usersPage.userTypeViewerRes')
      },
      loading: false,
      importUsers: false,
      exportUser: false
    };
  },
  methods: {
    newUsers() {
      this.importUsers=true;
    },
    exportUsers(){
      console.log("Export user");
      this.exportUser = true;
      // axios.get('user/csv').then(response => {
      //   let data = response.data;
      //   if (data.success) {
      //     _this.archive = data.data;
      //     _this.archive.retention = JSON.parse(_this.archive.retention);
      //     _this.archive.tags = (_this.archive.tags) && (_this.archive.tags.split(','));
      //   } else {
      //     if (data.need_auth) {
      //       this.$router.push({path: '/login'});
      //     } else {
      //       this.$notify({
      //         message: _this.$i18n.t('serverReply.errorArchiveRead'),
      //         timeout: 30000,
      //         icon: 'tim-icons icon-bell-55',
      //         horizontalAlign: 'center',
      //         verticalAlign: 'top',
      //         type: 'danger'
      //       });
      //     }
      //   }
      // }).catch(() => {});
      // window.open("user/csv", "download");
    },
    exportCsv(){
      window.open("user/csv", "download");
    },
    exportXls(){
      window.open("user/xls", "download");
    },
    hideImportUsers() {
      this.importUsers=false;
    },
    paginate() {
      //this.getUsers();
    },
    getUsers() {
      let _this = this;
      let obj = {
        from: (this.pagination.perPage * (this.pagination.currentPage - 1)) + 1,
        limit: this.pagination.perPage
				//limit: (this.pagination.perPage * (this.pagination.currentPage - 1)) + this.pagination.perPage
      };
      this.loading=true;
      axios.post('user/list', obj).then(response => {
        let data = response.data;
        _this.loading=false;
        if (data.success) {
          _this.tableData = data.data.map(function(element) {
            element.type = _this.userType[element.type];
            return element;
          });
          _this.total = data.total;
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorUsersRead'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: _this.$i18n.t('serverReply.errorProject'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    },
    updateUser(uuid) {
      this.$router.push({path: '/users/edit?uuid=' + uuid});
    },
    deleteUser(uuid) {
      let _this = this;
      swal({
        title: this.$i18n.t('usersPage.titleUserDelete'),
        text:  this.$i18n.t('usersPage.textUserDelete'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: this.$i18n.t('usersPage.textConfirmUserDelete'),
        cancelButtonText: this.$i18n.t('usersPage.textCancelUserDelete'),
        buttonsStyling: false
      }).then((resp) => {
        if (resp.value) {
          axios.post('user/delete', {uuid: uuid}).then(response => {
            let _this = this;
            let data = response.data;
            if (data.success) {
              swal({
                title: this.$i18n.t('usersPage.titleDeletedUser'),
                text:  this.$i18n.t('usersPage.textDeletedUser'),
                type: 'success',
                confirmButtonClass: 'btn btn-success btn-fill',
                buttonsStyling: false
              }).then(() => {
                _this.getUsers();
              });
            } else {
              if (data.need_auth) {
                _this.$router.push({path: '/login'});
              } else {
                _this.$notify({
                  message: _this.$i18n.t('serverReply.errorDeleteUser'),
                  timeout: 10000,
                  icon: 'tim-icons icon-bell-55',
                  horizontalAlign: 'center',
                  verticalAlign: 'top',
                  type: 'danger'
                });
              }
            }
          }).catch(() => {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorDeleteUser'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          });
        }
      });
    }
  },
  mounted() {},
  watch: {
    'pagination.currentPage'() {
      this.getUsers();
      window.scrollTo(0,0);
    }
  }
};
</script>
<style>
  div.card.users-card div.card-body {
    min-height: 500px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  tbody.user-table-body tr td.btn-container {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.user-table-body tr:hover td.btn-container {
    visibility: visible;
    opacity: 1;
  }
  tbody.user-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  button.import-users-margin {
    margin-right:1rem;
  }
  button.import-users {
    float: right;
  }
  .center-btn{
    text-align: center !important;
  }
</style>