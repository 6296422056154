<template>
    <div class="row agent-content">
        <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input
            v-bind:label="$t('projectForm.labelAnotherAgentName')"
            v-bind:placeholder="$t('projectForm.labelAnotherAgentName')"
            :disabled="statusOfUser"
            v-model="item.inchargeof">
            </base-input>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input
            v-bind:label="$t('projectForm.labelPhone')"
            v-bind:placeholder="$t('projectForm.placeholderPhone')"
            :disabled="statusOfUser"
            v-model="item.phone">
            </base-input>
        </div>
        <div class="remove-agent" v-if="!statusOfUser">
            <div class="closing-date-remove-btn btn-danger" @click="removeAgent()">
                <span><i class="tim-icons icon-simple-remove"></i>
                </span>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input
            v-bind:label="$t('projectForm.labelRole')"
            v-bind:placeholder="$t('projectForm.placeholderRole')"
            :disabled="statusOfUser"
            v-model="item.role">
            </base-input>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input
            v-bind:label="$t('projectForm.labelEmail')"
            v-bind:placeholder="$t('projectForm.placeholderEmail')"
            :disabled="statusOfUser"
            v-model="item.email">
            </base-input>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
            <base-input
            v-bind:label="$t('projectForm.labelOrganisation')"
            v-bind:placeholder="$t('projectForm.placeholderOrganisation')"
            :disabled="statusOfUser"
            v-model="item.organisation">
            </base-input>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6">
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-8 info-divider"></div>
        <div class="col-sm-2"></div>
    </div>
</template>
<script>
export default {
  name: 'agent-component',
  props: ['item', 'index','statusOfUser'],
  data () {
    return {};
  },
  methods: {
    removeAgent() {
        this.$emit('removeAgent', [this.index]);
    },
    keyDown(event) {
      
    }
  }
}
</script>
<style>
    div.agent-content {
        padding-left: 15px;
        padding-right: 15px;
    }
    div.remove-agent {
        position: absolute;
        right: 0px;
        margin-top: 60px;
        cursor: pointer;
        z-index:9;
    }
</style>
