<template>
  <div class="content">
     <div class="mt-3 text-right">
      <base-button class="" @click="$router.push('/users/list')" type="primary">
        {{ $t('userform.btnListUsers') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h3 class="card-title"></h3>
          </template>
          <tabs type="primary">
            <tab-pane v-bind:label="$t('userform.tab1')">
              <form class="form-horizontal">
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelUsername') }}</label>
                  <div v-if="mode=='create'" class="col-sm-10">
                    <base-input
                      name="login"
                      required
                      v-bind:placeholder="$t('userform.placeholderUsername')"
                      v-validate="modelValidations.required"
                      :error="getError('login')"
                      v-model="user.login">
                    </base-input>
                  </div>
                  <div v-else class="col-sm-10">
                    <base-input
                      name="login"
                      required
                      disabled
                      v-bind:placeholder="$t('userform.placeholderUsername')"
                      v-validate="modelValidations.required"
                      :error="getError('login')"
                      v-model="user.login">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelPassword') }}</label>
                  <div v-if="mode=='create'" class="col-sm-10">
                    <base-input
                      name="password"
                      required
                      type="password"
                      v-bind:placeholder="$t('userform.placeholderPassword')"
                      v-validate="modelValidations.password"
                      v-model="user.password"
                      :error="getError('password')">
                    </base-input>
                  </div>
                  <div v-else class="col-sm-10">
                    <base-input
                      name="epassword"
                      type="password"
                      v-bind:placeholder="$t('userform.placeholderPassword')"
                      v-validate="modelValidations.epassword"
                      :error="getError('epassword')"
                      v-model="user.epassword">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelName') }}</label>
                  <div class="col-sm-10">
                    <base-input
                      name="name"
                      required
                      v-bind:placeholder="$t('userform.placeholderName')"
                      v-validate="modelValidations.required"
                      :error="getError('name')"
                      v-model="user.name">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelTitle') }}</label>
                  <div class="col-sm-10">
                    <base-input
                      name="title"
                      v-bind:placeholder="$t('userform.placeholderTitle')"
                      v-model="user.title">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelEmail') }}</label>
                  <div class="col-sm-10">
                    <base-input
                      name="email"
                      type="email"
                      required
                      v-bind:placeholder="$t('userform.placeholderEmail')"
                      v-validate="modelValidations.email"
                      :error="getError('email')"
                      v-model="user.email">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelPhone') }}</label>
                  <div class="col-sm-10">
                    <base-input
                      name="phone"
                      v-bind:placeholder="$t('userform.placeholderPhone')"
                      v-model="user.phone">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelPost') }}</label>
                  <div class="col-sm-10">
                    <base-input
                      name="post"
                      v-bind:placeholder="$t('userform.placeholderPost')"
                      v-model="user.post">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">{{ $t('userform.labelLanguage') }}</label>
                  <div class="col-sm-10">
                    <el-select
                      class="test-select select-primary select-inline"
                      name="language"
                      size="large"
                      v-model="user.lang">
                        <el-option
                        v-for="option in [{value: 'fr', label: 'Français'}, {value: 'en', label: 'English'}]"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label" >{{$t('userform.tab1')}}</label>
                  <div class="col-sm-10 checkbox-radios">
                    <base-radio name="admin"  v-model="user.type">{{ $t('userform.labelType1') }}</base-radio>
                    <base-radio name="user"   v-model="user.type">{{ $t('userform.labelType2') }}</base-radio>
                    <base-radio name="userRes"   v-model="user.type">{{ $t('userform.labelType3') }}</base-radio>
                    <base-radio name="viewer" v-model="user.type">{{ $t('userform.labelType4') }}</base-radio>
                    <base-radio name="viewerRes" v-model="user.type">{{ $t('userform.labelType5') }}</base-radio>
                    <base-radio v-if="features.userTypeAdminGISRadioButtonInUserEdit.show" name="adminGis" v-model="user.type">{{ $t('userform.labelType6') }}</base-radio>
                  </div>
                </div>
              </form>
            </tab-pane>
            <tab-pane v-bind:label="$t('userform.tab2')">
              <!--{{ $t('userform.tab2') }}-->
              <div v-if="user.type=='admin' || user.type=='adminGis' || user.type=='user' || user.type=='userRes'">
                <div class="row margin-top-10">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-5">
                    <h4>{{ $t('userform.titleTags') }}</h4>
                  </div>
                </div>
                <div class="row margin-top-10">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div v-if='filters.district.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ filters.district.title.label }}</label>
                    <el-select multiple
                      class="select-primary"
                      v-bind:class="{ 'has-danger': errors.has('district')}"
                      name="district"
                      v-bind:placeholder="$t('projectForm.placeholderProjectDistrict')"
                      v-model="user.projsettings.district">
                      <el-option
                        v-for="option in filters.district.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                    <label class="error" v-show="errors.has('district')">{{ errors.first('district') }}</label>
                  </div>
                  <div v-if='filters.city.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ $t('projectForm.labelProjectCity') }}</label>
                    <el-select multiple
                      class="select-primary"
                      data-vv-name="city" 
                      label="name" 
                      size="large"
                      v-bind:placeholder="$t('projectForm.placeholderProjectCity')"
                      v-model="user.projsettings.city">
                      <el-option
                        v-for="option in filters.city.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                </div>
                <div class="row margin-top-10">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div v-if='filters.step.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ $t('projectForm.labelProjectStep') }}</label>
                    <el-select
                      class="select-primary select-inline"
                      v-bind:class="{ 'has-danger': errors.has('step')}"
                      name="step"
                      size="large"
                      clearable
                      v-bind:placeholder="$t('projectForm.placeholderProjectStep')"
                      v-model="user.projsettings.step">
                      <el-option
                        v-for="option in filters.step.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.entity.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ $t('projectForm.labelProjectEntity') }}</label>
                    <el-select  multiple
                      class="select-primary"
                      v-bind:class="{ 'has-danger': errors.has('entity')}"
                      name="entity"
                      size="large"
                      v-bind:placeholder="$t('projectForm.placeholderProjectEntity')"
                      v-model="user.projsettings.entity">
                      <el-option
                        v-for="option in filters.entity.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                </div>
                <div class="row margin-top-10">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div v-if='filters.status.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ $t('projectForm.labelProjectStatus') }}</label>
                    <el-select
                      class="select-primary select-inline"
                      v-bind:class="{ 'has-danger': errors.has('status')}"
                      name="status"
                      size="large"
                      clearable
                      v-bind:placeholder="$t('projectForm.placeholderProjectStatus')"
                      v-model="user.projsettings.status">
                      <el-option
                        v-for="option in filters.status.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.workType.show' class="col-sm-5 col-md-5 col-lg-5">
                    <label>{{ filters.workType.title.label }}</label>
                    <el-select  multiple
                      class="select-primary"
                      size="large"
                      v-bind:placeholder="$t('projectForm.placeholderProjectWorkType')"
                      v-model="user.projsettings.workType">
                      <el-option
                        v-for="option in filters.workType.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                </div>
                <div class="row margin-top-40">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-5">
                    <h4>{{ $t('userform.titleNbrClosing') }}</h4>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <h4>{{ $t('userform.titlePublicReport') }}</h4>
                  </div>
                </div>
                <div class="row margin-top-10">
                  <div class="col-sm-1 col-md-1 col-lg-1">
                  </div>
                  <div class="col-sm-5 col-md-5 col-lg-5">
                    <base-input
                      name="nbClosingDate"
                      numeric
                      v-model="user.nbClosingDate">
                    </base-input>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <base-checkbox v-model="user.publishReport" :checked="user.publishReport" class="publish-report"></base-checkbox>
                    <!--<base-switch v-model="user.publishReport" on-text="" off-text=""></base-switch>-->
                  </div>
                </div>
              </div>
              <div class="row margin-top-40">
                <div class="col-sm-1 col-md-1 col-lg-1">
                </div>
                <div class="col-sm-5 col-md-5 col-lg-5">
                  <h4>{{ $t('userform.TitleUserMapCenter') }}</h4>
                </div>
              </div>
              <div class="row margin-top-10">
                <div class="col-sm-2 col-md-2 col-lg-2">
                </div>
                <div class="col-sm-8 col-md-8 col-lg-8 map-container" >
                  <div id="mapview"></div>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                </div>
              </div>
            </tab-pane>
          </tabs>
          <template slot="footer" class="text-right">
            <base-button
              native-type="submit"
              @click.prevent="validate"
              class="pull-right"
              type="primary">{{ $t('userform.btnSave') }}</base-button>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { TabPane, Tabs } from 'src/components';
import { BaseCheckbox, BaseRadio, BaseSwitch } from 'src/components/index';
import { Select, Option } from 'element-ui';
import { loadModules } from 'esri-loader';
import filters from './../shared/filters';
import features from './../shared/features';

export default {
  components: {
    TabPane,
    Tabs,
    BaseCheckbox,
    BaseRadio,
    BaseSwitch,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  created () {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.user.location.longitude = this.instance.center.long;
    this.user.location.latitude = this.instance.center.lat;
    let user = JSON.parse(window.localStorage.getItem('user'));
    this.filters = filters[this.instance.city];
    this.features = features[this.instance.city];
    let keys = Object.keys(this.filters);
    let _this=this;
    keys.forEach((key) => {
      _this.filters[key].value.forEach((element) => {
        if (user.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      });
      let e = _this.filters[key];
      if (user.lang=='fr') {
        if (e.title && e.title.label_fr) {
          e.title.label=e.title.label_fr;
        }
      } else {
        if (e.title && e.title.label_en) {
          e.title.label=e.title.label_en;
        }
      }
    });
    //this.getFilters();
    if (this.$route.query && this.$route.query.uuid) {
      this.mode='edit';
      this.user.uuid=this.$route.query.uuid;
      this.getUser();
    }else {
      this.loadMap();
    }
  },
  watch: {
    // call the method again if the route changes
    '$route': 'resetUser'
  },
  data() {
    return {
      pointGraphic : null,
      centerSymbol : {
        type: "simple-marker",
        style: "cross",
        outline: { width: 1.5, color: [230, 0, 0, 1] },
        size: 15,
        color: [255, 255, 255, 0]
      },
      user: {
        uuid: null,
        login: '',
        password: '',
        name: '',
        email: '',
        title: '',
        phone: '',
        post: '',
        type: 'admin',
        lang: 'fr',
        publishReport: true,
        nbClosingDate: 1,
        location: {
          longitude: -71.282841,
          latitude: 46.843608
        },
        projsettings: {
          district:'',
          city: '',
          entity:'',
          step: '',
          status: '',
          workType: '',
        }
      },
      mode: 'create',
      filters: null,
      features: null,
      filterKeys: [],
      modelValidations: {
        required: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          min: 8
        },
        epassword: {
          min: 8
        },
        confirmPassword: {
          required: true,
          confirmed: 'password'
        },
        subscribe: {
          required: true
        }
      },
      instance : null
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      let _this=this;
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          _this.create(); 
        }
      });
    },
    resetUser() {
      this.user = {
        uuid: null,
        login: null,
        password: null,
        name: null,
        email: null,
        phone: null,
        post:'',
        type: 'admin',
        nbClosingDate: 1,
        projsettings: {
          district:null,
          city: null,
          entity:null,
          step: null,
          status: null,
          workType: null
        }
      };
      this.mode='create';
    },
    create() {
      let _this = this;
      let url;
      if (this.mode=='edit') {
        url='user/edit';
        if (_this.user.epassword) {
          _this.user.password = _this.user.epassword; 
        }
        if (!_this.user.appsettings.map) {
          _this.user.appsettings.map={
            typeMap: "streets",
            modeMap: "0"
          }
        }
      } else {
        url='user/create';
        this.user.appsettings = {
          "map": {
            "typeMap": "streets",
            "modeMap": "0"
          },
          "sidebarColor": "vue",
          "sidebarMini": false,
          "darkMode": true
        };
      }
      axios.post(url, _this.user).then(response => {
        let data = response.data;
        let connectedUser = JSON.parse(window.localStorage.getItem('user'));
        if (data.success) {
          if ((connectedUser.login == _this.user.login) && data.data) {
            window.localStorage.setItem('role', data.data.type);
            window.localStorage.setItem('user',JSON.stringify(data.data));
          }
          this.$notify({
            message: _this.$i18n.t('userform.successSaveUser'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          this.$router.push({path: '/users/list'});
          window.location.reload();
          
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else if(data.message=='already_exist') {
            _this.$notify({
              message: _this.$i18n.t('userform.errorUserAlreadyExist'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          } else {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorUserCreate'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {
        _this.$notify({
          message: _this.$i18n.t('serverReply.errorUserCreate'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });
    },
    getUser() {
      let _this=this;
      axios.get('user/edit?uuid='+_this.user.uuid).then(response => {
        let data = response.data;
        if (data.success) {
          _this.user = data.data;
          if (!_this.user.appsettings.map) {
            _this.user.appsettings.map={
              typeMap: "streets",
              modeMap: "0"
            }
          }
          _this.loadMap();
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorUserRead'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {});
    },
    loadMap() {
      loadModules([
      "esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Point",
      "esri/Graphic",
      "esri/layers/VectorTileLayer",])
      .then(([Map, BaseMap, MapView, FeatureLayer, SimpleLineSymbol, GraphicsLayer, Point, Graphic, VectorTileLayer]) => {
        let _this=this;
        // let connectedUser = JSON.parse(window.localStorage.getItem('user'));
        let connectedUser = _this.user;
        //let type = (connectedUser.appsettings && connectedUser.appsettings.map.typeMap) || 'osm';
        var basemap;
        if (connectedUser.appsettings.map && connectedUser.appsettings.map.modeMap && connectedUser.appsettings.map.modeMap==="1") {
          basemap = new BaseMap({
            portalItem: {id: connectedUser.appsettings.map.typeMap}
          });
        }
        else if (connectedUser.appsettings.map && connectedUser.appsettings.map.modeMap && connectedUser.appsettings.map.modeMap==="2"){
          basemap = new BaseMap({
            baseLayers: [
              new VectorTileLayer({
                portalItem: {
                  id: connectedUser.appsettings.map.typeMap
                }
              })
            ]
          });
        } 
        else {
          basemap = (connectedUser.appsettings.map && connectedUser.appsettings.map.typeMap) || 'osm'; 
        }
        this.map = new Map({
          basemap: basemap
        });
        
        let centerLong = connectedUser.location.longitude || -71.282841;
        let centerLat  = connectedUser.location.latitude  || 46.843608;
        let zoom       = connectedUser.location.zoom || 15;
        this.view = new MapView({
          container: "mapview",
          map: this.map,
          center: [centerLong, centerLat], // longitude, latitude
          zoom: zoom,
          spatialReference: 102100
        });
        
        this.drawLayer = new GraphicsLayer();
        this.map.add(this.drawLayer);
        let point = {
          type: "point",
          longitude: centerLong,
          latitude : centerLat
        };
        
        this.pointGraphic = new Graphic({
          geometry: point,
          symbol: this.centerSymbol
        });
  
        this.drawLayer.add(this.pointGraphic);
        this.view.goTo(this.pointGraphic);

        this.view.on("drag", function(evt) {
          //evt.stopPropagation();
          if(evt.action=='update' || evt.action === 'end'){
            if (_this.pointGraphic){_this.drawLayer.remove(_this.pointGraphic);}
            _this.pointGraphic = new Graphic({
              geometry : _this.view.extent.center,
              symbol : _this.centerSymbol
            });
            _this.drawLayer.add(_this.pointGraphic);
            _this.user.location.longitude = _this.view.extent.center.longitude;
            _this.user.location.latitude  = _this.view.extent.center.latitude;
            _this.user.location.zoom      = _this.view.zoom;
          }
          /*
          var screenPoint = {
              x: evt.x,
              y: evt.y
          };
          _this.view.hitTest(screenPoint).then(function(response) {
            var graphic = response.results[0].graphic;
            if (graphic && evt.action=='update') {
              evt.stopPropagation();
              _this.drawLayer.removeAll();
              let sym = {
                type: "simple-marker",
                color: "#00bf9a",
                size: "24px",
                outline: {
                  color: [255, 255, 255],
                  width: 2
                }
              };
              pointGraphic = new Graphic({
                geometry: response.results[0].mapPoint,
                symbol: sym
              });
              _this.drawLayer.add(pointGraphic);
              _this.user.location.longitude=response.results[0].mapPoint.longitude;
              _this.user.location.latitude=response.results[0].mapPoint.latitude;
            } else if (graphic && evt.action=='end') {
              
            }
          });*/
        });
        
        this.view.watch("scale", function(newVal, oldVal, name, target){
          if(oldVal !== newVal){
            if (_this.pointGraphic){_this.drawLayer.remove(_this.pointGraphic);}
            _this.pointGraphic = new Graphic({
              geometry : _this.view.extent.center,
              symbol : _this.centerSymbol
            });
            _this.drawLayer.add(_this.pointGraphic);
            _this.user.location.longitude = _this.view.extent.center.longitude;
            _this.user.location.latitude  = _this.view.extent.center.latitude;
            _this.user.location.zoom      = _this.view.zoom;
          }
        });
      });
    },
    getFilters() {
      /*
      axios.get('user/filters').then(response => {
        let _this = this;
        let data = response.data;
        if (data.success) {
          _this.filters = data.filters;
          _this.filterKeys = data.keys;
        } else {
          if (data.need_auth) {
            _this.$router.push({path: '/login'});
          } else {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorProjectFilters'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(e => {
        _this.$notify({
          message: _this.$i18n.t('serverReply.errorProjectFilters'),
          timeout: 10000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      });*/
    }
  }
};
</script>
<style lang="scss">
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .margin-top-40 {
    margin-top: 40px;
  }
  .map-container {
    height: 350px !important;
  }
  
  #mapview {
    padding: 0;
    margin: 0;
    height: 350px !important;
    width: 100%;
  }
  div.form-check.publish-report {
    margin-top: -5px;
  }
</style>