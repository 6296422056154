<template>
  <div v-show="enabledHelp">
    <div class="help-container">
      <div class="help-switch">
        <base-button default size="sm" type="default" @click="() => {showHelp = !showHelp;}"> 
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9996 7.99899C21.9994 7.82052 21.9514 7.64535 21.8607 7.49166C21.77 7.33798 21.6398 7.21137 21.4836 7.12499L12.4616 2.12499C12.3133 2.04332 12.1468 2.00049 11.9776 2.00049C11.8083 2.00049 11.6418 2.04332 11.4936 2.12499L2.51558 7.08499C2.35961 7.17136 2.22957 7.29785 2.13892 7.45136C2.04827 7.60487 2.00031 7.77983 2 7.95811C1.9997 8.13639 2.04706 8.31151 2.13718 8.46534C2.2273 8.61916 2.35691 8.7461 2.51258 8.83299L11.5346 13.873C11.6831 13.9564 11.8506 14.0004 12.0209 14.0005C12.1913 14.0007 12.3589 13.9571 12.5076 13.874L21.4856 8.87399C21.6415 8.78727 21.7714 8.6604 21.8618 8.50654C21.9522 8.35268 21.9998 8.17744 21.9996 7.99899ZM12.0226 11.854L5.05958 7.96499L11.9766 4.14299L18.9406 8.00199L12.0226 11.854Z" fill="white"/>
            <path d="M20.5166 11.126L12.0016 15.856L3.48662 11.126L2.51562 12.874L11.5156 17.874C11.6641 17.9565 11.8312 17.9997 12.0011 17.9997C12.171 17.9997 12.3381 17.9565 12.4866 17.874L21.4866 12.874L20.5166 11.126Z" fill="white"/>
            <path d="M20.5166 15.126L12.0016 19.856L3.48662 15.126L2.51562 16.874L11.5156 21.874C11.6641 21.9565 11.8312 21.9997 12.0011 21.9997C12.171 21.9997 12.3381 21.9565 12.4866 21.874L21.4866 16.874L20.5166 15.126Z" fill="white"/>
            </svg>
           &nbsp;&nbsp;{{ $t('helpWidget.legende') }}
        </base-button>
      </div>
      <div class="help-content-public" v-bind:class="{'help-minimise': !showHelp}">
        <dl class="dl-horizontal" style="margin-bottom: 45px;" ><dt style="width: 120px !important;">{{ $t('helpWidget.legende') }} <!--{{ $t('helpWidget.titleSymbole') }}--> </dt><dd> <!--{{ $t('helpWidget.titleDescription') }}--></dd></dl>
        <dl class="dl-horizontal">
          <dt v-show="groupEntrave">
            <div class="entraves-public-container"><div class="entraves-img"></div><div class="entraves-img"></div><div class="entraves-img"></div></div>
          </dt>
          <dd v-show="groupEntrave">{{ $t('helpWidget.titleGroupEntraves') }}</dd>
          <dt v-show="!groupEntrave">
            <div class="entraves-public-container"><div class="entraves-img"></div><div class="entraves-img"></div><div class="entraves-img"></div></div>
          </dt>
          <dd v-show="!groupEntrave">{{ $t('helpWidget.titleEntraves') }}</dd>
          <div v-if="instance!='gatineau'">
            <dt v-show="!groupEntrave">
              <div class="closing-container"><div class="closing-img"></div><div class="closing-img"></div><div class="closing-img"></div></div>
            </dt>
            <dd v-show="!groupEntrave"> {{ $t('helpWidget.titleClosing') }}</dd>
          </div>
          <dt>
            <div class="detour-container"><div class="detour-img">➡</div><div class="detour-img">➡</div><div class="detour-img">➡</div></div>
          </dt>
          <dd>{{ $t('helpWidget.titleDetour') }}</dd>
          <dt v-show="showLegend" class="legend-title"><!--{{ $t('helpWidget.legende') }}--></dt>
          <dd v-show="showLegend" style="margin-top:30px;" :class="{'legend-custom':showSvg}">
            <div style="margin-bottom: 10px; display:grid;">
              <div v-for="(item,index) in entityLegend" v-bind:index="index" v-bind:key="index" class='legend-content' :class="{'showSvg': showSvg}">
                <img :src="item.iconData" v-if="showSvg" class="legend-svg" />
                <div v-else  class="legend-entity " :class="[item.class1, item.class]"></div>
                <img v-show="showLegendImg" v-bind:src="item.src" class="legend-img" /> 
                <span class="legend-text">{{item.label}}</span>
              </div>
            </div>
          </dd>
          </dl>
          <dl class="dl-horizontal" style="margin-bottom: 55px;" ><dt style="width: 150px !important;"><span class="how-to-use-switch" @click="toggleHowToUse()">{{ $t('helpWidget.howToUse') }} {{ isPlus ? '+' : '-' }}</span></dt>
          </dl>
          <div id="how-to-use-div" v-show="!isPlus" class="dl-horizontal">
            <div class="how-to-use"> <img src="../../public/img/legend/project-pin.svg" class="how-to-use-img" /> <div class="how-to-use-text">{{ $t('helpWidget.howToUsePoint1') }}</div></div>
            <div class="how-to-use"> <span aria-hidden="true" class="esri-popup__icon esri-icon-forward"></span> <div class="how-to-use-text">{{ $t('helpWidget.howToUsePoint2') }}</div> </div>
              <div class="how-to-use"> <div class="how-to-use-text"> <span id="notice-of-work">{{ $t('helpWidget.noticeOfWork') }}</span> {{ $t('helpWidget.howToUsePoint3') }}</div> </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {BaseSwitch} from 'src/components/index';
  import Tags from './../app/shared/filters';
  import Icons from '../app/shared/icons';

  export default {
    name: 'help-public',
    components: {
      BaseSwitch
    },
    props: ['lang', 'instance', 'enabledHelp', 'groupEntrave', 'openLegend'],
    computed: {},
    methods: {
      toggleHowToUse(){
        this.isPlus = !this.isPlus;
      }
    },
    mounted() {
      //let instance = JSON.parse(window.localStorage.getItem('instance')) || 'quebec';
      let _this=this;
      if (this.openLegend) {
        this.showHelp=this.openLegend;
      }
      this.$root.$i18n.locale = this.lang;
      let filters = Tags[this.instance];
      this.showLegend=Object.keys(filters.entity.classes).length>0;
      this.showLegendImg=filters.entity.legendImg;
      this.entityLegend=filters.entity.value;
      this.entityLegend.forEach((element)=> {
        if (element.icon) {
          element.src='static/img/' + element.icon;
        }
        if (_this.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      });
      if (this.instance==='gatineau') {
        this.entityLegend = this.entityLegend.filter(function(e) {
          if (e.value=='6') return e;
          if (e.value=='1') return e;
          if (e.value=='2') return e;
          if (e.value=='8') return e;
          // else if(e.value=='1') {
          //   e.label='Ville'; e.label_fr='Ville'; e.label_en='City'
          //   return e;
          // }
          // else if(e.value=='9') {
          //   e.label='Autres'; e.label_fr='Autres'; e.label_en='Others'
          //   return e;
          // }
        }); 
      }
      if (this.instance==='mississauga') {
        this.showSvg = true;
        let newLabel = `${this.entityLegend[0].label}, ${this.entityLegend[1].label}, ${this.entityLegend[4].label}, ${this.entityLegend[7].label}`;
        this.entityLegend = this.entityLegend.filter(function(e) {
          // if (e.value=='1'){
          //   e.label = newLabel;
          //   e.iconData = "static/img/icons/cone.svg";
          //   e.color = '#ED732E'
          //   return e;
          // }
          if (e.value=='1') {e.iconData = "static/img/icons/new/cone.svg"; e.color = '#FF8C00';return e;}
          if (e.value=='2') {e.iconData = "static/img/icons/new/cone.svg"; e.color = '#ffff00';return e;}
          if (e.value=='4') {e.iconData = "static/img/icons/new/cone.svg"; e.color = '#b87332';return e;}
          if (e.value=='8') {e.iconData = "static/img/icons/new/cone.svg"; e.color = '#AA91E4';return e;}
          if (e.value=='3') {e.iconData = "static/img/icons/new/setting.svg"; e.color = '#1C02DE';return e;}
          if (e.value=='6') {e.iconData = "static/img/icons/new/event.svg"; e.color = '#FFD700'; return e;}
          if (e.value=='7') {e.iconData = "static/img/icons/new/emergency.svg"; e.color = '#DC143C'; return e;}
          if (e.value=='10'){e.iconData = "static/img/icons/new/rail.svg"; e.color = '#32CD32'; return e};
        });
      }
  },
    data () {
      return {
        showHelp: false,
        showLegend: false,
        showLegendImg: false,
        showSvg: false,
        entityLegend: [],
        isPlus: true,
      };
    }
  };
</script>
<style>
  .help-text {
    z-index: 3;
    position: absolute;
    right: 18px;
    top: 57px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
  }
  .help-text.help-text-white {
    color: #FFF;
  }
  .help-container {
    position: absolute;
    right: 5px;
    top: 50px;
  }
  .help-switch {
    position: relative;
    right: 12px !important;
    z-index: 2;
    margin-top: 10px;
  }
  .help-content-public {
    min-height: 100px;
    position: absolute;
    right: -10px;
    top: -60px;
    background-color: #F0F;
    z-index: 1;
    background: rgba(0,0,0,.6);
    width: 350px;
    opacity: 1;
    transform: translate(-10px,60px);
    border-radius: 5px;
    border: 1px solid #FFF;
    box-shadow: #000 0 0 5px;
    padding: 10px;
    color: #FFF;
    transition: background 150ms,border 150ms;
    display: block;
  }
  .help-content-public dd {
    margin-left: 40px;
  }
  .help-minimise {
    background: 0 0;
    border: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    color: #000;
    display: none;
  }
  dl {
    margin-top: 0;
    margin-bottom: 22px;
  }
  dt {
    width: 130px;
    font-weight: 500;
  }
  .dl-horizontal dt {
    float: left;
    text-align: left !important;
    /*
    width: 130px;
    clear: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;*/
  }
  .help-content-public .dl-horizontal dt {
    width: 60px !important;
  }
  dd {
    margin-left: 140px;
  }
  kbd {
    background-color: #f7f7f7;
    /*
    width: 70px;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.2),0 0 0 2px #fff inset;
    box-shadow: 0 1px 0 rgba(0,0,0,.2),0 0 0 2px #fff inset;
    */
    color: #333;
    display: inline-block;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    line-height: 1.4;
    /*margin: 0 .1em;
    padding: .1em .6em;
    text-shadow: 0 1px 0 #fff;
    */
    border-radius: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin: 0px;
    padding: 0px;
    text-shadow: none;
  }
  .detour-container {
    color: rgba(0,0,0,.6);
  }
  div.detour-img {
    /*background-color: rgba(0,0,0,.6);*/
    /*width: 10px;
    margin-right: 5px;
    */
  }
  div.closing-img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: rgba(0,0,0,.6);
  }
  div.entraves-img {
    width: 15px;
    height: 3px;
    margin-bottom: 3px;
    background-color: rgba(0,0,0,.6);
  }
  .entraves-public-container {
    margin-top: 5px;
    display: flex;
    background-color: #fff;
    width: 45px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    height: 12px;
    padding-top: 5px;
  }
  .detour-container,
  .closing-container {
    display: flex;
    /*padding: 5px;*/
    background-color: white;
    width: 45px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .closing-container {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  div.legend-entity {
    z-index: 8;
    width: 60px;
    height: 3px;
    position: relative;
    top: 8px;
    /*margin-bottom: 3px;
    margin-left:2px;
    margin-right:2px;
    background-color: rgba(0,0,0,.6);*/
  }

  div.legend-entity.bgreen {
    background-color: #01b050;
  }

  div.legend-entity.byellowgreen {
    /*background: linear-gradient(to left, #ffff02, #01b050);*/
    background-color: #ffff02;
  }
  div.legend-entity.borange {
    background-color: #ed7d31;
  }
  div.legend-entity.bblue {
    background-color: #01b0f0;
  }

  /** Others entity colors **/

  div.legend-entity.brown {
    background-color: #01b0f0;
  }
  div.legend-entity.blue_duck {
    background-color: #009aa2;
  }
  div.legend-entity.pink {
    background-color: #e97cbf;
  }
  div.legend-entity.green {
    background-color: #00e045;
  }
  div.legend-entity.blue {
    background-color: #8fc4e6;
  }
  div.legend-entity.orange {
    background-color: #ff5a00;
  }

  div.legend-entity.gat-green2 {
    background-color: #92d050;
  }
  div.legend-entity.gat-green3 {
    background-color: #316f7b;
  }
  div.legend-entity.gat-orange {
    background-color: #ffc001;
  }
  div.legend-entity.gat-blue2 {
    background-color: #0070c0;
  }
  div.legend-entity.gat-purple {
    background-color: #7030a0;
  }
  div.legend-entity.gat-purple1 {
    background-color: #5b2e8f;
  }
  div.legend-entity.gat-purple2 {
    background-color: #AA91E4;
  }
  div.legend-entity.gat-blue3 {
    background-color: #0E4095;
  }
  div.legend-entity.gat-red {
    background-color: #E8464A;
  }
  div.legend-entity.gat-yellow {
    background-color: #ffff02;
  }
  div.legend-entity.gat-green1 {
    background-color: #00b050;
  }
  div.legend-entity.gat-rose  {
    background-color: #FF007F;
  }
  div.legend-entity.gat-lightred  {
    background-color: #FFCCCB;
  }
  div.legend-entity.gat-mauve  {
    background-color: #BB85AB;
  }
  div.legend-entity.gat-turquois  {
    background-color: #30D5C8;
  }
  div.legend-entity.gat-jaune  {
    background-color: #a18200;
  }
  div.legend-entity.gat-orange-light{
    background-color: #ED732E;
  }
  div.legend-entity.gat-orange-light2{
    background-color: #F4B754;
  }
  div.legend-entity.gat-green-dark{
    background-color: #2E6B4D;
  }
  div.legend-entity.gat-blue-dark{
    background-color: #1C02DE;
  }
  div.legend-entity.gat-green-light{
    background-color: #84B938;
  }
  div.legend-entity.gat-pink-light{
    background-color: #ee7deb;
  }
  div.legend-entity.gat-red-light{
    background-color: #E8464A;
  }
  div.legend-entity.hidden {
    visibility: hidden;
  }
  div.legend-entity.gat-blue1 {
    background-color: #01b0f0;
  }
  dt.legend-title {
    margin-top: 20px;
  }
  div.legend-content {
    display: grid;
    grid-template-columns: 60px auto;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  div.legend-content.showSvg{
    grid-template-columns: 30px auto;
    align-items: center;
  }
  .legend-img {
    height:20px;
    width:20px;
    margin-left:-40px;
    z-index:9;
  }
  span.legend-text {
    margin-left: 30px;
  }
  .how-to-use-img{
    width:16px;
    z-index:9;
    border-radius: 0px;
  }
  .how-to-use-switch{
    cursor: pointer;
  }
  #notice-of-work{
    font-weight: 900;
    color: #0e85e5;
    font-style: normal;
  }
  .how-to-use{
    display: flex;
    align-items: center;
  }
  .how-to-use-text{
    margin-left: 5px;
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  #how-to-use-div{
    animation: fadeIn 0.5s;
    font-style: italic;
  }
  .legend-svg{
    height: 35px;
    z-index: 9;
  }
  .legend-custom{
    margin-left: 8px !important;
  }
</style>