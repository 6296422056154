<template>
    <div v-if="send" class="loading" >
      <img src="../../../public/img/loading.gif" />
    </div>
    <div v-else class="project-form-create row d-flex justify-content-center">
      <div class="col-md-12">
        <simple-wizard @savePublishExit="savePublishExit" @reportPDF="reportPDF"  nextButtonText="" prevButtonText="" finishButtonText="" v-bind:statusOfUser="isViewer" :loading="loading" :autoSave="!!features.autoSave && features.autoSave.show">
          <template slot="header">
            <h6 class="card-title"></h6>
          </template>
          
          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-alert-circle-exc"></i>
              <p>{{ $t('projectForm.entravesTitle') }}</p>
            </template>
            <second-step ref="step2" v-bind:uuid="wizardModel.uuid" v-bind:entraves="wizardModel.entraves" v-bind:mode="mode" @on-validated="onStepValidated" v-bind:layer="wizardModel.entraves.layer" v-bind:statusOfUser="isViewer"  v-on:onChangeLayer="onChangeLayer($event)"></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-notes"></i>
              <p>{{ $t('projectForm.informationTitle') }}</p>
            </template>
            <first-step ref="step1" v-bind:filters='tags' v-bind:name="wizardModel.name" v-bind:informations="wizardModel.informations" @add-agent="onAddAgent" @on-validated="onStepValidated" v-bind:statusOfUser="isViewer" v-bind:archives="archives"></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-pin"></i>
              <p style="width: 150px;">{{ $t('projectForm.closingTitle') }}</p>
            </template>
            <third-step ref="step3" v-bind:uuid="wizardModel.uuid" v-bind:entraves="wizardModel.entraves.entraves" v-bind:layer="wizardModel.entraves.layer" v-bind:phases="wizardModel.phases" v-bind:sdate="sdate" v-bind:edate="edate" v-bind:perimeter="wizardModel.entraves.perimeter" v-bind:mode="mode"  @on-validated="onStepValidated" v-bind:statusOfUser="isViewer"></third-step>
          </wizard-tab>
          
          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="tim-icons icon-compass-05"></i>
              <p style="width: 150px;">{{ $t('projectForm.detourTitle') }}</p>
            </template>
            <four-step ref="step4" v-bind:uuid="wizardModel.uuid" v-bind:sdate="sdate" v-bind:edate="edate" v-bind:layer="wizardModel.entraves.layer" v-bind:phases="wizardModel.phases" v-bind:perimeter="wizardModel.entraves.perimeter" v-bind:mode="mode" @on-validated="onStepValidated" v-bind:statusOfUser="isViewer"></four-step>
          </wizard-tab>
        </simple-wizard>
      </div>
      <report-project v-bind:reportProject="reportProject" v-bind:reportProjectUUID="reportProjectUUID" v-bind:reportProjectInfo="reportProjectInfo" v-on:hideReportProject="hideReportProject($event)" /></report-project>  
    </div>
</template>
<script>
import axios from 'axios';
import FirstStep from './InformationsStep.vue';
import SecondStep  from './Entraves.vue';
import ThirdStep from './ClosingStep.vue';
import FourStep from './DetourStep.vue';
import { SimpleWizard, WizardTab } from 'src/components';
import tags from './../shared/filters';
import { uuid } from 'vue-uuid';
import i18n from './../../i18n';

import { loadModules } from 'esri-loader';
import LoadProjects from './../shared/MapLoadProjects.js';
import features from './../shared/features';
import ReportProject from './ReportProject';

/*
var passanger = [
  {key:'type1', label: i18n.t('projectForm.passangerType1'), status: false, value: ''},
  {key:'type2', label: i18n.t('projectForm.passangerType2'), status: false, value: ''},
  {key:'type3', label: i18n.t('projectForm.passangerType3'), status: false, value: ''},
  {key:'type4', label: i18n.t('projectForm.passangerType4'), status: false, value: ''},
  {key:'type5', label: i18n.t('projectForm.passangerType5'), status: false, value: ''},
  {key:'type6', label: i18n.t('projectForm.passangerType6'), status: false, value: ''},
  {key:'type7', label: i18n.t('projectForm.passangerType7'), status: false, value: ''}
];
*/
export default {
  data() {
    return {
      sdate: '',
      edate: '',
      wizardModel: {
        informations: {
          name:'',
          comment:'',
          notes:'',
          inchargeof:'',
          phone:'',
          email:'',
          role:'',
          agents:[],
          district:'',
          city: '',
          entity:[],
          step: '',
          status: '',
          workType: [],
          serviceInfra: '',
          servicePublic: '',
          workType1: [],
          workCategory: [],
          sector: [],
          dista: [],
          distp: '',
          publish: false,
          publishReport: false,
          citizenOpinions: false,
          notification: false,
          internalCommunication: false,
          externalCommunication: false,
          publicWork: false,
          publicMap: false,
          publicMapWork: false,
          typeMap: {
            waze: false,
            map: false
          },
          passanger: [],
          trafficImpact: [],
          portableWater: [],
          documents: {
            allowed: false,
            plan: false,
            other: ''
          },
          typeProject: 'linear',
          requestDate : '',
          authorizeDate : '',
          cost: '',
          departments : []
        },
        entraves: {
          date: '',
          perimeter: 20,
          entraves: [{
            selected: {
              path : [],
              startPoint : null,
              endPoint : null,
              streetsTrack : []
            },
            type: 'linear',
            value: '',
            label: '',
            name: '',
            showError: false,
            entraveId: uuid.v1(),
          }],
          layer: 'roadworks',
          validateEntraves: false
        },
        phases: [],
        projectType:{
          isQuick: false,
          isDraft: true,
          isPublished: false,  
        },
      },
      archives: [],
      name: '',
      send: false,
      mode: 'create',
      tags: null,
      loading: false,
      city : '',
      features: [],
      projectForm:{
        isRecordSaved: false,
        isEditMode: false,
        isSaving: false,
        saveError: null
      },
      isFinish: false,
      reportProject: false,
      reportProjectUUID: null,
      alertProjectUUID: null,
      alertProjectPublish: null,
      reportProjectInfo: {
        reportDistrict: null,
        reportWorkType: null,
        reportPhasesLen: 0,
        reportPhases: []
      },
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourStep,
    SimpleWizard,
    WizardTab,
    ReportProject
  },
  computed: {
    isViewer() {
      return window.localStorage.getItem('role')==='viewer';
    },
    isViewerRes() {
      return window.localStorage.getItem('role')==='viewerRes';
    },
  },
  created () {
    let instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city=instance.city;
    this.features = features[this.city];
    this.getProject()
  },
  methods: {
    async validateStep(ref) {
      if( await this.$refs[ref].validate()){
        if(ref === 'step4') {
          this.loading = true;
        }else{
          if(!!this.features.autoSave && this.features.autoSave.show){
            this.saveDraft(ref);
          }
        }
        return true;
      }else{
        return false;
      }
    },
    onStepValidated(validated, step, model) {
      if (validated) {
        if (step=='information') {
          let tags = Object.keys(this.tags);
          tags.forEach(function(filter) {
            if (model[filter] && (Array.isArray(model[filter]))) {
              model[filter] = model[filter].sort((a, b) => a - b);
            }
          });
          this.wizardModel.informations = model;
        } else if (step=='entraves') {
          model.perimeter = typeof(model.perimeter)==="number"?model.perimeter:parseFloat(model.perimeter);
          this.sdate = model.date[0];
          this.edate = model.date[1];
          //this.project.entraves = model.entraves;
          this.wizardModel.informations.typeProject = this.typeProject(model.entraves);
          this.wizardModel.entraves = model;
          if (this.wizardModel.informations.name=='') {
            this.wizardModel.informations.name=this.wizardModel.entraves.entraves[0].name;
          }
        } else if (step=='closing') {
          //this.project.phases=model;
          this.wizardModel.phases = model;
        } else if (step=='detour') {
          this.wizardModel.projectType.isPublished = true;
          this.wizardModel.projectType.isDraft = false;
          this.conflictSearch();
        } 
      }
    },

    sendAutoAlert(uuid){
      let obj = {
        uuid: uuid,
        lang: this.$root.$i18n.locale,
        type: 2,
        phone: "",
        email: this.wizardModel.informations.email,
        period: [
          172800000
        ],
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      if(obj.email != ""){
        axios.post('/notification/add', obj).then(response => {
          let data = response.data;
          if (data.success) {
            this.$notify({
              message: this.$i18n.t('serverReply.messageAlertSuc'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
          } 
        }).catch(() => {
          this.$notify({
            message: this.$i18n.t('serverReply.errorAlertProject'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        });
      }else{
        this.$notify({
            message: this.$i18n.t('serverReply.errorAutoAlertEmail'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
      }
    },

    saveProject() {
      let _this = this;
      this.isFinish = true;
      let url = 'project/create';
      if (_this.mode=='edit') {
        url = 'project/edit';  
      }
      if (!_this.send) {
        _this.send=true;
        var projectData = this.formatProject(this.wizardModel);
        if (this.wizardModel.entraves.entraves.length===0) {
          this.$notify({
            message: this.$i18n.t('serverReply.entraveMandatory'),
            timeout: 30000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
          return;
        }
        axios.post(url, this.wizardModel).then(response => {
          _this.send=false;
          let data = response.data;
          if (data.success) {
            
            this.$notify({
              message: this.$i18n.t('projectForm.succesSave'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
            
            if(this.city == "gatineau" && (this.wizardModel.informations.serviceInfra.length > 0 )){
              if(_this.mode == "create"){
                // this.sendAutoAlert(this.wizardModel.uuid);
                this.sendAutoAlert(data.uuid);
              }
              
            }

            this.$router.push({path: '/projects/list'});
            
          } else {
            this.loading = false;
            if (data.need_auth) {
              this.$router.push({path: '/login'});
            } else {
              this.$notify({
                message: this.$i18n.t('serverReply.errorCreateProject'),
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch((e) => {
          this.loading = false;
          console.log("err",e)
        }); 
      } else {
        return;
      }
    },
    typeProject(entraves) {
      let type='ponctuel';
      entraves.forEach(function(element) {
        if (element.type=='linear') {
          type='linear';
        }
      });
      return type;
    },
    calculateConflicts(projects) {
      var _this=this;
      loadModules(["esri/geometry/geometryEngine"])
      .then(([geometryEngine]) => {
        let ProjectsConflict=[];
        let project=this.wizardModel;
        let perimeter = project.entraves.perimeter;
        let publish = project.informations.publish;
        let type = _this.typeProject(project.entraves.entraves);
        let conflictsUnits = "meters";
        let check = { entrave: true, closing: true, detour: true, date: project.entraves.date };
        project.entraves.entraves.forEach((entrave, index) => {
          let geoms, dists = [], conflictsBuffer=null;
          geoms = entrave.selected.path.map(function(object){
            dists.push(perimeter);
            return object.geometry;
          });
          let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
          conflictsBuffer = buff[0];
          let results = LoadProjects.checkConflicts('entrave', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
          if (results.length > 0) {
            let object = {
              uuid: _this.wizardModel.uuid,
              step: 'entrave',
              publish: publish,
              type: type,
              name: _this.wizardModel.informations.name,
              entraveIndex: (index+1),
              entraveId: entrave.entraveId,
              value: entrave.value,
              dates: project.entraves.date,
              conflicts: results
            };
            ProjectsConflict.push(object);
          }
        });
        project.phases.forEach((phase, phaseIndex) => {
          phase.closing.forEach((close, closeIndex) => {
            check.pdates = close.dates;
            let geoms, dists = [], conflictsBuffer=null;
            geoms = close.selected.path.map(function(object){
              dists.push(perimeter);
              return object.geometry;
            });
            let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
            conflictsBuffer = buff[0];
            let results = LoadProjects.checkConflicts('close', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
            if (results.length > 0) {
              let object = {
                uuid: _this.wizardModel.uuid,
                step: 'closing',
                publish: publish,
                type: type,
                name: _this.wizardModel.informations.name,
                phaseIndex: (phaseIndex+1),
                phaseId: phase.phaseId,
                closeIndex: (closeIndex+1),
                closingId: close.closingId,
                value: close.value,
                dates: close.dates,
                conflicts: results
              };
              ProjectsConflict.push(object);
            }
            close.detours.forEach((detour, detourIndex) => {
              check.pdates = detour.dates;
              let geoms, dists = [], conflictsBuffer=null;
              geoms = detour.selected.path.map(function(object){
                dists.push(perimeter);
                return object.geometry;
              });
              let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
              conflictsBuffer = buff[0];
              let results = LoadProjects.checkConflicts('detour', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
              if (results.length > 0) {
                let object = {
                  uuid: _this.wizardModel.uuid,
                  step: 'detour',
                  publish: publish,
                  type: type,
                  name: _this.wizardModel.informations.name,
                  phaseIndex: (phaseIndex+1),
                  phaseId: phase.phaseId,
                  closeIndex: (closeIndex+1),
                  closingId: close.closingId,
                  detourIndex: (detourIndex+1),
                  detourId: detour.detourId,
                  value: detour.value,
                  dates: detour.dates,
                  conflicts: results
                };
                ProjectsConflict.push(object);
              }
            });
          });
        });
        _this.wizardModel.conflicts=ProjectsConflict;
        if(!!this.features.autoSave && this.features.autoSave.show){
          this.saveDraft("step4");
        }else{
          this.saveProject();
        }
      });
    },
    conflictSearch() {
      let filters = {
        publish: true,
        nopublish: true,
        range: this.wizardModel.entraves.date || [],
        "tags": {
            "step": [ "", "1", "2", "3", "4", "5", "6" ] 
        },
      };
      let _this=this;

      if(this.isViewer){
        _this.$notify({
            message: 'Back to project list',
            timeout: 1000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        this.$router.push({path: '/projects/list'});
      }else{
         LoadProjects.loadProjects(filters, function(success, data) {
          if (success) {
            let projects=data.filter((e) => { if (e.uuid!==_this.wizardModel.uuid) return e;});
            projects = projects.filter(item => !!item.data.projectType?item.data.projectType.isPublished:true) 
            if (projects && projects.length > 0) {
              _this.calculateConflicts(projects);
            } else {
              if(!!_this.features.autoSave && _this.features.autoSave.show){
                _this.saveDraft("step4");
              }else{
                _this.saveProject();
              }
            }
          } else {
            _this.$notify({
              message: 'Une erreur s\'est produite lors du chargement des projets.',
              timeout: 1000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        });
      }
    },
    formatProject(project){
      let _this = this;
      var formatted = project;
      var entraves, phases;
      entraves = project.entraves.entraves.map(function(entrave){
        return _this.formatEntrave(entrave);
      });
      
      formatted.entraves.entraves = entraves;
      phases = project.phases.map(function(phase){
        var formattedP = phase;
        var closings = formattedP.closing.map(function(closing){
          var formattedC = _this.formatClosing(closing);
          return formattedC;
        });
        formattedP.closing = closings;
        return formattedP;
      });
      return formatted;
    },
    formatEntrave(entrave){
      let _this = this;
      var formatted = entrave;
      var selected = entrave.selected;
      selected.path = entrave.selected.path.map(function(object){
        var formattedObj = {
          geometry   : _this.formatGeometry(object.geometry),
          attributes : object.attributes,
        };
        if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
        if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
        return formattedObj;
      });
      if(entrave.selected.startPoint) {selected.startPoint = _this.formatGeometry(entrave.selected.startPoint);}
      if(entrave.selected.endPoint) {selected.endPoint   = this.formatGeometry(entrave.selected.endPoint);}
      formatted.selected = selected;
      return formatted;
    },
    
    formatClosing(closing){
      let _this = this;
      var formatted = closing;
      var selected  = closing.selected;
      var detours;
      if (closing.selected.path) {
        selected.path = closing.selected.path.map(function(object){
          var formattedObj = {
            geometry   : _this.formatGeometry(object.geometry),
            attributes : object.attributes,
          };
          if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
          if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
          return formattedObj;
        });
      }
      if(closing.selected.startPoint){selected.startPoint = _this.formatGeometry(closing.selected.startPoint);}
      if(closing.selected.endPoint){selected.endPoint = _this.formatGeometry(closing.selected.endPoint);}
      formatted.selected= selected;
      
      detours = closing.detours.map(function(detour){
        return _this.formatDetour(detour);
      });
      formatted.detours = detours;
      return formatted;
    },
    
    formatDetour(detour){
      let _this = this;
      var formatted = detour;
      var selected = detour.selected;
      if (detour.selected.path){
        selected.path = detour.selected.path.map(function(object){
          var formattedObj = {
            geometry : _this.formatGeometry(object.geometry),
            attributes : object.attributes
          };
          if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
          if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
          
          return formattedObj;
        });
      }
      if(detour.selected.startPoint){selected.startPoint = _this.formatGeometry(detour.selected.startPoint);}
      if(detour.selected.endPoint){selected.endPoint = _this.formatGeometry(detour.selected.endPoint);}
      formatted.selected = selected;
      return formatted;
    },
    
    formatGeometry(geometry){
      var formatted;
      if(geometry.type === "polyline"){
        formatted = {
          type  : geometry.type,
          paths : geometry.paths,
          hasM  : geometry.hasM,
          hasZ  : geometry.hasZ,
          spatialReference : geometry.spatialReference
        };
      }else if(geometry.type === "point"){
        formatted = {
          type : geometry.type,
          latitude  : geometry.latitude,
          longitude : geometry.longitude,
          x : geometry.x,
          y : geometry.y,
          spatialReference : geometry.spatialReference,
          hasM : geometry.hasM,
          hasZ : geometry.hasZ
        };
      }
      return formatted;
    },
    onChangeLayer(value){
      this.wizardModel.entraves.layer = value;
    },
    getProject(){
      let _this = this;
      _this.tags = tags[_this.city];
      
      if (_this.$route.query && _this.$route.query.uuid) {
        _this.mode='edit';
        _this.wizardModel.uuid=_this.$route.query.uuid;
        _this.projectForm.isRecordSaved = true;
        axios.get('project/edit?uuid='+_this.wizardModel.uuid).then(response => {
          let data = response.data;
          if (data.success) {
            data.archives.forEach(archive => {
              archive.publishedLabel = archive.published ? '<i class="tim-icons icon-check-2 success"></i>':'<i class="tim-icons icon-simple-remove"></i>';
              archive.fTags = '';
              archive.tags.split(',').forEach((tag) => {
                  archive.fTags += '<span class="badge badge-info">' + tag + '</span> ';
              });
              _this.archives.push(archive)
            })
            LoadProjects.reloadGeometries([data.data], function(success, results){
              let project = results[0].data;
              _this.wizardModel.informations = project.informations;
              if (!_this.wizardModel.informations.documents) {
                _this.wizardModel.informations.documents = {
                  allowed: false,
                  plan: false,
                  other: ''
                };
              }
              if (!_this.wizardModel.informations.typeMap) {
                _this.wizardModel.informations.typeMap = {
                  waze: false,
                  map: false
                };
              }
              if (!_this.wizardModel.informations.publishReport) {
                _this.wizardModel.informations.publishReport = false;
              }
              if (!_this.wizardModel.informations.agents) {
                _this.wizardModel.informations.agents = [];
              }
              if (!_this.wizardModel.informations.passanger || ((!_this.wizardModel.informations.passanger.title) && (!_this.wizardModel.informations.passanger.title1))) {
                _this.wizardModel.informations.passanger = _this.tags.passanger;
              }
              if (!_this.wizardModel.informations.portableWater || _this.wizardModel.informations.portableWater.length==0 ) {
                _this.wizardModel.informations.portableWater = _this.tags.portableWater;
              }
              if (!_this.wizardModel.informations.trafficImpact || _this.wizardModel.informations.trafficImpact.length==0 || (_this.wizardModel.informations.trafficImpact.value && _this.wizardModel.informations.trafficImpact.value.length==11)) {
                _this.wizardModel.informations.trafficImpact = _this.tags.trafficImpact;
              }
              _this.wizardModel.entraves = project.entraves;
              _this.wizardModel.phases = project.phases;
            
              let trafficImpact = JSON.parse(JSON.stringify(_this.tags.trafficImpact));
              let portableWater = JSON.parse(JSON.stringify(_this.tags.portableWater));
              _this.wizardModel.phases.forEach(function(e) {
                e.closing.forEach(function(close) {
                  if (!close.trafficImpact) {
                    close.trafficImpact=_this.tags.trafficImpact?trafficImpact:{};
                  }
                  if (!close.portableWater) {
                    close.portableWater=_this.tags.portableWater?portableWater:{};
                  }
                  if (close.publish===undefined) {
                    close.publish=true;
                  }
                });
              });
            });
          } else {
            if (data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              _this.$notify({
                message: this.$i18n.t('serverReply.errorCreateProject'),
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch((e) => {
          console.log('error', e)
        });
      } else {
        let tags = Object.keys(_this.tags);
        let user = JSON.parse(window.localStorage.getItem('user'));
        let projesettings = user.projsettings;
        tags.forEach(function(element) {
          let data = projesettings[element];
          if (data) {
            _this.wizardModel.informations[element] = data;
          }
        });
        _this.wizardModel.informations.inchargeof  = user.name;
        _this.wizardModel.informations.role  = user.title;
        _this.wizardModel.informations.email  = user.email;
        _this.wizardModel.informations.agents  = [];
        _this.wizardModel.informations.publishReport  = user.publishReport;
        _this.wizardModel.informations.phone = user.phone;
        if(!!_this.features.stakeholderAutocomplete && _this.features.stakeholderAutocomplete.show){
          if(_this.wizardModel.informations.agents.length == 0){
            _this.wizardModel.informations.agents.push({
              name: _this.wizardModel.informations.inchargeof,
              role: _this.wizardModel.informations.role,
              phone: _this.wizardModel.informations.phone,
              email: _this.wizardModel.informations.email,
              organization: '',
              isAuthor: true
            })
          }
        }
        if(_this.features.publishProjectToPublicMap.publishToCitizen.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishToCitizen.defaultValue) != undefined ){
          _this.wizardModel.informations.publish  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectPhase.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectPhase.defaultValue) != undefined ){
          _this.wizardModel.informations.publicMapWork  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectWorkNotice.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectWorkNotice.defaultValue) != undefined ){
          _this.wizardModel.informations.publishReport  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectToWaze.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectToWaze.defaultValue) != undefined ){
          _this.wizardModel.informations.typeMap.waze  = true;
        }
        if (_this.tags.passanger.show) _this.wizardModel.informations.passanger = _this.tags.passanger;
        if (_this.tags.portableWater.show) _this.wizardModel.informations.portableWater = _this.tags.portableWater;
        if (_this.tags.trafficImpact.show) _this.wizardModel.informations.trafficImpact = _this.tags.trafficImpact;
      }
    },
    async savePublishExit(){
      if(await this.$refs['step1'].validate()){
        this.wizardModel.projectType.isPublished = true;
        this.wizardModel.projectType.isDraft = false;
        this.loading = true;
        this.conflictSearch();
      }
    },
    reportPDF(){
      this.showModalReport(this.wizardModel.uuid)
    },

    showModalReport(uuid) {
      let _this = this;
      _this.reportProjectUUID=uuid;
      _this.reportProject = true;

          _this.reportProjectInfo.reportDistrict=this.wizardModel.informations.district;
          _this.reportProjectInfo.reportWorkType=this.wizardModel.informations.workType;
          _this.reportProjectInfo.reportPhasesLen = this.wizardModel.phases.length;
          _this.reportProjectInfo.reportPhases = this.wizardModel.phases.map((e, i) => { 
            let closing = e.closing.map((close, index) => {
              return {index:index, closeId:close.closingId}
            });
            return {index:i, phaseId:e.phaseId, closing: closing}; 
          });
    },
    hideReportProject() {
      this.reportProject=false;
      this.reportProjectUUID=null;
      this.reportProjectInfo.reportDistrict=null;
      this.reportProjectInfo.reportWorkType=null;
      this.reportProjectInfo.reportPhasesLen=0;
      this.reportProjectInfo.reportPhases=[];
    },

    async saveDraft(val){
      this.formatProject(this.wizardModel);
      if(this.wizardModel.entraves.date != '' ){
        this.projectForm.isSaving = true;
        this.projectForm.saveError = null;
        if (this.projectForm.isRecordSaved) {
          // Update existing record
          await axios.post(`/project/edit`, this.wizardModel)
            .then(response => {
              if(val === "step4"){
                this.isFinish = true;
                if(this.city == "gatineau" && (this.wizardModel.informations.serviceInfra.length > 0 )){
                  if(this.mode == "create"){
                      this.sendAutoAlert(this.wizardModel.uuid);
                    }
                }
                this.loading = false;
                this.$router.push({path: '/projects/list'});
              }
              this.handleAutoSave(response);
            })
            .catch(error => {
              this.handleError(error);
            });
          console.log("old record")
        } else {
          // Create new record
          await axios.post('/project/create', this.wizardModel)
            .then(response => {
              this.projectForm.isRecordSaved = true;
              this.projectForm.isEditMode = true; // Switch to edit mode after first save
              this.wizardModel.uuid = response.data.uuid; // Assuming the response includes the new ID
              this.handleAutoSave(response);
            })
            .catch(error => {
              this.handleError(error);
            });
        }
      }
    },
    handleAutoSave(response) {
      this.projectForm.isSaving = false;
      // Handle success (e.g., show success notification)
      if(this.isFinish){
        this.$notify({
              message: this.$i18n.t('projectForm.succesSave'),
              timeout: 10000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
      }else{
        this.$notify({
          message: this.$i18n.t('quickCreate.message.handleAutoSave'),
          timeout: 2000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        });
      }
    },
    handleError(error) {
      this.isSaving = false;
      this.saveError = 'Error occurred while saving';
      console.log(error)
      // Handle error (e.g., show error notification)
      this.$notify({
        message: this.$i18n.t('quickCreate.message.handleError'),
        timeout: 3000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      });
    },

  }
};
</script>
<style>
  .card-wizard .wizard-navigation {
    margin-top: 40px !important;
  }
  .card-wizard .card-body {
    margin-top: 10px !important;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  span.el-range-separator,
  input.el-range-input {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 12px !important;
  }
  .el-picker-panel__footer {
    font-size: 12px !important;
  }
  /*
  div.el-date-editor--datetimerange:hover{
    border-color:  #2b3553 !important;
  }
  */
  input.form-control {
    margin-bottom: 0px !important;
  }
  div.el-picker-panel__footer button.el-button:first-child {
    margin-right: 10px;
  }
  div.project-form-create div.card-header {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  div.project-label label {
    color: #3da674 !important;
  }
</style>