<template>
    <div class="video-container">
      <video
        ref="videoPlayer"
        class="video-js vjs-default-skin"
        playsinline
        controls
      ></video>
      <div class="video-overlay"></div>
    </div>
  </template>
  
  <script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';
  
  export default {
    name: 'VideoPlayer',
    props: {
      options: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        player: null,
      };
    },
    mounted() {
      this.initializePlayer();
    },
    watch: {
      options: {
        deep: true,
        handler(newOptions) {
          if (this.player) {
            // If player already exists, update source
            this.player.src(newOptions.sources);
          } else {
            // If not initialized, initialize the player
            this.initializePlayer();
          }
        },
      },
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    methods: {
      initializePlayer() {
        if (!this.player) {
          this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .video-container {
    position: relative;
  }
  
  .video-js {
    width: 100%;
    height: 100%;
  }
  
  .vjs-control-bar {
    font-size: 14px;
  }
  
  .vjs-default-skin .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-top: -1em;
    margin-left: -1em;
    border: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .vjs-default-skin .vjs-big-play-button .vjs-icon-placeholder:before {
    font-size: 1.5em;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
  </style>
  