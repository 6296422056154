<template>
  <div>
    <div class="help-text" v-bind:class="{'help-text-white': showHelp}">?</div>
    <div class="help-container">
      <div class="help-switch">
        <base-switch  style="z-index: 3;" v-model="showHelp" on-text="" off-text=""></base-switch>
      </div>
      <div class="help-content" v-bind:class="{'help-minimise': !showHelp}">
        <dl class="dl-horizontal"><dt>{{ $t('helpWidget.textKeyShort') }}</dt><dd> {{ $t('helpWidget.textDesc') }}</dd></dl>
        <dl class="dl-horizontal">
          <dt>
            <kbd>←</kbd>,<kbd>→</kbd>,<kbd>↑</kbd>,<kbd>↓</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textMoveMap') }}</dd>
          <dt>
            <kbd>+</kbd>,<kbd>-</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textZoom') }}</dd>
          <dt>
            <kbd>{{ $t('helpWidget.textSpace') }}</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textCenterMap') }}</dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>
            <kbd>⬅{{ $t('helpWidget.textBackSpce') }}</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textDelLast') }}</dd>
          <dt>
            <kbd>{{ $t('helpWidget.textDel') }}</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textDelFirst') }}</dd>
          <dt>
            <kbd>Shift</kbd>+<kbd>{{ $t('helpWidget.rightClk') }}</kbd>
          </dt>
          <dd> {{ $t('helpWidget.pointSelect') }}</dd>
          <dt>
            <kbd>Ctrl</kbd>+<kbd>i</kbd>
          </dt>
          <dd>{{ $t('helpWidget.textReverse') }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
  import {BaseSwitch} from 'src/components/index';
  
  export default {
    name: 'help-map',
    components: {
      BaseSwitch
    },
    data () {
      return {
        showHelp: false
      };
    }
  };
</script>
<style>
  .help-text {
    z-index: 3;
    position: absolute;
    right: 18px;
    /*top: 64px;*/
    top: 57px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
  }
  .help-text.help-text-white {
    color: #FFF;
  }
  .help-container {
    position: absolute;
    right: 5px;
    top: 50px;
  }
  .help-switch {
    position: relative;
    right: 30px;
    z-index: 2;
    margin-top: 10px;
  }
  .help-content {
    min-height: 200px;
    position: absolute;
    right: -10px;
    top: -60px;
    background-color: #F0F;
    z-index: 1;
    background: rgba(0,0,0,.6);
    width: 500px;
    opacity: 1;
    transform: translate(-10px,60px);
    border-radius: 5px;
    border: 1px solid #FFF;
    ox-shadow: #000 0 0 5px;
    padding: 10px;
    color: #FFF;
    transition: background 150ms,border 150ms;
    display: block;
  }
  .help-minimise {
    background: 0 0;
    border: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    color: #000;
    display: none;
  }
  dl {
    margin-top: 0;
    margin-bottom: 22px;
  }
  dt {
    width: 130px;
    font-weight: 700;
  }
  .dl-horizontal dt {
    float: left;
    width: 130px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
  }
  dd {
    margin-left: 140px;
  }
  kbd {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.2),0 0 0 2px #fff inset;
    box-shadow: 0 1px 0 rgba(0,0,0,.2),0 0 0 2px #fff inset;
    color: #333;
    display: inline-block;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    line-height: 1.4;
    margin: 0 .1em;
    padding: .1em .6em;
    text-shadow: 0 1px 0 #fff;
  }
</style>