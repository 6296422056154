<template>
  <div class="container">
    <div v-if="reset.uuid" class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="update">
        <div v-show="loading" class="loading-map loading-map-forget">
          <img src="../../public/img/loading.gif" />
        </div>
        <card class="card-login card-white">
          <template slot="header">
            <!--<img src="static/img/card-primary.png" alt="" />-->
            <a href="/"><img class="opa-logo" src="static/img/new/roadworks.svg" alt="" /></a>
          </template>
          <div class="forget-title">
            <span>{{ $t('loginPage.resetTitle')}}</span>
          </div>
          <div>
            <base-input
              v-validate="'required'"
              name="code"
              :error="getError('code')"
              v-model="reset.code"
              v-bind:placeholder= "$t('loginPage.placeholderCode')"
              addon-left-icon="tim-icons icon-key-25">
            </base-input>
            <base-input
              v-validate="'required|min:8'"
              ref="password"
              name="password"
              :error="getError('password')"
              v-model="reset.password"
              type="password"
              v-bind:placeholder= "$t('loginPage.placeholderNewPass')"
              addon-left-icon="tim-icons icon-lock-circle">
            </base-input>
            <base-input
              v-validate="'required|min:8|confirmed:password'"
              name="password"
              :error="getError('password')"
              v-model="reset.cpassword"
              type="password"
              v-bind:placeholder= "$t('loginPage.placeholderConfirmPass')"
              addon-left-icon="tim-icons icon-lock-circle">
            </base-input>
          </div>
          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              {{ $t('loginPage.send')}}
            </base-button>
          </div>
        </card>
      </form>
    </div>
    <div v-else class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="forget">
        <div v-show="loading" class="loading-map loading-map-forget">
          <a href="/"><img class="opa-logo" src="static/img/new/roadworks.svg" alt="" /></a>
        </div>
        <card class="card-login card-white">
          <template slot="header">
            <a href="/"><img class="opa-logo" src="static/img/new/roadworks.svg" alt="" /></a>
          </template>
          <div class="forget-title">
            <span>{{ $t('loginPage.forgetTitle')}}</span>
          </div>
          <div>
            <base-input
              v-validate="'required'"
              name="email"
              type="email"
              :error="getError('email')"
              v-model="model.email"
              v-bind:placeholder= "$t('loginPage.placeholderEmail')"
              addon-left-icon="tim-icons icon-email-85">
            </base-input>
          </div>
          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
              {{ $t('loginPage.send')}}
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
export default {
  data() {
    return {
      loading: false,
      reset: {
        uuid: null,
        code: null,
        password: null,
        cpassword: null
      },
      model: {
        email: ''
      }
    };
  },
  created () {
    if (this.$route.query && this.$route.query.uuid) {
      this.reset.uuid = this.$route.query.uuid;
    } 
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async update() {
      let obj = {
        code: this.reset.code,
        password: this.reset.password,
        uuid: this.reset.uuid
      };
      let _this=this;
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        this.loading=true;
        // TIP use this.model to send it to api and perform login call
        axios.post('/login/updatepwd', obj).then(response => {
          this.loading=false;
          if (response.data.success) {
            _this.$notify({
              message: _this.$i18n.t('loginPage.updatePassSuccess'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
            _this.$router.push({path: '/'});
          } else {
            if (response.data.need_auth) {
              _this.$router.push({path: '/'});
            } else {
              let message = _this.$i18n.t('loginPage.' + response.data.message);
              _this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
              //_this.$router.push({path: '/'});
            }
          }
        }).catch(e => {
          //window.localStorage.setItem('access_token', 'hello');
          //this.$router.push({path: '/'});
        });
      };
    },
    async forget() {
      let isValidForm = await this.$validator.validateAll();
      let _this = this;
      if (isValidForm) {
        this.loading=true;
        // TIP use this.model to send it to api and perform login call
        axios.post('/login/forget', {email: this.model.email}).then(response => {
          this.loading=false;
          if (response.data.success) {
            _this.$notify({
              message: _this.$i18n.t('loginPage.forgetSuccess'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
            _this.$router.push({path: '/login'});
          } else {
            if (response.data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              let message = _this.$i18n.t('loginPage.' + response.data.message);
              _this.$notify({
                message: message,
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch(e => {
          //window.localStorage.setItem('access_token', 'hello');
          //this.$router.push({path: '/'});
        });
      };
    }
  }
};
</script>
<style>
/* div.card-header {
  text-align:center;
} */
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
/* div.full-page.login-page div.card-header img {
  left: 50%;
  margin-left: -125px;
  margin-top: 20px;
} */
div.forget-title {
  text-align: center;
  margin-bottom: 10px;
  color: #1d253b;
}
div.forget-title span {
  font-size: 12px;
}
div.loading-map-forget {
  position:fixed !important;
}
div.loading-map-forget img {
  margin-left: 0px !important;
}
</style>
