<template>
    <div>
        <div class="content feedback-list-wrapper">
            <div class="mt-3 d-flex align-items-center justify-content-end mx-3">
                <base-button class="" @click="$router.push('/feedback/create')" type="primary">
                    {{ $t('feedbacksPage.addFeedback') }}
                </base-button>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <card class="users-card" card-body-classes="table-full-width">
                        <div class="loading" v-if="loading">
                            <img src="../../../public/img/loading.gif" />
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-12 listing-view-options" :class="darkMode ? 'feedback-text-light' : 'feedback-text-dark'">
                                    <span :class="{ 'active-view-option': viewtype === 2 }" @click="handleViewType(2)">{{ $t('feedbacksPage.bug') }}</span>
                                    <span :class="{ 'active-view-option': viewtype === 1 }" @click="handleViewType(1)">{{ $t('feedbacksPage.feedback') }}</span>
                                    <span :class="{ 'active-view-option': !viewtype }" @click="handleViewType('')">{{ $t('feedbacksPage.all') }}</span>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div :class="viewtype !== 1 ? 'col-sm-12 col-md-5 col-lg-6': 'col-sm-12 col-md-8 col-lg-8'">
                                    <base-input v-bind:placeholder="$t('projectForm.search')" @keyup="onSearchInputChange"
                                        v-model="search" class="search-input">
                                    </base-input>
                                </div>
                                <div v-if="viewtype !== 1" class="col-sm-6 col-md-3 col-lg-2 list-project-type">
                                    <el-select
                                        class="select-primary select-inline"
                                        name="published"
                                        size="large"
                                        v-model="status">
                                        <el-option
                                        v-for="option in [
                                            { value: '', label: $t('feedbacksPage.viewAll') }, 
                                            { value: 5, label: $t('feedbacksPage.submitted') }, 
                                            { value: 0, label: $t('feedbacksPage.inProgress') }, 
                                            { value: 1, label: $t('feedbacksPage.done') }, 
                                            { value: 2, label: $t('feedbacksPage.canceled') }]"
                                        class="select-primary"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div :class="viewtype !== 1 ? 'col-sm-6': 'col-sm-12'" class="col-md-4 col-lg-4">
                                    <base-input name="date">
                                        <el-date-picker type="datetimerange" value-format="timestamp" @change="onChangeDate"
                                            range-separator="-" format="yyyy-MM-dd HH:mm" lang="fr"
                                            v-bind:start-placeholder="$t('feedbacksPage.labelProjectStart')"
                                            v-bind:end-placeholder="$t('feedbacksPage.labelProjectEnd')" v-model="date">
                                        </el-date-picker>
                                    </base-input>
                                </div>
                            </div>
                            <div v-show="tableData.length == 0" class="p-3">
                                <base-alert type="info"> {{ $t('feedbacksPage.alert1') }}</base-alert>
                            </div>
                            <div v-show="tableData.length > 0" class="feedbacks-list-table-wrapper mt-3 pl-3 pr-3">
                                <base-table :data="tableData" thead-classes="project-table-head"
                                    tbody-classes="feedback-table-body">
                                    <template slot="columns" slot-scope="{ columns }">
                                        <th v-if="!viewtype || viewtype == 2">{{ $t('feedbacksPage.labelRegistationNo') }}</th>
                                        <th v-if="!viewtype || viewtype == 2">{{ $t('feedbacksPage.priority') }}</th>
                                        <th>{{ $t('feedbacksPage.labelTypeOfReport') }}</th>
                                        <th><span>{{ $t('feedbacksPage.labelName') }}</span></th>
                                        <th>{{ $t('feedbacksPage.labelStatus') }}</th>
                                        <th>
                                            <span class="cursor-pointer date-created" @click="handleColumnSorting('time')">
                                            <span>{{ $t('feedbacksPage.labelCreatedBy') }}</span><span class="ml-1 mb-1" v-html="sortingArrowIcon" />
                                            </span>
                                        </th>
                                        <!-- <th>{{ $t('feedbacksPage.labelLastUpdatedBy') }}</th> -->
                                        <th><span style="visibility: hidden;">{{ $t('feedbacksPage.labelAction') }}</span></th>
                                    </template>

                                    <template slot-scope="{ row, index }" @click="handleViewFeedback(row)">
                                        <td v-if="!viewtype || viewtype == 2">{{ getPulseId(row.pulse_id) || "-" }}</td>
                                        <td v-if="!viewtype || viewtype == 2"><span v-if="isBug(row.feedback_type)">{{ getImpact(row.impact) }}</span><span v-else>-</span></td>
                                        <td>{{ feedback_type[row.feedback_type] }}</td>
                                        <td><span>{{ row.name }}</span></td>
                                        <td><span class="feedback-status color-black px-3 py-1" :style="{ backgroundColor: getStatusBgColor(row.monday_data) }">{{ getFeedbackStatus(row) }}</span></td>
                                        <td>{{ formatTime(row.time) }}</td>
                                        <!-- <td>{{ formatTime(row.time) }}</td> -->
                                        <td>
                                            <div>
                                                <el-tooltip v-bind:content="$t('feedbacksPage.tipView')" effect="light"
                                                    :open-delay="300" placement="top">
                                                    <base-button :type="'success'" icon size="sm" id="view-feedback-btn" class="btn-link">
                                                        <i id="view-feedback-icon" class="fa fa-eye" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'"></i>
                                                    </base-button>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                    </template>
                                </base-table>
                            </div>
                        </div>
                        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                            <div v-show="tableData.length > 0" class="col-sm-2 list-project-action">
                            </div>
                            <div v-show="tableData.length > 0" class="paginate-container">
                                <p class="card-category"> {{ $t('feedbacksPage.pageResultsFrom') }} {{ from + 1 }} {{
                                    $t('feedbacksPage.pageResultsTo') }} {{ to }} {{ $t('feedbacksPage.pageResultsOn') }} {{
            total }}</p>
                                <base-pagination v-show="tableData.length > 0" class="pagination-no-border"
                                    v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total">
                                </base-pagination>
                            </div>
                        </div>
                    </card>
            
                </div>
            </div>
        </div>
         <div v-if="viewFeedback" class="view-feedback-container p-4" :class="darkMode ? 'feedback-bg-dark': 'feedback-bg-light'" ref="sidebar">
            <div class="d-flex align-items-center py-2">
                <span v-if="!loading" @click="closeViewFeedbackSidebar" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" :stroke="darkMode ? 'white': 'black'"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </span>
                <span class="ml-3 primary-heading" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'"><span v-if="viewFeedback.feedback_type === '1'">{{ $t('feedbacksPage.feedback') }}</span>
                <span v-else>{{ $t('feedbacksPage.bug') }}</span></span>
            </div>
            <div>
                <div class="pt-3 pb-1">
                    <span class="secondary-heading" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">{{ $t('feedbacksPage.userDetails') }}</span>
                </div>
                <div class="user-details">
                    <table>
                        <tbody>
                            <tr :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600">{{ $t('feedbacksPage.name') }}</td>
                                <td>{{ viewFeedback.name }}</td>
                            </tr>
                            <tr :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600">{{ $t('feedbacksPage.email') }}</td>
                                <td>{{ viewFeedback.email }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <div class="pt-3 pb-1 d-flex justify-content-start">
                    <span class="secondary-heading" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">{{ $t('feedbacksPage.reportDetails') }}</span>
                    <span class="back-btn cursor-pointer d-none" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'" @click="toggleEditMode">
                        <i v-if="!isEditMode" class="tim-icons icon-pencil" :style="{color: darkMode ? 'white': 'black'}"></i>
                        <i v-else class="tim-icons icon-simple-remove" :style="{color: darkMode ? 'white': 'black'}"></i>
                        <span class="ml-1">{{ $t('feedbackView.editBtnText') }}</span>
                    </span>
                </div>
                <div class="report-details">
                    <table>
                        <tbody>
                            <tr :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600">{{ $t('feedbacksPage.typeofReport') }}</td>
                                <td>{{ feedback_types[viewFeedback.feedback_type] }}</td>
                            </tr>
                            <tr v-if="viewFeedback.feedback_type === '2'" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600">{{ $t('feedbacksPage.labelRegistationNo') }}</td>
                                <td>{{ getPulseId(viewFeedback.pulse_id) }}</td>
                            </tr>
                            <tr v-if="viewFeedback.feedback_type === '1'" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'"> 
                                <td class="fw-600" >{{ $t('feedbacksPage.feedback') }}</td>
                                <td v-if="!isEditMode">
                                    <!-- {{ viewFeedback.rating }} -->
                                    <span v-if="viewFeedback.rating === '1'">
                                        <AmazingExperience :feedbackRating="parseInt(viewFeedback.rating)"/>
                                    </span>
                                    <span v-if="viewFeedback.rating === '2'">
                                        <ReallyGoodExperience :feedbackRating="parseInt(viewFeedback.rating)"/>
                                    </span>
                                    <span v-if="viewFeedback.rating === '3'">
                                        <GoodExperience :feedbackRating="parseInt(viewFeedback.rating)" />
                                    </span>
                                    <span v-if="viewFeedback.rating === '4'">
                                        <NotVeryGoodExperience :feedbackRating="parseInt(viewFeedback.rating)"/>
                                    </span>
                                    <span v-if="viewFeedback.rating === '5'">
                                        <BadExperience :feedbackRating="parseInt(viewFeedback.rating)"/>
                                    </span>
                                </td>
                                <td v-else>
                                    <div class="feedback-experiences-wrapper">
                                        <span @click="handleSelectFeedback(1)" class="cursor-pointer"><AmazingExperience :feedbackRating="editFeedback.rating" /></span>
                                        <span @click="handleSelectFeedback(2)" class="cursor-pointer"><ReallyGoodExperience :feedbackRating="editFeedback.rating" /></span>
                                        <span @click="handleSelectFeedback(3)" class="cursor-pointer"><GoodExperience :feedbackRating="editFeedback.rating" /></span>
                                        <span @click="handleSelectFeedback(4)" class="cursor-pointer"><NotVeryGoodExperience :feedbackRating="editFeedback.rating" /></span>
                                        <span @click="handleSelectFeedback(5)" class="cursor-pointer"><BadExperience :feedbackRating="editFeedback.rating" /></span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-else :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600" >{{ $t('feedbacksPage.impact') }}</td>
                                <td v-if="!isEditMode">{{ getImpact(viewFeedback.impact) }}</td>
                                <td v-else>
                                    <div>
                                        <el-select class="test-select select-primary select-inline feedback-impact" name="language" size="large"
                                        v-model="editFeedback.impact">
                                        <el-option
                                            v-for="option in [{ value: '1', label: $t('feedbackForm.impactLow') }, { value: '2', label: $t('feedbackForm.impactMedium') }, { value: '3', label: $t('feedbackForm.impactHigh') }, { value: '4', label: $t('feedbackForm.impactEmergency') }]"
                                            class="select-primary" :value="option.value" :label="option.label" :key="option.label">
                                        </el-option>
                                        </el-select>
                                    </div>
                                </td>
                            </tr>
                            <tr  :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600">{{ $t('feedbacksPage.status') }}</td>
                                <td><span class="feedback-status color-black px-3 py-1 feedback-text-dark" :style="{ backgroundColor: getStatusBgColor(viewFeedback.monday_data) }">{{ getFeedbackStatus(viewFeedback) }}</span></td>
                            </tr>
                            <tr :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600" >{{ $t('feedbacksPage.labelCreatedBy') }}</td>
                                <td>{{ formatTime(viewFeedback.time) }}</td>
                            </tr>
                            <tr :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600" >{{ $t('feedbacksPage.labelLastUpdatedBy') }}</td>
                                <td>{{ formatTime(viewFeedback.time) }}</td>
                            </tr>
                            <tr v-if="viewFeedback.feedback_type === '1'" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600" >{{ $t('feedbacksPage.experience') }}</td>
                                <td>
                                    <div v-if="!isEditMode" class="text-content-container" v-html="viewFeedback.experience_description"></div>
                                    <div v-else id="quill-editor"></div>
                                    <label v-if="error.experience_description && isEditMode" class="text-danger-feedback mt-2 ml-0">{{ $t('feedbackForm.descRequired') }}</label>
                                </td>
                            </tr>
                            <tr v-else :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                                <td class="fw-600" >{{ $t('feedbacksPage.bugExplanation') }}</td>
                                <td>
                                    <div v-if="!isEditMode" class="text-content-container" v-html="viewFeedback.bug_explanation"></div>
                                    <div v-else id="quill-editor"></div>
                                    <label v-if="error.bug_explanation && isEditMode" class="text-danger-feedback mt-2 ml-0">{{ $t('feedbackForm.bugExplainationRequired') }}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="!isEditMode && viewFeedback.attachment_urls.length" class="attachments mt-2">
                        <div class="secondary-heading" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">{{ $t('feedbacksPage.attachments') }}</div>
                        <div class="mt-4">
                            <span v-for="(file, index) in viewFeedback.attachment_urls" :key="index">
                                <div class="fileinput text-center position-relative" v-bind:class="{ 'ml-2': index > 0 }">
                                    <a :href="file" target="_blank">
                                        <div class="fileinput-new thumbnail file" :class="{ 'img-circle': type === 'avatar' }">
                                            <img v-if="isImage(file)" :src="file" alt="" />
                                            <video v-else-if="isVideo(file)" :src="file" controls autoplay muted></video>
                                        </div>
                                    </a>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div v-else-if="isEditMode" class="attachments mt-2">
                        <div class="d-flex align-items-center" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">
                            <span class="heading" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'">{{ $t('feedbacksPage.attachments') }}</span>
                            <span class="select-image-btn ml-2">
                                <span class="btn btn-primary btn-simple btn-file">
                                    <span class="fileinput-new">{{ $t('feedbackForm.btnFileSelect')
                                    }}</span>
                                    <!-- <span v-else class="fileinput-new">{{ $t('feedbackForm.btnUploadMoreFiles') }}</span> -->
                                    <input type="hidden" value="" name="" />
                                    <input @change="handlePreview" type="file" accept="image/*,video/*" name="..." class="valid" :multiple="true"
                                    aria-invalid="false" />
                                </span>
                            </span>
                        </div>
                        <div class="mt-3">
                            <span v-for="(file, index) in editFeedback.attachment_urls" :key="index">
                                <div class="fileinput text-center position-relative" v-bind:class="{ 'ml-2': index > 0 }">
                                    <div class="select-image-btn file-actions-container d-flex align-items-center">
                                        <span class="btn-file">
                                            <i class="fa fa-pencil-alt mr-1 cursor-pointer" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'"></i>
                                            <input type="hidden" value="" name="" />
                                            <input @change="e => handlePreview(e, index)" type="file" accept="image/*,video/*" name="..." class="valid"
                                                :multiple="false" aria-invalid="false" />
                                        </span>
                                        <i class="fa fa-trash ml-1 cursor-pointer" :class="darkMode ? 'feedback-text-light': 'feedback-text-dark'" @click="removeFile(index)"></i>
                                    </div>
                                    <div class="fileinput-new thumbnail file" :class="{ 'img-circle': type === 'avatar' }">
                                        <img v-if="isImage(file)" :src="getFilePreview(file)" alt="" />
                                        <video v-else-if="isVideo(file)" :src="getFilePreview(file)" controls autoplay muted></video>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <base-button v-if="isEditMode" native-type="submit" @click.prevent="submit" class="pull-right" type="primary"
                    :loading="feedbackUpdateLoading">{{ $t('feedbackForm.btnFeedbackSubmit') }}</base-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { BasePagination, BaseAlert } from 'src/components';
import { BaseTable } from '@/components';
import { Modal } from 'src/components';
import moment from 'moment';
import { Table, TableColumn, Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { AmazingExperience, ReallyGoodExperience, GoodExperience, NotVeryGoodExperience, BadExperience } from 'src/components/index';
import quillEditorToolbar from 'src/app/shared/quillEditorToolbar';

import icons from '../../app/shared/icons';

export default {
    components: {
        Modal,
        BaseTable,
        BasePagination,
        BaseAlert,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        AmazingExperience, 
        ReallyGoodExperience,
        GoodExperience, 
        NotVeryGoodExperience,
        BadExperience
    },
    computed: {
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.total;
        }
    },
    created() {
        this.getFeedbacks();
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            this.darkMode = user.appsettings.darkMode;
        } catch (error) {
            console.log(error)
        }
        // document.body.style.overflowY = 'hidden';
    },
    beforeDestroy() {
        // Remove the event listener when the component is about to be destroyed
        document.removeEventListener("click", this.handleDocumentClick);
    },
    data() {
        return {
            pagination: {
                perPage: 5,
                currentPage: 1,
                total: 0
            },
            tableData: [],
            total: 0,
            userType: {
                'admin': this.$i18n.t('feedbacksPage.userTypeAdmin'),
                'adminGis': this.$i18n.t('feedbacksPage.userTypeAdminGis'),
                'user': this.$i18n.t('feedbacksPage.userTypeUser'),
                'userRes': this.$i18n.t('feedbacksPage.userTypeUserRes'),
                'viewer': this.$i18n.t('feedbacksPage.userTypeViewer'),
                'viewerRes': this.$i18n.t('feedbacksPage.userTypeViewerRes')
            },
            loading: false,
            feedbackUpdateLoading: false,
            importUsers: false,
            exportUser: false,
            feedback_type: {
                1: this.$i18n.t('feedbacksPage.feedback'),
                2: this.$i18n.t('feedbacksPage.bug')
            },
            search: "",
            feedback: '',
            date: null,
            viewtype: 2,
            viewFeedback: null,
            feedback_types: {
                1: this.$i18n.t('feedbacksPage.feedback'),
                2: this.$i18n.t('feedbacksPage.bug')
            },
            isEditMode: false,
            editFeedback: {
                feedback_id: null,
                feedback_type: null,
                experience_description: '',
                bug_explanation: '',
                impact: null,
                rating: null,
                attachment_urls: []
            },
            error: {
                experience_description: false,
                bug_explanation: false,
            },
            darkMode: false,
            viewBy: '',
            quillEditor: null,
            sort_order: 1,
            sort_column: 'time',
            sortingArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
                <g transform="translate(-8413 592)">
                  <path d="${icons.sortingArrow}" transform="translate(8413 -592)" fill="#f400cc"></path>
                  <path d="${icons.sortingArrow}" transform="translate(8422 -578) rotate(180)" fill="#bfc3cd"></path>
                </g>
              </svg>`,
            status: ''
        };
    },
    filters: {
        formatDate(value) {
            return moment(value).format('DD-MM-YYYY hh:mm:ss');
        }
    },
    methods: {
        paginate() {
            //this.getFeedbacks();
        },
        getFeedbacks() {
            let _this = this;
            const currentPage = this.$route.query.currentPage || this.pagination.currentPage;
            let obj = {
                from: (this.pagination.perPage * (currentPage - 1)) + 1,
                limit: this.pagination.perPage,
                searchkey: this.search,
                viewtype: this.viewtype,
                sort_order: this.sort_order,
                status: this.status
            };
            if(this.date){
                obj.datefrom = this.date[0];
                obj.dateto = this.date[1];
            }
            this.loading = true;
            axios.post('feedback/readAll', obj).then(response => {
                let data = response.data;
                _this.loading = false;
                if (data.success) {
                    _this.tableData = data.data.map(function (element) {
                        element.type = _this.userType[element.type];
                        return element;
                    });
                    _this.total = data.total;
                } else {
                    if (data.need_auth) {
                        _this.$router.push({ path: '/login' });
                    } else {
                        _this.$notify({
                            message: _this.$i18n.t('serverReply.errorUsersRead'),
                            timeout: 10000,
                            icon: 'tim-icons icon-bell-55',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        });
                    }
                }
            }).catch(() => {
                _this.$notify({
                    message: _this.$i18n.t('serverReply.errorProject'),
                    timeout: 10000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                });
            });
        },
        onSearchInputChange(e) {
            if (e.keyCode === 13 || e.target.value === "") {
                this.getFeedbacks();
            }
        },
        handleViewFeedback(feedback) {
            if(feedback){
                this.editFeedback = {...feedback, files: feedback.attachment_urls, rating: parseInt(feedback.rating)};
            }else{
                this.isEditMode = false;
            }
            this.viewFeedback = feedback;
        },
        onChangeDate() {
            this.getFeedbacks();
        },
        handleViewType(type){
            this.viewtype = type;
            this.status = "";
        },
        getFeedbackStatus(data){
            const { monday_data } = data;
            const currentLanguageTranslatedStatus = {
                Submitted: this.$t('feedbacksPage.submitted'),
                'In progress': this.$t('feedbacksPage.inProgress'),
                Done: this.$t('feedbacksPage.done'),
                Canceled: this.$t('feedbacksPage.canceled')
            }
            if(monday_data && monday_data.event && monday_data.event.value && monday_data.event.value.label && monday_data.event.value.label.text){
                return currentLanguageTranslatedStatus[monday_data.event.value.label.text];
            }
            return this.$t('feedbacksPage.defaultFeedbackBugStatus');
        },
        getStatusBgColor(monday_data) {
            let defaultColor = '#c4c4c4';
            if (monday_data && monday_data.event && monday_data.event.value && monday_data.event.value.label && monday_data.event.value.label.style && monday_data.event.value.label.style.color) {
                defaultColor = monday_data.event.value.label.style.color;
            }
            return defaultColor;
        },
        getImpact(impact){
            return [{ value: '1', label: this.$t('feedbackForm.impactLow') }, { value: '2', label: this.$t('feedbackForm.impactMedium') }, { value: '3', label: this.$t('feedbackForm.impactHigh') }, { value: '4', label: this.$t('feedbackForm.impactEmergency') }].find(item => item.value === impact).label;             
        },
        isImage(file){
            if(typeof file === 'string'){
                const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
                return imageExtensions.includes(this.getUrlExtension(file));
            }
            return file.type.includes('image');
        },
        isVideo(file){
            if(typeof file === 'string'){
                const videoExtensions = ['mp4', 'webm', 'mkv', 'avi', 'mov', 'flv', 'wmv'];
                return videoExtensions.includes(this.getUrlExtension(file));
            }
            return file.type.includes('video');
        },
        getFilePreview(file) {
            if(typeof file === 'object'){
                return URL.createObjectURL(file)
            }
            return file
        },
        getUrlExtension(url){
            return url.split('.').pop().toLowerCase();
        },
        toggleEditMode(){
            this.isEditMode = !this.isEditMode;
        },
        handleSelectFeedback(rating) {
            // this.error.rating = false;
            this.editFeedback.rating = parseInt(rating);
        },
        filterSpecialCharacters(event) {
            const charCode = event.keyCode || event.which;
            const charStr = String.fromCharCode(charCode);

            // Allow only alphanumeric characters and whitespace
            const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

            if (!alphanumericRegex.test(charStr)) {
                event.preventDefault();
            }
        },
        handlePreview(event, idx) {
            if (!event.target.files.length) return;
            const files = event.target.files;
            let showError;
            if (isNaN(idx)) {
                const filesArray = Array.from(files);
                for (const file of filesArray) {
                    if (this.isValidFileSize(file)) {
                        this.editFeedback.attachment_urls.push(file);
                    }
                    else {
                        showError = true;
                    }
                }
            } else {
                const file = files[0];
                if (this.isValidFileSize(file)) {
                    this.editFeedback.attachment_urls.splice(idx, 1, file);
                } else {
                    showError = false;
                }
            }
            if (showError) {
                this.$notify({
                    message: this.$i18n.t('feedbackForm.fileMaxSizeError'),
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                });
            }
        },
        isValidFileSize(file){
            return this.isImage(file) && this.bytesToMB(file.size) <= 10 || this.isVideo(file) && this.bytesToMB(file.size) <= 50;
        },
        bytesToMB(bytes) {
            return (bytes / (1024 * 1024)).toFixed(2);
        },
        removeFile(index) {
            this.editFeedback.attachment_urls = this.editFeedback.attachment_urls.filter((_file, idx) => idx !== index);
        },
        submit() {
            let error;
            if (this.editFeedback.feedback_type == 1 && !this.editFeedback.experience_description) {
                this.error.experience_description = true;
                error = true;
            } else if (this.editFeedback.feedback_type == 2 && !this.editFeedback.bug_explanation) {
                this.error.bug_explanation = true
                error = true;
            }
            if (error) return;
            let isAnyImageToBeUploaded;
            isAnyImageToBeUploaded = this.editFeedback.attachment_urls.find(item => typeof item === 'object');
            if(isAnyImageToBeUploaded){
                this.uploadMedia();
            }else{
                this.updateFeedback();
            }
        },
        updateFeedback() {
            let error;
            if (this.editFeedback.feedback_type == 1 && !this.editFeedback.experience_description) {
                this.error.experience_description = true;
                error = true;
            } else if (this.editFeedback.feedback_type == 2 && !this.editFeedback.bug_explanation) {
                this.error.bug_explanation = true
                error = true;
            }
            if (error) return;
            const url = '/feedback/update';
            const data = {
                'feedback_id': this.editFeedback.uuid,
                'impact': this.editFeedback.impact,
                'rating': this.editFeedback.rating,
                'experience_description': this.editFeedback.experience_description,
                'bug_explanation': this.editFeedback.bug_explanation,
                'attachment_urls': this.editFeedback.attachment_urls
            }
            let _this = this;
            _this.feedbackUpdateLoading = true;
            axios({
                method: 'post',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.success) {
                _this.$notify({
                    message: `${this.feedback.feedback_type == 1 ? 'Feedback' : 'Bug'} updated successfully!`,
                    timeout: 3000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success'
                });
                _this.closeViewFeedbackSidebar();
                _this.getFeedbacks();
                } else {
                if (response.data.need_auth) {
                    _this.$router.push({ path: '/login' });
                } else {
                    _this.$notify({
                    message: response.data.message || "Something went wrong, please try again later.",
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                    });
                }
                }
            }).catch(e => {
                console.log("error:::", e);
                _this.$notify({
                message: e.message || "Something went wrong, please try again later.",
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
                });
            }).finally(() => { _this.feedbackUpdateLoading = false });
        },
        uploadMedia() {
            const url = '/aws/upload';
            const { attachment_urls } = this.editFeedback;
            const files = [];
            let fd = new FormData();
            for (const file of attachment_urls) {
                if (typeof file === 'object') { files.push(file); }
            }
            for (const file of files) {
                fd.append('file', file)
            }
            let _this = this;
            _this.feedbackUpdateLoading = true;
            axios({
                method: 'post', 
                url,
                data: fd,
                headers: this.getHeaders(files)
            }).then((response) => {
                if (response.data.success) {
                    response.data.attachments.forEach(url => {
                        for (let index = 0; index < _this.editFeedback.attachment_urls.length; index++) {
                            const item = _this.editFeedback.attachment_urls[index];
                            if (typeof item === 'object') {
                                _this.editFeedback.attachment_urls[index] = url;
                                break; 
                            }
                        }
                    });
                    this.updateFeedback();
                } else {
                    if (response.data.need_auth) {
                        _this.$router.push({ path: '/login' });
                    } else {
                        _this.$notify({
                            message: response.data.message || "Something went wrong, please try again later.",
                            timeout: 30000,
                            icon: 'tim-icons icon-bell-55',
                            horizontalAlign: 'center',
                            verticalAlign: 'top',
                            type: 'danger'
                        });
                    }
                }
            }).catch(e => {
                console.log("error:::", e);
                _this.$notify({
                    message: e.message || "Something went wrong, please try again later.",
                    timeout: 30000,
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                });
            }).finally(() => { _this.feedbackUpdateLoading = false });
        },
        getHeaders(files) {
            let size = 0;
            for (const file of files) {
                size += file.size;
            }
            return {
                'Filesize': size
            };
        },
        formatTime(milliseconds) {
            const date = new Date(milliseconds);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        },
        isBug(feedackType){
            return feedackType == 2;
        },
        closeViewFeedbackSidebar() {
            this.handleViewFeedback(null);
            this.isEditMode = false;
        },
        removeQlToolbarHeader(){
            const toolbarElements = document.querySelectorAll('.ql-toolbar');

            toolbarElements.forEach((element) => {
                element.remove();
            });
        },
        getPulseId(pulseId){
            if(typeof pulseId !== 'string') return;
            return pulseId;
        },
        handleDocumentClick(event){
            if(!this.viewFeedback){
                const clickedRow = event.target.closest('tr');
                if (clickedRow && clickedRow.parentNode.matches('.feedback-table-body')) {
                    const rowIndex = Array.from(clickedRow.parentNode.children).indexOf(clickedRow);
                    const rowData = this.tableData[rowIndex];
                    this.handleViewFeedback(rowData);
                } 
            } else {
                if (!this.$refs.sidebar) return;
                if (!this.$refs.sidebar.contains(event.target)) {
                    this.closeViewFeedbackSidebar();
                }
            }
        },
        handleColumnSorting(name) {
            let sortingOrder = 1;
            if (this.sort_column === name) {
                sortingOrder = this.sort_order === 1 ? -1 : 1;
            }
            const { sortingArrow } = icons;
            let groupTransformation, upArrowColor, downArrowColor, upArrowDirection, downArrowDirection;
            switch (sortingOrder) {
                case 1:
                groupTransformation = "translate(-8440 592)", upArrowColor = "#bfc3cd", downArrowColor = "#f400cc", upArrowDirection = "translate(8449 -578) rotate(180)", downArrowDirection = "translate(8440 -592)";
                break
                case -1:
                groupTransformation = "translate(-8428 592)", upArrowColor = "#f400cc", downArrowColor = "#bfc3cd", upArrowDirection = "translate(8437 -578) rotate(180)", downArrowDirection = "translate(8428 -592)";
                break;
            }
            this.sortingArrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
                        <g transform="${groupTransformation}">
                        <path d="${sortingArrow}" transform="${upArrowDirection}" fill="${upArrowColor}" />
                        <path d="${sortingArrow}" transform="${downArrowDirection}" fill="${downArrowColor}" />
                        </g>
                    </svg>`;
            this.sort_column = name;
            this.sort_order = sortingOrder;
        },
    },
    watch: {
        'pagination.currentPage'() {
            this.getFeedbacks();
        },
        viewtype(){
            this.getFeedbacks();
        },
        'editFeedback.experience_description'(newValue) {
            if (newValue) {
                this.error.experience_description = false;
            }
        },
        'editFeedback.bug_explanation'(newValue) {
            if (newValue) {
                this.error.bug_explanation = false;
            }
        },
        viewFeedback(value){
            let verticalOverflowStyle = 'hidden';
            if(value){
                verticalOverflowStyle = '';
            }
            // removing unnecessary scroll when view feedback sidebar is opened.
            // document.body.style.overflowY = verticalOverflowStyle;
        },
        isEditMode(value) {
            const _this = this;
            _this.removeQlToolbarHeader();

            if (!value) return; 
           
            _this.$nextTick(() => {
                _this.quillEditor = new Quill('#quill-editor', {
                    modules: {
                        toolbar: quillEditorToolbar
                    },
                    theme: 'snow'
                });
                _this.quillEditor.on('text-change', function () {
                    const editorContent = _this.quillEditor.root.innerHTML;
                    if (_this.editFeedback.feedback_type == 1) {
                        _this.editFeedback.experience_description = editorContent;
                    } else {
                        _this.editFeedback.bug_explanation = editorContent;
                    }
                });

                let editorContent;
                if (_this.editFeedback.feedback_type == 1) {
                    editorContent = _this.editFeedback.experience_description;
                } else {
                    editorContent = _this.editFeedback.bug_explanation;
                }
                this.quillEditor.clipboard.dangerouslyPasteHTML(editorContent);
            }) 
        },
        'tableData.length'(length){  
            if(!length) return;
            document.addEventListener('click', this.handleDocumentClick);
        },
        sort_order(){
            this.getFeedbacks();
        },
        status(){
            this.getFeedbacks();
        }
    }
};
</script>
<style>
:root {
    --green-color: #8AF2C0;
    --dark-color: rgba(34, 42, 66, .7);
    --light-color: hsla(0, 0%, 100%, .8);
    --dark-bg-color: #27293d;
    --light-bg-color: #ffffff;
}

div.card.users-card div.card-body {
    min-height: 500px;
}

div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
}

div.paginate-container {
    display: inherit;
}

div.paginate-container p {
    margin-top: 7px;
}

tbody.feedback-table-body tr td.btn-container {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}

tbody.feedback-table-body tr:hover td.btn-container {
    visibility: visible;
    opacity: 1;
}

tbody.feedback-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

button.import-users-margin {
    margin-right: 1rem;
}

button.import-users {
    float: right;
}

.center-btn {
    text-align: center !important;
}

.feedback-list-wrapper .heading {
    font-size: calc(1em + 0.4vw);
    font-weight: 600;
    color: black;
}

.listing-view-options {
    padding: 10px;
    border-bottom: 1px solid rgba(34,42,66,.2);
}

.listing-view-options span {
    margin: 10px;
    cursor: pointer;
    padding: 10px 0;
}

.listing-view-options .active-view-option {
    border-bottom: 2px solid #e14eca;
}

.feedback-list-wrapper .card-body {
    padding: 0px !important;
}

.feedback-list-wrapper .row {
    margin: 0px;
}

.view-feedback-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1050;
    overflow-y: auto;
}

.feedback-list-wrapper .color-black {
    color: black;
}

.user-details > div, .report-details > div {
    border-bottom: 1px solid;
    padding: 7px 0;
}

.view-feedback-container .heading {
    font-size: calc(1em + 0.2vw);
}

.user-details table, .report-details table {
    border-collapse: collapse;
    width: 100%;
}

.user-details th, .user-details td, .report-details th, .report-details td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Border only at the bottom */
}

.user-details th, .report-details th {
    background-color: #f2f2f2;
}

.feedback-list-wrapper .text-content-container {
    max-height: 100px;
    overflow-y: auto;
}

.feedback-list-wrapper .font-size-20 {
    font-size: 20px;
}

.feedback-list-wrapper label.text-danger-feedback {
  color: #e5262b !important;
}

.feedback-list-wrapper .feedback-status, .view-feedback-container .feedback-status {
    font-size: 10px;
    border-radius: 15px;
    font-weight: 600;
}

.feedback-list-wrapper .attachments {
    border-bottom: none;
}

.view-feedback-container .primary-heading {
    font-size: calc(1em + 0.3vw);
    font-weight: 600;
}

.view-feedback-container .secondary-heading {
    font-size: calc(1em + 0.1vw);
    font-weight: 600;
}

.fw-600 {
    font-weight: 600;
}

.view-feedback-container textarea {
    border-radius: 8px;
    resize: none;
}

.feedbacks-list-table-wrapper{
    overflow-x: auto;
}

.feedback-text-dark {
    color: var(--dark-color) !important;
}

.feedback-text-light {
    color: var(--light-color) !important;
}

.feedback-bg-light {
    background-color: var(--light-bg-color) !important;
}

.feedback-bg-dark {
    background-color: var(--dark-bg-color) !important;
}

.feedbacks-list-table-wrapper table tbody tr:hover {
    cursor: pointer;
}

.feedbacks-list-table-wrapper .date-created {
    display: flex;
    align-items: center;
}
/* 
@media only screen and (max-width: 600px) {
    .el-picker-panel.el-date-range-picker {
        left: 0 !important;
    }
} */

@media only screen and (min-width: 768px) {
    .view-feedback-container {
        width: 75%;
    }
}

@media only screen and (min-width: 1024px) {
    .view-feedback-container {
        width: 50%;
    }
}

</style>