<template>
  <div class="login-container"></div>
</template>
<script>
import axios from "axios";
import { BaseNav } from "src/components";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {};
  },
  methods: {},
  beforeDestroy() {},
  beforeRouteUpdate(to, from, next) {},

  mounted() {  
    let _this = this; 
    const ACCESS_TOKEN = window.location.hash.split("&")[0].split("=")[1];
    console.log("tk",ACCESS_TOKEN);
    axios.post("/login_ad", { token: ACCESS_TOKEN })
      .then((res) => {
        if (res.data.success) {
          axios.post("login", {
              login: res.data.user.login,
              password: res.data.token,
            }).then((response) => {
              if (response.data.success) {
                let user = response.data.user;
                if (!user.lang) {
                  user.lang = "fr";
                }
                window.localStorage.setItem("access_token", user.uuid);
                window.localStorage.setItem("role", user.type);
                window.localStorage.setItem("user", JSON.stringify(user));
                window.localStorage.setItem(
                  "instance",
                  JSON.stringify(response.data.instance)
                );
                this.$root.$i18n.locale = user.lang;
                if (response.data.instance.city == "us") {
                  window.localStorage.setItem("state", "California");
                }
                this.$router.push({ path: "/" }).catch(() => {});
              } else {
                if (response.data.need_auth) {
                  this.$router.push({ path: "/login" }).catch(() => {});
                } else {
                  _this.$notify({
                    message: _this.$i18n.t("serverReply.errorLoginFail"),
                    timeout: 30000,
                    icon: "tim-icons icon-bell-55",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                  });
                }
              }
            }).catch((error) => console.log("error :>> ", error));
        }
      }).catch((error) => console.log("error :>> ", error));
  },
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

body {
}

div.login-container {
  // background-image: url("../../public/img/bg.jpg");
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
}
.full-page {
  background: none !important;
}
</style>
