<template>
  <div class="loading" v-if="loading">
    <img src="../../../public/img/loading.gif"/>
  </div>
  <div v-else>
    <div v-show="tableData.length==0">
      <base-alert type="info"> {{ $t('emails.emailsList.noRecordFound') }}</base-alert>
    </div>
    <div v-show="tableData.length > 0" style="overflow-x: auto;">
      <base-table :data="tableData" thead-classes="text-primary project-table-head" tbody-classes="archive-table-body">
        <template slot="columns" slot-scope="{ columns }">
          <th>{{ $t('emails.emailsList.projectName') }}</th>
          <th>{{ $t('emails.emailsList.labelToEmail') }}</th>
          <th><span class="cursor-pointer" @click="handleColumnSorting('time')">{{ $t('emails.emailsList.labelTime') }} <span class="ml-1 mb-1" v-html="sortingArrowIcon"></span>
          </span></th>
          <th width="20">{{ $t('emails.emailsList.labelStatus') }}</th>
        </template>
        <template slot-scope="{ row, index }" >
          <td ><p class="title">{{ row.project_name }}</p></td>
          <td v-html="row.to"></td>
          <td>{{ row.time | formatDate }}</td>
          <td>
            <p :class="row.statusIconClass"> {{ row.statusTitle }}</p>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, TimeSelect, DatePicker } from 'element-ui';
import { BaseTable } from '@/components';
import { BasePagination, BaseAlert } from 'src/components';
import moment from 'moment';

import {Slider, BaseProgress} from 'src/components/index';

export default {
  name: 'EmailsTable',
  components: {
    BaseTable,
    BasePagination,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseProgress,
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    onDownload: {
      type: Function,
      required: true
    },
    componentName: {
      type: String,
      required: true
    },
    updateModal: {
      type: Function,
      required: false
    },
    getEmails: {
      type: Function,
      required: false
    },
    sort_by: {
      type: Object,
      required: true
    },
    handleColumnSorting: {
      type: Function,
      required: true
    },
    sortingArrowIcon: {
      type: String,
      required: true
    }
  },
  filters: {
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    }
  },
}
</script>

<style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  table.el-table__header {
    margin-bottom: 0px;
  }
  .bootstrap-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;
    vertical-align: middle;
    cursor: text;
  }
  .bootstrap-tagsinput .tag {
    cursor: pointer;
    margin: 5px 3px 5px 0;
    position: relative;
    padding: 3px 8px;
        padding-left: 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: .75em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.5em;
    padding-left: .8em;
    color: #fff;
  }
  div.card.archives-card div.card-body {
    min-height: 500px;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.paginate-container {
    display: inherit;
  }
  div.paginate-container p {
    margin-top: 7px;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }
  div.card.archives-card .pad-left-20 {
    padding-left: 20px
  }
  .pell-button {
    width: 35px !important;
  }
  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
</style>