<template>
  <div class="projects-filters-content"  :class="{ show: hover }">
    <div class="col-sm-8 col-md-8 col-lg-8 filters-tags">
      <el-select v-model="value" multiple v-bind:placeholder="$t('mapPage.filterProject')" popper-class="filters-project" @remove-tag="removeTags()" @visible-change="visibleChange($event)">
        <el-option-group
          :class="filters-project-group"
          v-for="group in options"
          :key="group.key"
          :label="group.label">
          <el-option
            :class="item.class"
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="`${group.key}.${item.value}`">
          </el-option>
        </el-option-group>
      </el-select>
    </div>
    <div class="col-sm-4 col-md-4 col-lg-4 filters-range">
      <base-input name="range">
        <el-date-picker
          type="datetimerange"
          value-format="timestamp"
          range-separator="-"
          format="yyyy-MM-dd HH:mm"
          lang="fr"
          :clearable="true"
          v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
          v-bind:end-placeholder="$t('projectForm.labelProjectEnd')"
          v-model="range">
        </el-date-picker>
      </base-input>
    </div>
  </div>
</template>

<script>
import {Select, Option, OptionGroup, TimeSelect, DatePicker} from 'element-ui';
import tags from './../shared/filters';

export default {
  name: 'filters-projects',
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup
  },
  props: ['hover'],
  watch: {
    'value'(val) {
      this.changeTags=true;
      this.$emit('changeTags', val);
    },
    'range'(val) {
      this.$emit('changeRanges', val);
    }
  },
  created() {
    let  instance = JSON.parse(window.localStorage.getItem('instance'));
    let user = JSON.parse(window.localStorage.getItem('user'));
    this.filters = tags[instance.city];
    let format = {
      'district': this.$i18n.t('projectList.ladelDistrict'),
      'entity':  this.$i18n.t('projectList.labelProjectEntity'),
      'step': this.$i18n.t('projectList.labelProjectStep'),
      'city': this.$i18n.t('projectList.labelProjectCity'),
      'status': this.$i18n.t('projectList.labelProjectStatus'),
      'workType': this.$i18n.t('projectList.labelProjectType')
    };
    let _this = this;
    let keys = Object.keys(this.filters);
    keys.forEach(function(key) {
      _this.filters[key].value.forEach((element)=> {
        if (user.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      }); 
      if (_this.filters[key].show && _this.filters[key].search) {
        var label;
        if (user.lang=='fr') {
          label = (_this.filters[key].title && _this.filters[key].title.label_fr) || format[key];
        } else {
          label = (_this.filters[key].title && _this.filters[key].title.label_en) || format[key];
        }
        let element = {
          key: key,
          label: label,
          options: _this.filters[key].value
        };
        _this.options.push(element);
      }
    });
  },
  mounted () {
  }, 
  data() {
    return {
      hover: false,
      range: null,
      options: [],
      filters: null,
      value: null,
      changeTags: false
    };
  },
  methods: {
    visibleChange(val) {
      if (!val && this.changeTags) {
        this.$emit('applyChangeTags');
      }
    },
    removeTags(val) {
      this.$emit('applyChangeTags', [true]);
    }
  }
};
</script>
<style>
  div.projects-filters-content {
    display: flex;
    opacity: 1;
  }
  div.projects-filters-content.show {
    opacity: 1;
  }
  div.filters-project ul.el-scrollbar__view.el-select-dropdown__list {
    display:  flex !important;
  }
  
  div.filters-project .el-select-group__wrap:not(:last-of-type)::after {
    content: "";
    position: absolute;
    display: none;
    background: #e4e7ed;
    height: 0px;
  }
  div.el-select-dropdown.el-popper.is-multiple.filters-project {
    min-width: auto !important;
    /*width: 1024px;*/
    width: 58%;
    left: 105px;
  }
  div.filters-project .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
    right: 0px !important;
  }
  div.filters-project ul.el-select-group__wrap {
    border-right: 1px solid #e4e7ed;
    padding-right: 10px;
    padding-left: 10px;
  }
  div.filters-project ul.el-select-group li.el-select-dropdown__item.selected {
    color: #e14eca !important;
  }
  div.filters-project  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #e14eca !important;
  }
  li.el-select-dropdown__item.gat-green1 span::before,
  li.el-select-dropdown__item.gat-green2 span::before,
  li.el-select-dropdown__item.gat-green3 span::before,
  li.el-select-dropdown__item.gat-orange span::before,
  li.el-select-dropdown__item.gat-blue1 span::before,
  li.el-select-dropdown__item.gat-blue2 span::before,
  li.el-select-dropdown__item.gat-blue3 span::before,
  li.el-select-dropdown__item.gat-purple2 span::before,
  li.el-select-dropdown__item.gat-purple span::before,
  li.el-select-dropdown__item.gat-purple1 span::before,
  li.el-select-dropdown__item.gat-yellow span::before,
  li.el-select-dropdown__item.gat-red span::before,
  li.el-select-dropdown__item.bgreen span::before,
  li.el-select-dropdown__item.byellowgreen span::before,
  li.el-select-dropdown__item.borange span::before,
  li.el-select-dropdown__item.bblue span::before,
  li.el-select-dropdown__item.brown span::before,
  li.el-select-dropdown__item.blue_duck span::before,
  li.el-select-dropdown__item.pink span::before,
  li.el-select-dropdown__item.green span::before,
  li.el-select-dropdown__item.gat-lightred span::before,
  li.el-select-dropdown__item.gat-rose span::before,
  li.el-select-dropdown__item.gat-mauve span::before,
  li.el-select-dropdown__item.gat-turquois span::before,
  li.el-select-dropdown__item.gat-jaune span::before,
  li.el-select-dropdown__item.gat-red span::before,
  li.el-select-dropdown__item.gat-orange span::before,
  li.el-select-dropdown__item.gat-orange-light span::before,
  li.el-select-dropdown__item.gat-orange-light2 span::before,
  li.el-select-dropdown__item.gat-green-dark span::before,
  li.el-select-dropdown__item.gat-blue-dark span::before,
  li.el-select-dropdown__item.gat-green-light span::before,
  li.el-select-dropdown__item.gat-pink-light span::before,
  li.el-select-dropdown__item.gat-red-light span::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
  /** Brossard entity colors **/
  li.el-select-dropdown__item.bgreen span::before {
    background-color: #01b050;
  }
  li.el-select-dropdown__item.byellowgreen span::before {
    /*background-color: #ffff02;*/
    background: linear-gradient(
                  to right,
                    #01b050  0%, #01b050 50%,
                    #ffff02 50%, #ffff02 100%
                );
  }
  li.el-select-dropdown__item.borange span::before {
    background-color: #ed7d31;
  }
  li.el-select-dropdown__item.bblue span::before {
    background-color: #01b0f0;
  }

  /** Others entity colors **/

  li.el-select-dropdown__item.brown span::before {
    background-color: #01b0f0;
  }
  li.el-select-dropdown__item.blue_duck span::before {
    background-color: #009aa2;
  }
  li.el-select-dropdown__item.pink span::before {
    background-color: #e97cbf;
  }
  li.el-select-dropdown__item.green span::before {
    background-color: #00e045;
  }
  li.el-select-dropdown__item.blue span::before {
    background-color: #8fc4e6;
  }
  li.el-select-dropdown__item.orange span::before {
    background-color: #ff5a00;
  }

  li.el-select-dropdown__item.gat-green1 span::before {
    background-color: #00b050;
  }
  li.el-select-dropdown__item.gat-green2 span::before {
    background-color: #92d050;
  }
  li.el-select-dropdown__item.gat-green3 span::before {
    background-color: #316f7b;
  }
  li.el-select-dropdown__item.gat-orange span::before {
    background-color: #ffc001;
  }
  li.el-select-dropdown__item.gat-blue1 span::before {
    background-color: #01b0f0;
  }
  li.el-select-dropdown__item.gat-blue2 span::before {
    background-color: #0070c0;
  }
  li.el-select-dropdown__item.gat-purple span::before {
    background-color: #7030a0;
  }
  li.el-select-dropdown__item.gat-purple1 span::before {
    background-color: #5b2e8f;
  }
  li.el-select-dropdown__item.gat-yellow span::before {
    background-color: #ffff02;
  }
  li.el-select-dropdown__item.gat-rose span::before {
    background-color: #FF007F;
  }
  li.el-select-dropdown__item.gat-lightred span::before {
    background-color: #FFCCCB;
  }
  li.el-select-dropdown__item.gat-mauve span::before {
    background-color: #BB85AB;
  }
  li.el-select-dropdown__item.gat-turquois span::before {
    background-color: #30D5C8;
  }
  li.el-select-dropdown__item.gat-jaune span::before {
    background-color: #a18200;
  }
  li.el-select-dropdown__item.gat-purple2 span::before {
    background-color: #AA91E4;
  }
  li.el-select-dropdown__item.gat-blue3 span::before {
    background-color: #0E4095;
  }
  li.el-select-dropdown__item.gat-red span::before {
    background-color: #E8464A;
  }
  li.el-select-dropdown__item.gat-orange-light span::before {
    background-color: #ED732E;
  }
  li.el-select-dropdown__item.gat-orange-light2 span::before {
    background-color: #F4B754;
  }
  li.el-select-dropdown__item.gat-green-dark span::before {
    background-color: #2E6B4D;
  }
  li.el-select-dropdown__item.gat-blue-dark span::before {
    background-color: #1C02DE;
  }
  li.el-select-dropdown__item.gat-green-light span::before {
    background-color: #84B938;
  }
  li.el-select-dropdown__item.gat-pink-light span::before {
    background-color: #ee7deb;
  }
  li.el-select-dropdown__item.gat-red-light span::before {
    background-color: #E8464A;
  }
  div.projects-filters-content input.el-range-input::placeholder,
  div.projects-filters-content input.el-input__inner::placeholder {
    color: #606266 !important;
  }
</style>