<template>
  <router-view></router-view>
</template>

<script>
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    changeLang() {
      let user = window.localStorage.getItem("user");
      if (user) {
        user = JSON.parse(window.localStorage.getItem("user"));
        if (!user.lang) {
          user.lang='fr';
        }
        this.$root.$i18n.locale = user.lang;
        this.$validator.localize(user.lang);
      }
    }
  },
  mounted() {
    this.initializeLayout();
    this.changeLang();
  }
};
</script>
<style>
ul.el-select-group__wrap{
  max-width: 250px;
}

.ql-toolbar {
  border-radius: 0.4285rem 0.4285rem 0 0;
  border-color: rgba(29,37,59,.5) !important;
}

.ql-container {
  height: 150px;
  border-radius: 0 0 0.4285rem 0.4285rem;
  border-color: rgba(29,37,59,.5) !important;
}
</style>
