<template>
  <div>
    <collapse :multiple-active="false" :active-index="0" class="detours-content" >
      <collapse-item v-bind:id="calculeIdFermeture(index)" v-bind:title="calculeTitle">
        <div class="detour-add-btn" v-if="!statusOfUser" v-on:click="$emit('addDetour', [index, item.closingId])"><i class="tim-icons icon-simple-add"></i><span></span></div>
        <collapse v-if="item.detours.length>0" :multiple-active="false"  :active-index="0"  class="detours-container" >
          <div class="detour-wrapper" v-bind:key="item1.detourId" v-bind:id="item1.detourId" v-for="(item1, index1) in item.detours">
            <collapse-item v-bind:id="calculeIdDetour(index, index1)" v-bind:title="calculeTitleDetour(index1)">
              <div class="detour-remove-btn btn-danger" v-if="!statusOfUser" v-on:click="$emit('removeDetour', [index, item.closingId, item1.detourId])"><span><i class="tim-icons icon-simple-remove"></i></span></div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 aaaa">
                  <label> {{ $t('projectForm.labelDetourDelimitation')}} *</label>
                  <base-input
                    v-model="item1.value"
                    @input="$emit('input', [item.closingId, item1.detourId])"
                    @click.native="$emit('showModal', [item, item1])"
                    v-on:keydown="keyDown"
                    :disabled="statusOfUser"
                    v-bind:class="[item1.showError?'has-danger':'']"
                    v-bind:placeholder="$t('projectForm.placeholderProjectEntrave')"
                    addon-left-icon="tim-icons icon-map-big">
                  </base-input>
                  <label v-show="item1.showError" class="error">{{ $t('projectForm.requiredField') }}</label>
                </div>
              </div>
              <div class="detour-dates-container">
                <detour-date-component v-for="(item2, index2) in item1.dates"  v-bind:key="item2.dateId" v-bind:index="index2"  v-bind:item="item2" v-bind:pdate="date"
                                       :statusOfUser="statusOfUser" v-on:removeDetourDate="$emit('removeDetourDate', [$event, item.closingId, item1.detourId])" >
                </detour-date-component>
              </div>
              <div class="row margin-top-10">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="detour-date-add-btn" v-if="!statusOfUser" v-on:click="$emit('addDetourDate', [index, item.closingId, item1.detourId])" ><span><i class="tim-icons icon-simple-add"></i></span></div>
                </div>
              </div>
            </collapse-item>
          </div>
        </collapse>
      </collapse-item>
    </collapse>
  </div>
</template>
<script>
import { Collapse, CollapseItem } from 'src/components';

import DetourDateComponent from './DetourDateComponent.vue';

export default {
  name: 'detour-component',
  components: {
    Collapse,
    CollapseItem,
    DetourDateComponent
  },
  props: ['item', 'index', 'date','statusOfUser'],
  computed: {
    calculeTitle: function() {
      return this.$i18n.t('projectForm.detourItemTitle1') + (parseInt(this.index,10) + 1);
    }
  },
  data () {
    return {};
  },
  methods: {
    removeDetourDate(params) {
      $emit('removeDetourDate', [params, item.closingId, item1.detourId]);
    },
    calculeTitleDetour(index) {
      return this.$i18n.t('projectForm.detourItemTitle') + (parseInt(index, 10) + 1);
    },
    calculeIdDetour(index, index1) {
      return  this.$i18n.t('projectForm.detourItemTitle') + (parseInt(index, 10) + 1) + '#' + (parseInt(index1, 10) + 1);
    },
    calculeIdFermeture(index) {
      return this.$i18n.t('projectForm.closingItemTitle')  + (parseInt(index, 10) + 1);
    },
    keyDown(event) {
      if (event.key!=='Enter') {
        event.preventDefault();
      }
    }
  }
}
</script>
<style>
  .detours-phases div.card.card-timeline.card-plain div.card-body ul.timeline li.timeline-inverted div.timeline-panel div.detours-content div.card-collapse.detours-container div.detour-wrapper:only-child div.card.card-plain div.detour-remove-btn {
    /*display: none;*/
  }
  div.detours-content div.card-header a {
    font-weight: 700 !important;
  }
  div.detour-add-btn {
    color: #ffffff;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 1em;
    text-align: center;
    position: absolute;
    /*top: 10px;
    right: 50px;
    margin-left: -25px;*/
    top: 25px;
    right: 0px;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  .detour-remove-btn {
    color: #ffffff;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 0.7em;
    text-align: center;
    background-color: #9A9A9A;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  .detour-remove-btn {
    position: relative;
    /*top: -60px;*/
    top: -47px;
    right: -100px;
    margin-left: -25px;
  }
  
  div.form-group.has-danger {
    margin-bottom: 0px;
  }
  .card.card-plain {
    margin-bottom: 20px;
  }
</style>