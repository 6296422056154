<template>
  <div class="content">
     <div 
      class="mt-3 text-right">
      <base-button class="" @click="$router.push('/layer/list')" type="primary">
      {{ $t('layerForm.label_layerlist') }}
      </base-button>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <card style="min-height: 500px;">
          <template slot="header">
            <h3 class="card-title"></h3>
          </template>
          <div class="loading" v-if="loading">
            <img src="../../../public/img/loading.gif"/>
          </div>
          <form v-else class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">  {{ $t('layerForm.url') }}</label>
              <div class="col-sm-10">
                <base-input
                  name="URL"
                  v-bind:placeholder="$t('layerForm.url')"
                  required
                  v-validate="modelValidations.required"
                  :error="getError('URL')"
                  v-model="layer.url">
                </base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">{{ $t('layerForm.label_en') }}</label>
              <div class="col-sm-10"> 
                <base-input
                  name="Label English"
                  v-bind:placeholder="$t('layerForm.label_en')"
                  required
                  v-validate="modelValidations.required"
                  :error="getError('Label English')"
                  v-model="layer.label_en">
                </base-input>
              </div>
              <label class="col-sm-2 col-form-label">{{ $t('layerForm.label_fr') }}</label>
              <div class="col-sm-10"> 
                <base-input
                  name="Label French"
                  v-bind:placeholder="$t('layerForm.label_fr')"
                  required
                  v-validate="modelValidations.required"
                  :error="getError('Label French')"
                  v-model="layer.label_fr">
                </base-input>
              </div>
            </div>
            
            
          </form>
          <template slot="footer" class="text-right">
            <base-button
              native-type="submit"
              @click.prevent="validate"
              class="pull-right"
              type="primary">{{ $t('layerForm.label_add') }}</base-button>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BaseCheckbox, BaseRadio, ImageUpload, TagsInput } from 'src/components/index';
import { Select, Option } from 'element-ui';

import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);

export default {
  name: 'LayerForm',
  props: ['projectId','modalStatus'],
  components: {
    BaseCheckbox,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
    ImageUpload,
    TagsInput
  },
  created () {
    let _this=this;
    if(_this.projectId != null){
      _this.mode='create'
    }
    if (_this.$route.query && _this.$route.query.uuid) {
      _this.mode='edit';
      _this.layer.uuid=this.$route.query.uuid;
      _this.getLayer()
    }
  },
  watch: {
    // call the method again if the route changes
    '$route': 'resetArchive'
  },
  data() {
    return {
      layer: {
        uuid: null,
        url: '',
        label_en:'',
        label_fr: '',
        date: '',
      },
      error: {
        file: false
      },
      mode: 'create',
      
      modelValidations: {
        required: {
          required: true
        }
      }
      
    };
  },
  methods: {
    resetValue(){
        this.archive = {
          uuid: null,
          url: '',
          label_en:'',
          label_fr: '',
          date: '',
        },
        this.mode = 'create',
        this.loading = false,
        this.modelValidations = {
          required: {
            required: true
          }
        }
    },


    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      let _this=this;
      _this.error.file=false;
   
      this.$validator.validateAll().then(isValid => {
        if (isValid  && (_this.mode=='create')) {
          _this.create(); 
        } else if (isValid &&  (_this.mode=='edit')) {
          _this.create();
        }
      });
    },
    create() {
      this.loading=true;
      let url = '/layer/create';
      if (this.mode=='edit') {
        url = '/layer/edit';
      }

      let _this=this;
      axios({
        method: 'post',
        url: url,
        data: this.layer,
      }).then( (response) => {
        _this.loading=false;
        if (response.data.success) {
          if(_this.projectId != null){
            _this.$emit("update", false);
            _this.resetValue();
          }else{
            window.location.href='/#/layer/list'; 
          }
        }
      })
      .catch( e =>  {
        console.log("error:::", e);
      });
    },
    getLayer() {
      let _this=this;
      axios.get('layer/edit?uuid='+_this.layer.uuid).then(response => {
        let data = response.data;
        if (data.success) {
          _this.layer = data.data;
        } else {
          if (data.need_auth) {
            this.$router.push({path: '/login'});
          } else {
            this.$notify({
              message: _this.$i18n.t('serverReply.errorArchiveRead'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        }
      }).catch(() => {});
    },

  }
};
</script>
<style lang="scss">
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .margin-top-40 {
    margin-top: 40px;
  }
  .map-container {
    height: 350px !important;
  }
  
  #mapview {
    padding: 0;
    margin: 0;
    height: 350px !important;
    width: 100%;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .custom-actionbar {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(43, 53, 83);
  }
  
  .custom-actionbar button.pell-button {
    color: #d3d7e9 !important;
  }
  .vp-editor {
    border: 1px solid rgb(43, 53, 83);
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    height: 150px;
  }
  body.white-content .test-select.select-primary.el-select .el-input input,
  body.white-content .vp-editor{
    border-color: #8d919c !important;
  }
  .pell-content {
    height: 150px;
  }
  .vp-editor:focus {
    border: 1px solid #cd51e0 !important;
  }
  .pell-button {
    width: 40px;
  }
  .input-new-tag {
    margin-left: 0px;
    width: 200px !important;
    height: 32px;
    display: inline;
  }
  span.el-tag.el-tag--primary.el-tag--small.el-tag--light {
    margin-right: 10px;
  }
  label.text-danger {
    margin-left: -20px;
    margin-top: -40px;
    color:  #e5262b !important;
  }
  body:not(.white-content) span.file-selected {
    color: hsla(0,0%,100%,.8);
    font-weight: bold;
  }
  body.white-content span.file-selected {
    color: #344675;
    font-weight: bold;
  }
  div.loading {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  div.archive-published {
    margin-top: -15px;
  }
</style>